export enum DocumentType {
  procedure = "Procedure",
  report = "Report",
  certificate = "Certificate",
  drawing = "Drawing",
  list = "List",
  specification = "Specification",
  contractualDocument = "Contractual_Document",
  documentDataSheet = "Document_Data_Sheet",
  dataSheet = "Data_Sheet",
  functionalDesign = "Functional_Design",
  isometricDrawings = "Isometric_Drawings",
  inspectionDataSheet = "Inspection_Data_Sheet",
  minutesOfMeeting = "Minutes_of_Meeting",
  planningAndScheduling = "Planning_and_Scheduling",
  processAndInstrumentDiagram = "Process_and_Instrument_Diagram",
  technicalDataSheet = "Technical_Data_Sheet",
  technicalQuery = "Technical_Query",
  other = "Other",
}
