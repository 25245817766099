import React, { FC } from "react";

export const SiteDocumentsIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <path
        fill="#575756"
        d="M50.5,13.34c-0.01-0.08-0.02-0.16-0.05-0.23c-0.03-0.08-0.08-0.14-0.13-0.2c-0.03-0.04-0.04-0.08-0.07-0.12
	l-8.58-9.02c0,0-0.01,0-0.01-0.01c-0.07-0.07-0.16-0.12-0.25-0.17c-0.03-0.01-0.05-0.04-0.07-0.05c-0.05-0.02-0.11-0.02-0.17-0.04
	c-0.07-0.02-0.14-0.04-0.21-0.04c-0.01,0-0.01,0-0.02,0H21.54c-1.56,0-2.84,1.27-2.84,2.84v2.02h-2.02c-1.56,0-2.84,1.27-2.84,2.83
	v2.02h-2.02c-1.56,0-2.84,1.27-2.84,2.84v37.63c0,1.56,1.27,2.84,2.84,2.84h26.16c1.56,0,2.84-1.27,2.84-2.84v-2.02h2.02
	c1.56,0,2.84-1.27,2.84-2.83v-2.02h2.02c1.56,0,2.84-1.27,2.84-2.84V13.48C50.53,13.43,50.51,13.39,50.5,13.34z M37.48,22.2h-5.25
	v-5.52L37.48,22.2z M42.34,17.34h-5.25v-5.52L42.34,17.34z M47.2,12.48h-5.25V6.96L47.2,12.48z M20.7,6.3
	c0-0.46,0.38-0.84,0.84-0.84h18.41v6.46l-3.13-3.29c0,0-0.01,0-0.01-0.01c-0.07-0.07-0.15-0.12-0.24-0.17
	c-0.03-0.01-0.05-0.04-0.08-0.05c-0.05-0.02-0.11-0.02-0.16-0.03c-0.07-0.02-0.15-0.04-0.22-0.04c-0.01,0-0.01,0-0.01,0H20.7V6.3z
	 M15.84,11.15c0-0.46,0.38-0.83,0.84-0.83h18.41v6.46l-3.13-3.29c0,0-0.01,0-0.01-0.01c-0.07-0.07-0.15-0.11-0.23-0.16
	c-0.03-0.02-0.06-0.05-0.09-0.06c-0.05-0.02-0.1-0.02-0.15-0.03c-0.08-0.02-0.15-0.05-0.23-0.05c-0.01,0-0.01,0-0.01,0H15.84V11.15z
	 M38.82,53.64c0,0.46-0.38,0.84-0.84,0.84H11.82c-0.46,0-0.84-0.38-0.84-0.84V16.01c0-0.46,0.38-0.84,0.84-0.84h18.41v8.02
	c0,0.55,0.45,1,1,1h7.58V53.64z M43.67,48.78c0,0.46-0.38,0.83-0.84,0.83h-2.02V23.2c0-0.05-0.02-0.09-0.03-0.14
	c-0.01-0.08-0.02-0.16-0.05-0.23c-0.03-0.07-0.08-0.14-0.13-0.2c-0.03-0.04-0.04-0.08-0.07-0.12l-3.01-3.17h6.15V48.78z
	 M48.53,43.93c0,0.46-0.38,0.84-0.84,0.84h-2.02V18.34c0-0.05-0.02-0.09-0.03-0.14c-0.01-0.08-0.02-0.16-0.05-0.23
	c-0.03-0.07-0.08-0.14-0.13-0.2c-0.03-0.04-0.04-0.08-0.07-0.12l-3.01-3.17h6.15V43.93z"
      />
    </svg>
  );
};
