import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import { FPSO } from "Models/FPSO";
import moment from "moment";
import { FC, SyntheticEvent, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  readonly fpso: FPSO;
  handleFpsoBasicDetailsEdited(updatedFpso: FPSO): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2),
    },
    formTopRow: {
      justifyContent: "space-evenly",
    },
    formBottomRow: {
      justifyContent: "space-between",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    coordinateTextFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.COORDINATE_TEXT_INPUT_WIDTH,
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
  }),
);

export const FpsoBasicDetailsEdit: FC<Props> = ({
  fpso: originalFpso,
  handleFpsoBasicDetailsEdited,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");

  const [unchangedFpso] = useState<FPSO>({
    id: originalFpso.id,
    name: originalFpso.name,
    englishTranslation: [
      {
        id: originalFpso.englishTranslation[0].id,
        fpsoDescription: originalFpso.englishTranslation[0].fpsoDescription,
      },
    ],
    portugueseTranslation: [
      {
        id: originalFpso.portugueseTranslation[0].id,
        fpsoDescription: originalFpso.portugueseTranslation[0].fpsoDescription,
      },
    ],
    latitude: originalFpso.latitude,
    longitude: originalFpso.longitude,
    startOfOperations: originalFpso.startOfOperations,
    block: {
      id: originalFpso.block.id,
      name: originalFpso.block.name,
      fpsos: originalFpso.block.fpsos,
      owner: originalFpso.block.owner,
      anpgMainContactName: originalFpso.block.anpgMainContactName,
      anpgMainContactEmail: originalFpso.block.anpgMainContactEmail,
    },

    fpsoMainContactName: originalFpso.fpsoMainContactName,
    fpsoMainContactEmail: originalFpso.fpsoMainContactEmail,
    fpsoAreas: originalFpso.fpsoAreas,
  });

  const [updatedFpso, setUpdatedFpso] = useState<FPSO>(unchangedFpso);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    handleFpsoBasicDetailsEdited(updatedFpso);
  };

  const handleStartOfOperationsChange = (
    selectedDate: MaterialUiPickersDate,
  ) => {
    const fpso = { ...updatedFpso };
    fpso.startOfOperations = selectedDate ? selectedDate.toDate() : null;
    setUpdatedFpso(fpso);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12} className={classes.root}>
        <FormGroup row className={classes.formTopRow}>
          <FormControl className={classes.coordinateTextFormControl}>
            <TextField
              label={t("latitude")}
              defaultValue={updatedFpso.latitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpso.latitude = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.coordinateTextFormControl}>
            <TextField
              label={t("longitude")}
              defaultValue={updatedFpso.longitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpso.longitude = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <DatePicker
              clearable={true}
              label={t("start")}
              value={updatedFpso.startOfOperations}
              placeholder={moment().format(DateConstants.DEFAULT_DATE_FORMAT)}
              onChange={selectedDate => {
                handleStartOfOperationsChange(selectedDate);
              }}
              format={DateConstants.DEFAULT_DATE_FORMAT}
            />
          </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formBottomRow}>
          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("anpg_contact")}
              defaultValue={updatedFpso.block.anpgMainContactName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpso.block.anpgMainContactName = event.target.value;
              }}
            />
          </FormControl>

          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("anpg_contact_email")}
              defaultValue={updatedFpso.block.anpgMainContactEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpso.block.anpgMainContactEmail = event.target.value;
              }}
            />
          </FormControl>

          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("fpso_contact")}
              defaultValue={updatedFpso.fpsoMainContactName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpso.fpsoMainContactName = event.target.value;
              }}
            />
          </FormControl>

          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("fpso_contact_email")}
              defaultValue={updatedFpso.fpsoMainContactEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpso.fpsoMainContactEmail = event.target.value;
              }}
            />
          </FormControl>
        </FormGroup>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            multiline
            fullWidth
            rows="4"
            rowsMax="8"
            variant="outlined"
            label={t("site_description") + t("cars:englishLanguageSuffix")}
            defaultValue={updatedFpso.englishTranslation[0]?.fpsoDescription}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updatedFpso.englishTranslation[0].fpsoDescription =
                event.target.value;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            multiline
            fullWidth
            rows="4"
            rowsMax="8"
            variant="outlined"
            label={t("site_description") + t("cars:portugueseLanguageSuffix")}
            defaultValue={updatedFpso.portugueseTranslation[0]?.fpsoDescription}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updatedFpso.portugueseTranslation[0].fpsoDescription =
                event.target.value;
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.bottomButtonRow}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("cars:save")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
