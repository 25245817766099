import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { CarEvidenceStatus } from "Enums/CarEvidenceStatus";
import {
  DELETE_CAR_EVIDENCE_COMMENT,
  DELETE_CAR_EVIDENCE_DOCUMENT,
  GET_CAR_EVIDENCE_DOCUMENTS_BY_CAR_ID,
  GetCarEvidenceDocumentsByCarIdQueryResult,
  GetCarEvidenceDocumentsByCarIdQueryVariables,
  INSERT_CAR_EVIDENCE_COMMENT,
  INSERT_CAR_EVIDENCE_DOCUMENT,
  UPDATE_CAR_EVIDENCE_COMMENT,
  UPDATE_CAR_EVIDENCE_DOCUMENT,
} from "Graphql/CarQueries";
import { CarEvidenceDocument } from "Models/CarEvidenceDocument";
import { CarRow } from "Models/CarRow";
import { Comment } from "Models/Comment";
import { InitializedComment } from "Models/InitialComment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CarEvidenceDocumentList } from "Views/Components/CarEvidenceDocuments/CarEvidenceDocumentList";

interface Props {
  readonly carRow: CarRow;
  readonly fpsoId: string;
  readonly fpsoAreaId: string;
  readonly auditId: string;
  readonly isActive: boolean;
  readonly isLocked: boolean;
  readonly isArchived: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
  }),
);

export const CarEvidenceDocuments: FC<Props> = ({
  carRow,
  fpsoId,
  fpsoAreaId,
  auditId,
  isActive,
  isLocked,
  isArchived,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");
  const { keycloak } = useKeycloak();
  const uuidv4 = require("uuid/v4");

  const {
    loading: carEvidenceDocumentsLoading,
    error: carEvidenceDocumentsError,
    data: carEvidenceDocumentsInfo,
    refetch,
  } = useQuery<
    GetCarEvidenceDocumentsByCarIdQueryResult,
    GetCarEvidenceDocumentsByCarIdQueryVariables
  >(GET_CAR_EVIDENCE_DOCUMENTS_BY_CAR_ID, {
    variables: {
      id: isActive ? carRow.id : carRow.originalCarId || "undefined",
    },
  });

  const [
    insertCarEvidenceDocument,
    { error: insertCarEvidenceDocumentError },
  ] = useMutation(INSERT_CAR_EVIDENCE_DOCUMENT);

  const [
    deleteCarEvidenceDocument,
    { error: deleteCarEvidenceDocumentError },
  ] = useMutation(DELETE_CAR_EVIDENCE_DOCUMENT);

  const [updateCarEvidenceDocument] = useMutation(UPDATE_CAR_EVIDENCE_DOCUMENT);

  const [insertCarEvidenceComment] = useMutation(INSERT_CAR_EVIDENCE_COMMENT);

  const [updateCarEvidenceComment] = useMutation(UPDATE_CAR_EVIDENCE_COMMENT);

  const [deleteCarEvidenceComment] = useMutation(DELETE_CAR_EVIDENCE_COMMENT);

  const uploadNewDocument = (document: CarEvidenceDocument, file: File) => {
    const formData = new FormData();
    formData.set(
      "folderName",
      `${fpsoId}/${fpsoAreaId}/${auditId}/${carRow.id}/`,
    );
    formData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${keycloak?.token}`,
        },
      })
      .then(result => {
        if (keycloak?.tokenParsed?.sub) {
          insertCarEvidenceDocument({
            variables: {
              evidenceDocumentName: document.evidenceDocumentName,
              evidenceDocumentReference: result.data,
              evidenceStatus: document.evidenceStatus
                ? document.evidenceStatus
                : CarEvidenceStatus.awaitingReview,
              revision: document.revision,
              documentNumber: document.documentNumber,
              documentDescription: document.documentDescription,
              carId: carRow.id,
              submitterId: keycloak.tokenParsed.sub,
              createdAt: document.createdAt,
            },
          }).then(() => {
            refetch({ id: carRow.id || "undefined" });
          });
        }
      });
  };

  const deleteDocument = (document: CarEvidenceDocument) => {
    if (document.evidenceDocumentReference) {
      axios
        .delete(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, {
          headers: { Authorization: `bearer ${keycloak?.token}` },
          params: { fileName: document.evidenceDocumentReference },
        })
        .then(result => {
          if (keycloak?.tokenParsed?.sub) {
            deleteCarEvidenceDocument({
              variables: {
                id: document.id,
              },
            }).then(() => {
              refetch({ id: carRow.id || "undefined" });
            });
          }
        });
    }
  };

  const updateDocument = (updatedEvidenceRow: CarEvidenceDocument) => {
    updateCarEvidenceDocument({
      variables: {
        id: updatedEvidenceRow.id,
        evidenceDocumentName: updatedEvidenceRow.evidenceDocumentName,
        revision: updatedEvidenceRow.revision,
        documentNumber: updatedEvidenceRow.documentNumber,
        documentDescription: updatedEvidenceRow.documentDescription,
        evidenceStatus: updatedEvidenceRow.evidenceStatus,
        createdAt: updatedEvidenceRow.createdAt,
      },
    }).then(() => {
      refetch({ id: carRow.id || "undefined" });
    });
  };

  const handleCommentAdd = (newComment: InitializedComment) => {
    if (keycloak?.tokenParsed?.sub) {
      insertCarEvidenceComment({
        variables: {
          id: uuidv4(),
          content: newComment.content,
          carEvidenceId: newComment.carEvidenceId,
          commenterId: keycloak?.tokenParsed?.sub,
        },
      }).then(() => {
        refetch({ id: carRow.id || "undefined" });
      });
    }
  };

  const handleCommentEdited = (updatedComment: Comment) => {
    updateCarEvidenceComment({
      variables: {
        id: updatedComment.id,
        content: updatedComment.content,
      },
    }).then(() => {
      refetch({ id: carRow.id || "undefined" });
    });
  };

  const handleCommentDeleted = (deletedComment: Comment) => {
    deleteCarEvidenceComment({
      variables: {
        id: deletedComment.id,
      },
    }).then(() => {
      refetch({ id: carRow.id || "undefined" });
    });
  };

  if (carEvidenceDocumentsLoading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (carEvidenceDocumentsError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:carEvidenceDocumentsFetchError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (insertCarEvidenceDocumentError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:insertCarEvidenceDocumentError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (deleteCarEvidenceDocumentError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:deleteCarEvidenceDocumentError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {carEvidenceDocumentsInfo && carEvidenceDocumentsInfo.car ? (
        <Grid container>
          <Grid item xs={12} className={classes.root}>
            <CarEvidenceDocumentList
              title={t("evidenceDocuments")}
              carEvidenceDocuments={
                carEvidenceDocumentsInfo.car.carEvidenceDocuments
              }
              uploadNewDocument={uploadNewDocument}
              deleteDocument={deleteDocument}
              updateDocument={updateDocument}
              insertComment={handleCommentAdd}
              updateComment={handleCommentEdited}
              deleteComment={handleCommentDeleted}
              isActive={isActive}
              isLocked={isLocked}
              isArchived={isArchived}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {t("errors:noDocumentsAvailable")}
        </Grid>
      )}
    </>
  );
};
