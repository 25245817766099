import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  readonly dialogOpen: boolean;
  readonly title: string;
  readonly description: string;
  handleDialogClose(): void;
  handleDialogConfirmClicked(): void;
}

export const ConfirmDialog: FC<Props> = ({
  dialogOpen,
  title,
  description,
  handleDialogClose,
  handleDialogConfirmClicked,
}) => {
  const { t } = useTranslation("cars");

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="primary">
          {t("deleteCarCancel")}
        </Button>
        <Button onClick={handleDialogConfirmClicked} color="primary" autoFocus>
          {t("deleteCarAccept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
