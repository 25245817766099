import { useQuery } from "@apollo/react-hooks";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import DateConstants from "Constants/DateConstants";
import {
  GET_FPSO_LEAGUE_TABLE_DATA,
  GetFpsoLeagueTableDataResult,
} from "Graphql/FpsoQueries";
import MaterialTable from "material-table";
import { LeagueTableRow } from "Models/LeagueTableRow";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TableIcons } from "Views/Components/Icons/TableIcons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leagueTable: {
      padding: theme.spacing(3),
    },
  }),
);

export const KPILeagueTable: FC = () => {
  const history = useHistory();
  const { t } = useTranslation("kpis");
  const classes = useStyles();

  const [tableData, setTableData] = useState<LeagueTableRow[]>([]);

  const handleLeagueTableDataReceived = () => {
    const data: LeagueTableRow[] = [];
    leagueTableData?.fpsos.forEach(item => {
      item.fpsoAreas.forEach(areaItem => {
        data.push({
          fpsoAreaId: areaItem.id,
          fpsoName: areaItem.fpso.name,
          fpsoAreaName: areaItem.areaName,
          operatorName: areaItem.fpso.block.owner.companyName,
          grading:
            areaItem.currentAuditCarClassesCount.auditScore >= 40
              ? t("kpis:unacceptableGrading")
              : areaItem.currentAuditCarClassesCount.auditScore >= 30 &&
                areaItem.currentAuditCarClassesCount.auditScore <= 39
              ? t("kpis:unsatisfactoryGrading")
              : areaItem.currentAuditCarClassesCount.auditScore >= 20 &&
                areaItem.currentAuditCarClassesCount.auditScore <= 29
              ? t("kpis:fairGrading")
              : areaItem.currentAuditCarClassesCount.auditScore >= 0 &&
                areaItem.currentAuditCarClassesCount.auditScore <= 19
              ? t("kpis:goodGrading")
              : "",
          currentAuditScore: areaItem.currentAuditCarClassesCount.auditScore,
          currentAuditStartDate: areaItem.currentAuditCarClassesCount.startDate,
          countCriticals:
            areaItem.currentAuditCarClassesCount.countCriticals.aggregate.count,
          countMajors:
            areaItem.currentAuditCarClassesCount.countMajors.aggregate.count,
          countMinors:
            areaItem.currentAuditCarClassesCount.countMinors.aggregate.count,
          countComments:
            areaItem.currentAuditCarClassesCount.countComments.aggregate.count,
        });
      });
    });
    setTableData(data);
  };

  const {
    loading: dataLoading,
    error: dataError,
    data: leagueTableData,
  } = useQuery<GetFpsoLeagueTableDataResult>(GET_FPSO_LEAGUE_TABLE_DATA, {
    onCompleted: handleLeagueTableDataReceived,
  });

  const handleFpsoClick = (rowData: LeagueTableRow | LeagueTableRow[]) => {
    window.scrollTo(0, 0);
    if (Array.isArray(rowData)) {
      history.push(`/fpsoArea/${rowData[0].fpsoAreaId}`);
    } else {
      history.push(`/fpsoArea/${rowData.fpsoAreaId}`);
    }
  };

  return (
    <>
      {!dataError ? (
        <div className={classes.leagueTable}>
          <MaterialTable
            isLoading={dataLoading}
            options={{
              exportButton: false,
              exportAllData: false,
              draggable: false,
              padding: "default",
              pageSizeOptions: [10, 20, 30],
              pageSize: 10,
              emptyRowsWhenPaging: false,
              detailPanelColumnAlignment: "right",
              detailPanelType: "single",
            }}
            columns={[
              {
                title: t("fpsoName"),
                field: "fpsoName",
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: t("fpsoAreaName"),
                field: "fpsoAreaName",
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: t("operator"),
                field: "operatorName",
                headerStyle: {
                  fontWeight: "bold",
                },
              },
              {
                title: t("currentGrading"),
                field: "grading",
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
              {
                title: t("currentAuditScore"),
                field: "currentAuditScore",
                sorting: true,
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
              {
                title: t("currentAuditDate"),
                field: "currentAuditStartDate",
                type: "date",
                render: rowData => (
                  <>
                    {rowData.currentAuditStartDate ? (
                      <>
                        {moment(rowData.currentAuditStartDate).format(
                          DateConstants.DEFAULT_DATE_FORMAT,
                        )}
                      </>
                    ) : (
                      <>{t("cars:notAvailable")}</>
                    )}
                  </>
                ),
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
              {
                title: t("openCriticalsTitle"),
                field: "countCriticals",
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
              {
                title: t("openMajorsTitle"),
                field: "countMajors",
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
              {
                title: t("openMinorsTitle"),
                field: "countMinors",
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
              {
                title: t("openCommentsTitle"),
                field: "countComments",
                cellStyle: {
                  textAlign: "center",
                },
                headerStyle: {
                  textAlign: "center",
                  fontWeight: "bold",
                },
              },
            ]}
            icons={TableIcons}
            data={tableData}
            title={t("leagueTableTitle")}
            onRowClick={(event, rowData) => {
              if (rowData) {
                handleFpsoClick(rowData);
              }
            }}
          />
        </div>
      ) : (
        <div>{t("errors:leagueTableFetchError")}</div>
      )}
    </>
  );
};
