import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { FC, useState } from "react";
import ReactImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

interface Props {
  readonly images: ReactImageGalleryItem[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      display: "flex",
    },
    imageSize: {
      minHeight: 450,
      minWidth: 320,
      width: "70%",
      height: "auto",
    },
  }),
);

export const PictureGallery: FC<Props> = ({ images }) => {
  const classes = useStyles();
  const [visibility] = useState(images.length > 0);

  return (
    <div className={classes.root}>
      {visibility && (
        <div className={classes.imageSize}>
          <ReactImageGallery
            items={images}
            showFullscreenButton={true}
            showThumbnails={true}
            thumbnailPosition={"bottom"}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={600}
            slideInterval={7000}
            showNav={false}
          />
        </div>
      )}
    </div>
  );
};
