import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MaterialSelect,
  TextField,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ElementSizes from "Constants/ElementSizes";
import { SubSystemType } from "Enums/SubSystemType";
import { SystemArea } from "Enums/SystemArea";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemDefaultLabelLogic } from "Logic/SubSystemOptions/SubSystemDefaultLabelLogic";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { FpsoAreaSystemComponent } from "Models/FpsoAreaSystemComponent";
import { OptionType } from "Models/OptionType";
import React from "react";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";
import { ConfirmDialog } from "Views/Components/Dialogs/ConfirmDialog";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  readonly componentRow: FpsoAreaSystemComponent;
  handleRowEdited(
    updatedComponentRow: FpsoAreaSystemComponent,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  handleRowDeleted(deletedComponentRow: FpsoAreaSystemComponent): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      width: "100%",
      paddingRight: theme.spacing(6),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.SYSTEM_COMPONENT_EDIT_FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    formRowSpacing: {
      margin: theme.spacing(1),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    bottomButtonRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    languageTextFields: {
      margin: theme.spacing(1),
    },
  }),
);

export const FpsoAreaSystemComponentDetailsEdit: FC<Props> = ({
  componentRow: originalComponentRow,
  handleRowEdited,
  handleRowDeleted,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");

  const [unchangedComponentRow] = useState<FpsoAreaSystemComponent>({
    id: originalComponentRow.id,
    createdAt: originalComponentRow.createdAt,
    updatedAt: originalComponentRow.updatedAt,
    systemArea: originalComponentRow.systemArea,
    componentName: originalComponentRow.componentName,
    quantity: originalComponentRow.quantity,
    type: originalComponentRow.type,
    manufacturer: originalComponentRow.manufacturer,
    modelNo: originalComponentRow.modelNo,
    serialNo: originalComponentRow.serialNo,
    description: originalComponentRow.description,
    comment: originalComponentRow.comment,
    itemsWithSubSystems: originalComponentRow.itemsWithSubSystems,
  });

  const [updatedComponentRow, setUpdatedComponentRow] = useState(
    unchangedComponentRow,
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();
  const [changeInSubSystems, setChangeInSubSystems] = useState(false);

  const defaultSelectedSubSystems: OptionType[] = [];
  unchangedComponentRow.itemsWithSubSystems.forEach(subSystem => {
    const defaultLabelBlank = "";
    const defaultLabel = SubSystemDefaultLabelLogic(
      defaultLabelBlank,
      subSystem,
    );
    defaultSelectedSubSystems.push({
      value: subSystem.subSystemType,
      label: defaultLabel,
    });
  });

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    if (changeInSubSystems === true) {
      const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
        selectedSubSystems,
      );

      event.preventDefault();
      handleRowEdited(
        updatedComponentRow,
        selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    } else {
      const unchangedSubSystemsSubSystemTypeArray: SubSystemType[] = [];

      event.preventDefault();
      handleRowEdited(
        updatedComponentRow,
        unchangedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    }
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleRowDeleted(updatedComponentRow);
  };

  const handleSystemAreaChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const systemComponentRow = { ...updatedComponentRow };
    systemComponentRow.systemArea = event.target.value as SystemArea;
    setUpdatedComponentRow(systemComponentRow);
  };

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
    setChangeInSubSystems(true);
  };

  const Option = CheckboxOption;

  return (
    <form onSubmit={handleSubmit} className={classes.formRoot}>
      <FormGroup>
        <FormGroup row className={classes.formRowSpacing}>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("componentName")}
              defaultValue={unchangedComponentRow.componentName}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.componentName = event.target.value;
              }}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel shrink id="class-select-label">
              {t("systemArea")}
            </InputLabel>
            <MaterialSelect
              value={updatedComponentRow.systemArea}
              onChange={handleSystemAreaChange}
            >
              <MenuItem value={SystemArea.chemicalsAndFuel}>
                {t("chemicalsAndFuel")}
              </MenuItem>
              <MenuItem value={SystemArea.electrical}>
                {t("electrical")}
              </MenuItem>
              <MenuItem value={SystemArea.hardware}>{t("hardware")}</MenuItem>
              <MenuItem value={SystemArea.instrument}>
                {t("instrument")}
              </MenuItem>
              <MenuItem value={SystemArea.liftingEquipment}>
                {t("liftingEquipment")}
              </MenuItem>
              <MenuItem value={SystemArea.mechanical}>
                {t("mechanical")}
              </MenuItem>
              <MenuItem value={SystemArea.other}>{t("other")}</MenuItem>
              <MenuItem value={SystemArea.piping}>{t("piping")}</MenuItem>
              <MenuItem value={SystemArea.process}>{t("process")}</MenuItem>
              <MenuItem value={SystemArea.safety}>{t("safety")}</MenuItem>
              <MenuItem value={SystemArea.vessel}>{t("vessel")}</MenuItem>
              <MenuItem value={SystemArea.wasteManagement}>
                {t("wasteManagement")}
              </MenuItem>
              <MenuItem value={SystemArea.welfare}>{t("welfare")}</MenuItem>
            </MaterialSelect>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("quantity")}
              defaultValue={unchangedComponentRow.quantity}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.quantity = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("type")}
              defaultValue={unchangedComponentRow.type}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.type = event.target.value;
              }}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              label={t("manufacturer")}
              defaultValue={unchangedComponentRow.manufacturer}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.manufacturer = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("modelNo")}
              defaultValue={unchangedComponentRow.modelNo}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.modelNo = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("serialNo")}
              defaultValue={unchangedComponentRow.serialNo}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.serialNo = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Select
              onChange={option => handleChange(option)}
              options={subSystemOptions}
              isMulti
              autoFocus
              isSearchable
              isClearable
              styles={CustomStylesSubSystemOptions}
              placeholder={t("helperTextEditSubSystems")}
              defaultValue={defaultSelectedSubSystems}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
            />
          </FormControl>
        </FormGroup>

        <FormGroup row>
          <Grid item xs={12}>
            <TextField
              className={classes.languageTextFields}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("description")}
              style={{ zIndex: 0 }}
              defaultValue={unchangedComponentRow.description}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedComponentRow.description = event.target.value;
              }}
            />
          </Grid>
        </FormGroup>
        <FormGroup row>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={classes.languageTextFields}
                multiline
                fullWidth
                rows="4"
                rowsMax="8"
                variant="outlined"
                label={t("comment")}
                style={{ zIndex: 0 }}
                defaultValue={unchangedComponentRow.comment}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updatedComponentRow.comment = event.target.value;
                }}
              />
            </Grid>
          </Grid>
        </FormGroup>

        <Grid item xs={12} className={classes.bottomButtonRow}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
          <Button
            onClick={() => handleDeleteDialogOpen()}
            startIcon={<DeleteIcon />}
          >
            {t("delete")}
          </Button>
        </Grid>
        <ConfirmDialog
          dialogOpen={deleteDialogOpen}
          title={t("deleteSystemComponentTitle")}
          description={t("deleteSystemComponentDescription")}
          handleDialogClose={handleDeleteDialogClose}
          handleDialogConfirmClicked={handleDeleteClicked}
        />
      </FormGroup>
    </form>
  );
};
