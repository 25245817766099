import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Roles } from "Enums/Roles";
import { keycloak } from "keycloak";
import { Feedback } from "Models/Feedback";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";

interface Props {
  handleFeedbackDeleted(deletedFeedback: Feedback): void;
  readonly feedback: Feedback;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      paddingRight: theme.spacing(10),
    },
  }),
);

export const UserFeedbackDelete: FC<Props> = ({
  handleFeedbackDeleted,
  feedback: originalUserFeedback,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation("feedback");

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleFeedbackDeleted(originalUserFeedback);
  };

  return (
    <>
      {(originalUserFeedback.commenter.id === keycloak?.idTokenParsed?.sub ||
        keycloak?.hasRealmRole(Roles.administrator)) && (
        <>
          <Grid item xs={1} className={classes.deleteButton}>
            <Button
              onClick={() => handleDeleteDialogOpen()}
              startIcon={<DeleteIcon />}
            >
              {t("delete")}
            </Button>
          </Grid>
          <ConfirmDialog
            dialogOpen={deleteDialogOpen}
            title={t("deleteFeedbackTitle")}
            description={`${t("deleteFeedbackDescription")} '${
              originalUserFeedback.feedback
            }'`}
            handleDialogClose={handleDeleteDialogClose}
            handleDialogConfirmClicked={handleDeleteClicked}
          />
        </>
      )}
    </>
  );
};
