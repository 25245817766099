import React, { FC } from "react";
import { PlotComponent } from "../PlotComponent/PlotComponent";

interface Props {
  readonly data: Plotly.Data[];
  readonly titleString: string;
  readonly xAxisTitle: string;
  readonly yAxisTitle: string;
}
export const BaseBarChart: FC<Props> = ({
  data,
  titleString,
  xAxisTitle,
  yAxisTitle,
}) => {
  return (
    <PlotComponent
      useResizeHandler={true}
      data={data}
      style={{ width: "100%", height: "100%" }}
      config={{
        displayModeBar: false,
        scrollZoom: false,
      }}
      layout={{
        yaxis: {
          fixedrange: false,
          automargin: true,
          rangemode: "nonnegative",
          title: yAxisTitle,
        },
        xaxis: {
          fixedrange: false,
          automargin: true,
          rangemode: "normal",
          title: xAxisTitle,
        },
        legend: {
          font: {
            family: '"Red Hat Display", sans-serif',
            size: 18,
            color: "black",
          },
        },
        autosize: true,
        title: {
          text: titleString,
          font: {
            family: '"Red Hat Display", sans-serif',
            size: 24,
            color: "black",
          },
        },
      }}
    />
  );
};
