import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FontConstants from "Constants/FontConstants";
import { CarEvidenceDocument } from "Models/CarEvidenceDocument";
import { InitializedComment } from "Models/InitialComment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  handleAddComment(comment: InitializedComment): void;
  readonly carEvidenceDocument: CarEvidenceDocument;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      width: "95%",
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: FontConstants.SUBHEADING_WEIGHT,
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
    languageTextFields: {
      margin: theme.spacing(1),
    },
  }),
);

export const CarEvidenceCommentAdd: FC<Props> = ({
  handleAddComment,
  carEvidenceDocument,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");

  const [initializedComment] = useState<InitializedComment>(
    new InitializedComment(),
  );

  const handleSubmit = (event: SyntheticEvent) => {
    if (initializedComment.carEvidenceId === null) {
      initializedComment.carEvidenceId = carEvidenceDocument.id;
    }
    event.preventDefault();
    if (initializedComment) {
      handleAddComment(initializedComment);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("addComment")}</Typography>
      </Grid>
      <div className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              className={classes.languageTextFields}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("comment")}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                initializedComment.content = event.target.value;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" startIcon={<SaveIcon />}>
              {t("save")}
            </Button>
          </Grid>
        </form>
      </div>
    </Grid>
  );
};
