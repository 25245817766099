import { Point } from "react-simple-maps";

export default class MapConstants {
  public static readonly MAXIMUM_ZOOM = 100;
  public static readonly MINIMUM_ZOOM = 25;
  public static readonly STARTING_COORDINATES: Point = [11.563, -6.645];
  public static readonly STARTING_ZOOM = 40;
  public static readonly MAP_HEIGHT = 1050;
  public static readonly MAP_SCALE = 15500;
  public static readonly DETAILED_BLOCK_MAP_HEIGHT = 410;
  public static readonly DETAILED_BLOCK_MAP_SCALE = 200;
  public static readonly FPSO_MARKER_WIDTH = 22;
  public static readonly FPSO_MARKER_HEIGHT = 10;
}
