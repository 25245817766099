import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateConstants from "Constants/DateConstants";
import { SubSystemType } from "Enums/SubSystemType";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemDefaultLabelLogic } from "Logic/SubSystemOptions/SubSystemDefaultLabelLogic";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { Audit } from "Models/Audit";
import { OptionType } from "Models/OptionType";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  readonly audit: Audit;
  handleAuditEdited(
    updatedAudit: Audit,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    saveButtonRow: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
);

export const AuditDetailsEdit: FC<Props> = ({
  audit: originalAudit,
  handleAuditEdited,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("audits");

  const [unchangedAudit] = useState<Audit>({
    englishTranslation: [
      {
        id: originalAudit.englishTranslation[0].id,
        auditSummary: originalAudit.englishTranslation[0].auditSummary,
        mainFindings: originalAudit.englishTranslation[0].mainFindings,
        professionalOpinion:
          originalAudit.englishTranslation[0].professionalOpinion,
        actionsRecommendations:
          originalAudit.englishTranslation[0].actionsRecommendations,
      },
    ],
    portugueseTranslation: [
      {
        id: originalAudit.portugueseTranslation[0].id,
        auditSummary: originalAudit.portugueseTranslation[0].auditSummary,
        mainFindings: originalAudit.portugueseTranslation[0].mainFindings,
        professionalOpinion:
          originalAudit.portugueseTranslation[0].professionalOpinion,
        actionsRecommendations:
          originalAudit.portugueseTranslation[0].actionsRecommendations,
      },
    ],
    startDate: originalAudit.startDate,
    endDate: originalAudit.endDate,
    id: originalAudit.id,
    auditReference: originalAudit.auditReference,
    auditScore: originalAudit.auditScore,
    createdAt: originalAudit.createdAt,
    leadAuditor: originalAudit.leadAuditor,
    isActive: originalAudit.isActive,
    isLocked: originalAudit.isLocked,
    lockingUser: originalAudit.lockingUser,
    fpsoAreaId: originalAudit.fpsoAreaId,
    fpsoId: originalAudit.fpsoId,
    videoSessions: originalAudit.videoSessions,
    activeVideoSessions: originalAudit.activeVideoSessions,
    itemsWithSubSystems: originalAudit.itemsWithSubSystems,
  });

  const [updatedAudit, setUpdatedAudit] = useState(unchangedAudit);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();
  const [changeInSubSystems, setChangeInSubSystems] = useState(false);

  const defaultSelectedSubSystems: OptionType[] = [];
  unchangedAudit.itemsWithSubSystems.forEach(subSystem => {
    const defaultLabelBlank = "";
    const defaultLabel = SubSystemDefaultLabelLogic(
      defaultLabelBlank,
      subSystem,
    );
    defaultSelectedSubSystems.push({
      value: subSystem.subSystemType,
      label: defaultLabel,
    });
  });

  const handleSubmit = (event: SyntheticEvent) => {
    if (changeInSubSystems === true) {
      const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
        selectedSubSystems,
      );

      event.preventDefault();
      handleAuditEdited(
        updatedAudit,
        selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    } else {
      const unchangedSubSystemsSubSystemTypeArray: SubSystemType[] = [];

      event.preventDefault();
      handleAuditEdited(
        updatedAudit,
        unchangedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    }
  };

  const handleStartDateChange = (selectedDate: MaterialUiPickersDate) => {
    const audit = { ...updatedAudit };
    audit.startDate = selectedDate ? selectedDate.toDate() : null;
    setUpdatedAudit(audit);
  };

  const handleEndDateChange = (selectedDate: MaterialUiPickersDate) => {
    const audit = { ...updatedAudit };
    if (selectedDate) {
      audit.endDate = selectedDate.toDate();
    } else {
      audit.endDate = selectedDate;
    }
    setUpdatedAudit(audit);
  };

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
    setChangeInSubSystems(true);
  };

  const Option = CheckboxOption;

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <FormGroup>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("auditSummary") + t("englishLanguageSuffix")}
              defaultValue={unchangedAudit.englishTranslation[0].auditSummary}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.englishTranslation[0]) {
                  updatedAudit.englishTranslation[0].auditSummary =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("auditSummary") + t("portugueseLanguageSuffix")}
              defaultValue={
                unchangedAudit.portugueseTranslation[0]?.auditSummary
              }
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.portugueseTranslation[0]) {
                  updatedAudit.portugueseTranslation[0].auditSummary =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("mainFindings") + t("englishLanguageSuffix")}
              defaultValue={unchangedAudit.englishTranslation[0]?.mainFindings}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.englishTranslation[0]) {
                  updatedAudit.englishTranslation[0].mainFindings =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("mainFindings") + t("portugueseLanguageSuffix")}
              defaultValue={
                unchangedAudit.portugueseTranslation[0]?.mainFindings
              }
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.portugueseTranslation[0]) {
                  updatedAudit.portugueseTranslation[0].mainFindings =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("professionalOpinion") + t("englishLanguageSuffix")}
              defaultValue={
                unchangedAudit.englishTranslation[0]?.professionalOpinion
              }
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.englishTranslation[0]) {
                  updatedAudit.englishTranslation[0].professionalOpinion =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("professionalOpinion") + t("portugueseLanguageSuffix")}
              defaultValue={
                unchangedAudit.portugueseTranslation[0]?.professionalOpinion
              }
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.portugueseTranslation[0]) {
                  updatedAudit.portugueseTranslation[0].professionalOpinion =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("actionsRecommendations") + t("englishLanguageSuffix")}
              defaultValue={
                unchangedAudit.englishTranslation[0]?.actionsRecommendations
              }
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.englishTranslation[0]) {
                  updatedAudit.englishTranslation[0].actionsRecommendations =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={
                t("actionsRecommendations") + t("portugueseLanguageSuffix")
              }
              defaultValue={
                unchangedAudit.portugueseTranslation[0]?.actionsRecommendations
              }
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (updatedAudit.portugueseTranslation[0]) {
                  updatedAudit.portugueseTranslation[0].actionsRecommendations =
                    event.target.value;
                }
              }}
            />
          </FormControl>
          <Grid container>
            <FormControl className={classes.formControl}>
              <Grid item xs={12}>
                <DatePicker
                  clearable={true}
                  label={t("startDate")}
                  value={updatedAudit.startDate}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    handleStartDateChange(selectedDate);
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </Grid>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Grid item xs={12}>
                <DatePicker
                  clearable={true}
                  label={t("endDate")}
                  value={updatedAudit.endDate}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    handleEndDateChange(selectedDate);
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </Grid>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Select
                onChange={option => handleChange(option)}
                options={subSystemOptions}
                isMulti
                autoFocus
                isSearchable
                isClearable
                styles={CustomStylesSubSystemOptions}
                placeholder={t("fpsos:helperTextEditSubSystems")}
                defaultValue={defaultSelectedSubSystems}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.saveButtonRow}>
            <Button type="submit" startIcon={<SaveIcon />}>
              {t("save")}
            </Button>
          </Grid>
        </FormGroup>
      </Grid>
    </form>
  );
};
