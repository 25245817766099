import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { Roles } from "Enums/Roles";
import { keycloak } from "keycloak";
import { FeedbackReply } from "Models/FeedbackReply";
import { NewFeedbackReply } from "Models/NewFeedbackReply";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";

interface Props {
  handleFeedbackReplyDeleted(deletedFeedbackReply: NewFeedbackReply): void;
  readonly feedbackReply: FeedbackReply;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      paddingRight: theme.spacing(10),
    },
  }),
);

export const UserFeedbackReplyDelete: FC<Props> = ({
  handleFeedbackReplyDeleted,
  feedbackReply: originalUserFeedbackReply,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation("feedback");

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleFeedbackReplyDeleted(originalUserFeedbackReply);
  };

  return (
    <>
      {(originalUserFeedbackReply.replier.id === keycloak?.idTokenParsed?.sub ||
        keycloak?.hasRealmRole(Roles.administrator)) && (
        <>
          <Grid item xs={1} className={classes.deleteButton}>
            <Button
              onClick={() => handleDeleteDialogOpen()}
              startIcon={<DeleteIcon />}
            >
              {t("delete")}
            </Button>
          </Grid>
          <ConfirmDialog
            dialogOpen={deleteDialogOpen}
            title={t("deleteFeedbackReplyTitle")}
            description={`${t("deleteFeedbackReplyDescription")} '${
              originalUserFeedbackReply.reply
            }'`}
            handleDialogClose={handleDeleteDialogClose}
            handleDialogConfirmClicked={handleDeleteClicked}
          />
        </>
      )}
    </>
  );
};
