import DateConstants from "Constants/DateConstants";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { PlotComponent } from "../PlotComponent/PlotComponent";

interface Props {
  readonly data: Plotly.Data[];
  readonly titleString: string;
  readonly sortedDates: Date[];
}

export const BaseLineChart: FC<Props> = ({
  data,
  titleString,
  sortedDates,
}) => {
  const { t } = useTranslation("kpis");
  return (
    <PlotComponent
      useResizeHandler={true}
      data={data}
      style={{ width: "100%", height: "100%" }}
      config={{
        displayModeBar: false,
        scrollZoom: false,
      }}
      layout={{
        yaxis: {
          fixedrange: true,
          automargin: true,
          rangemode: "nonnegative",
        },
        xaxis: {
          rangeselector: {
            buttons: [
              {
                step: "month",
                stepmode: "backward",
                count: 1,
                label: t("lastMonth"),
              },
              {
                step: "month",
                stepmode: "backward",
                count: 6,
                label: t("last6Months"),
              },
              {
                step: "year",
                stepmode: "backward",
                count: 1,
                label: t("lastYear"),
              },
              {
                step: "year",
                stepmode: "todate",
                count: 1,
                label: t("ytd"),
              },

              {
                step: "day",
                stepmode: "backward",
                count: moment().diff(moment(sortedDates[0]), "days") + 10,
                label: t("entireRange"),
              },
            ],
          },

          fixedrange: true,
          automargin: true,
          type: "date",
          rangemode: "normal",
          range: [
            moment(sortedDates[0])
              .subtract(7, "d")
              .format(DateConstants.REVERSE_DEFAULT_DATE_FORMAT),
            moment()
              .add(3, "d")
              .format(DateConstants.REVERSE_DEFAULT_DATE_FORMAT),
          ],
        },
        showlegend: true,
        legend: {
          font: {
            family: '"Red Hat Display", sans-serif',
            size: 18,
            color: "black",
          },
        },
        autosize: true,
        title: {
          text: titleString,
          font: {
            family: '"Red Hat Display", sans-serif',
            size: 24,
            color: "black",
          },
        },
      }}
    />
  );
};
