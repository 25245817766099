import React, { FC } from "react";

interface Props {
  readonly fillColor: string;
  readonly strokeColor: string;
}

export const TriangleIcon: FC<Props> = ({ fillColor, strokeColor }) => {
  return (
    <svg
      className="trianlge"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <polygon
        points="14, 2, 26, 26, 2, 26"
        stroke={strokeColor}
        strokeWidth="2"
        fill={fillColor}
      />
    </svg>
  );
};
