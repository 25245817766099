import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import ValidationConstants from "Constants/ValidationConstants";
import { Roles } from "Enums/Roles";
import { VideoSessionEvidence } from "Models/VideoSessionEvidence";
import { VideoSessionRow } from "Models/VideoSessionRow";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "../FileDropZone/FileDropZone";
import { SaveIcon } from "../Icons/SaveIcon";

interface Props {
  handleAddVideoSessionEvidence(
    videoSessionEvidence: VideoSessionEvidence,
    file: File,
  ): void;
  tableData: VideoSessionRow[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    saveButtonRow: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
);

export const AuditVideoSessionEvidenceAdd: FC<Props> = ({
  handleAddVideoSessionEvidence,
  tableData,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("audits");
  const { keycloak } = useKeycloak();

  const [newVideoSessionEvidence, setNewVideoSessionEvidence] = useState<
    VideoSessionEvidence
  >({} as VideoSessionEvidence);
  const [file, setFile] = useState<File>();

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (newVideoSessionEvidence.createdAt === undefined) {
      newVideoSessionEvidence.createdAt = moment().toDate();
    }
    if (newVideoSessionEvidence && file) {
      handleAddVideoSessionEvidence(newVideoSessionEvidence, file);
    }
  };

  const handleFileSelected = (selectedFiles: File[]) => {
    setFile(selectedFiles[0]);
  };

  const handleCreatedAtDateChange = (date: Date) => {
    const videoSessionEvidence = { ...newVideoSessionEvidence };
    videoSessionEvidence.createdAt = date;
    setNewVideoSessionEvidence(videoSessionEvidence);
  };

  const handleVideoSessionIdChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const videoSessionEvidence = { ...newVideoSessionEvidence };
    videoSessionEvidence.videoSessionId = event.target.value as string;

    setNewVideoSessionEvidence(videoSessionEvidence);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("addVideoSessionEvidenceTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <FormGroup row>
            <Grid item xs={6}>
              <FormControl className={classes.textFormControl}>
                <TextField
                  label={t("description")}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    newVideoSessionEvidence.description = event.target.value;
                  }}
                  required
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel shrink>{t("DateAndTimeOfSession")}</InputLabel>
                <Select
                  value={newVideoSessionEvidence.videoSessionId}
                  onChange={handleVideoSessionIdChange}
                  required
                >
                  {tableData.map(data => (
                    <MenuItem value={data.id}>
                      {moment(data.createdAt).format(
                        DateConstants.DATE_TIME_FORMAT,
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              {
                <FileDropZone
                  handleFilesSelected={handleFileSelected}
                  allowMultipleFiles={false}
                  acceptedFileTypes={
                    ValidationConstants.ACCEPTED_DOCUMENT_TYPES
                  }
                />
              }
            </Grid>
          </FormGroup>

          <FormGroup row>
            {keycloak?.hasRealmRole(Roles.administrator) && (
              <FormControl className={classes.formControl}>
                <Typography variant={"body1"}>
                  {t("cars:adminDescriptionDateAdded")}
                </Typography>
                <DatePicker
                  clearable={true}
                  label={t("cars:dateAdded")}
                  value={newVideoSessionEvidence.createdAt}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handleCreatedAtDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </FormControl>
            )}
          </FormGroup>
          <Grid item xs={12} className={classes.saveButtonRow}>
            <Button type="submit" startIcon={<SaveIcon />}>
              {t("save")}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
