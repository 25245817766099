import { useMutation, useQuery } from "@apollo/react-hooks";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import {
  GET_FPSO_AREA_BY_ID,
  GetFpsoAreaByIdQueryResult,
  GetFpsoAreaByIdQueryVariables,
  UPDATE_FPSO_AREA,
} from "Graphql/FpsoQueries";
import { FPSOArea } from "Models/FPSOArea";
import { RouteParams } from "Models/RouteParams";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FpsoAreaBasicDetails } from "./FpsoAreaBasicDetails";

export const FpsoAreaOverview: FC = () => {
  const { fpsoAreaId } = useParams<RouteParams>();
  const { t } = useTranslation();

  const {
    loading: fpsoAreaLoading,
    error: fpsoAreaError,
    data: fpsoAreaInfo,
    refetch,
  } = useQuery<GetFpsoAreaByIdQueryResult, GetFpsoAreaByIdQueryVariables>(
    GET_FPSO_AREA_BY_ID,
    { variables: { id: fpsoAreaId || "undefined" } },
  );
  const [updateFpsoArea, { error: updateFpsoAreaError }] = useMutation(
    UPDATE_FPSO_AREA,
  );

  const handleFpsoAreaEdit = (updatedFpsoArea: FPSOArea) => {
    updateFpsoArea({
      variables: {
        id: updatedFpsoArea.id,
        fpsoAreaEnglishDescription:
          updatedFpsoArea.englishTranslation[0].fpsoAreaDescription,
        fpsoAreaPortugueseDescription:
          updatedFpsoArea.portugueseTranslation[0].fpsoAreaDescription,
        mainContactEmail: updatedFpsoArea.mainContactEmail,
        mainContactName: updatedFpsoArea.mainContactName,
      },
    }).then(() => {
      refetch({
        id: fpsoAreaId || "undefined",
      });
    });
  };

  if (fpsoAreaLoading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (fpsoAreaError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:fpsoFetchError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (updateFpsoAreaError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:fpsoUpdateError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {fpsoAreaInfo && fpsoAreaInfo.fpsoArea ? (
        <>
          <Grid container>
            <FpsoAreaBasicDetails
              fpsoArea={fpsoAreaInfo.fpsoArea}
              handleFpsoAreaEdited={handleFpsoAreaEdit}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {t("errors:fpsoFetchError")}
        </Grid>
      )}
    </>
  );
};
