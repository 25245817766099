import {
  Button,
  createStyles,
  Grid,
  GridSize,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import { FpsoAreaSystemComponent } from "Models/FpsoAreaSystemComponent";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";
import { FpsoAreaSystemComponentDetailsEdit } from "./FpsoAreaSystemComponentDetailsEdit";

interface Props {
  readonly componentRow: FpsoAreaSystemComponent;
  handleRowEdited?(
    updatedComponentRow: FpsoAreaSystemComponent,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  handleRowDeleted?(deletedComponentRow: FpsoAreaSystemComponent): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: "bold",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
    subSystemList: {
      display: "flex",
    },
    subSystemItem: {
      padding: theme.spacing(1),
    },
  }),
);

export const FpsoAreaSystemComponentDetailsPanel: FC<Props> = ({
  componentRow: originalComponentRow,
  handleRowEdited: handleEditedRowReceived,
  handleRowDeleted,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleRowEdited = (
    updatedComponentRow: FpsoAreaSystemComponent,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    setEditEnabled(false);
    if (handleEditedRowReceived) {
      handleEditedRowReceived(
        updatedComponentRow,
        updatedSubSystems,
        changeInSubSystems,
      );
    }
  };

  let editButtonSize: GridSize = 12;
  if (originalComponentRow.itemsWithSubSystems.length !== 0) {
    editButtonSize = 1;
  }

  return (
    <Grid container className={classes.root}>
      {!editEnabled && (
        <>
          {originalComponentRow.itemsWithSubSystems.length !== 0 && (
            <Grid item xs={11} className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("subSystems")}</div>
              <div className={classes.subSystemList}>
                {originalComponentRow.itemsWithSubSystems.map(subSystem => (
                  <Paper className={classes.subSystemItem} variant="outlined">
                    {t("fpsos:" + subSystem.subSystemType)}
                  </Paper>
                ))}
              </div>
            </Grid>
          )}
          {(keycloak?.hasRealmRole(Roles.auditor) ||
            keycloak?.hasRealmRole(Roles.administrator)) && (
            <Grid item xs={editButtonSize} className={classes.detailsButtonRow}>
              <Tooltip
                title={t<string>("tooltipEditSystemComponent")}
                arrow={true}
              >
                <Button onClick={handleEditClicked}>
                  <EditAuditInfoIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </>
      )}
      {editEnabled && (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">{t("editSystemComponentTitle")}</Typography>
          <Tooltip title={t<string>("tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">{t("discardChanges")}</Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      {editEnabled && handleRowDeleted ? (
        <FpsoAreaSystemComponentDetailsEdit
          fpsoAreaName={fpsoAreaName}
          componentRow={originalComponentRow}
          handleRowDeleted={handleRowDeleted}
          handleRowEdited={handleRowEdited}
        />
      ) : (
        <>
          <Grid item xs={2} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("createdAt")}</div>
            <div>
              {moment(originalComponentRow.createdAt).format(
                DateConstants.DEFAULT_DATE_FORMAT,
              )}
            </div>
          </Grid>
          <Grid item xs={2} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("updatedAt")}</div>
            <div>
              {moment(originalComponentRow.updatedAt).format(
                DateConstants.DEFAULT_DATE_FORMAT,
              )}
            </div>
          </Grid>

          <Grid item xs={4} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("description")}</div>
            <div>{originalComponentRow.description}</div>
          </Grid>
          <Grid item xs={4} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("comment")}</div>
            <div>{originalComponentRow.comment}</div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
