import React, { FC } from "react";
import { PlotComponent } from "Views/Components/PlotComponent/PlotComponent";

interface Props {
  readonly data: Plotly.Data[];
  readonly titleText: string;
  readonly subtitleText: string;
  readonly subtitlePositionY: number;
  readonly secondarySubtitleText: string;
  readonly secondarySubtitlePositionY: number;
}

export const DoublePieChart: FC<Props> = ({
  data,
  titleText,
  subtitleText,
  subtitlePositionY,
  secondarySubtitleText,
  secondarySubtitlePositionY,
}) => {
  const titleFont = {
    family: '"Red Hat Display", sans-serif',
    size: 19,
    color: "black",
  };
  const subHeadingFont = {
    family: '"Red Hat Display", sans-serif',
    size: 12,
    color: "black",
  };
  return (
    <PlotComponent
      useResizeHandler={true}
      data={data}
      config={{
        displayModeBar: false,
        scrollZoom: false,
      }}
      layout={{
        grid: { rows: 1, columns: 2 },
        yaxis: {
          fixedrange: true,
          automargin: true,
          rangemode: "nonnegative",
        },
        xaxis: {
          fixedrange: true,
          automargin: true,
          type: "category",
          rangemode: "nonnegative",
        },
        showlegend: true,
        legend: {
          orientation: "v",
          x: 0.5,
          y: 0,
          borderwidth: 2,
          bordercolor: "black",
        },
        annotations: [
          {
            text: titleText,
            showarrow: false,
            width: 500,
            height: 20,
            x: 0.5,
            y: 1.12,
            font: {
              family: titleFont.family,
              size: titleFont.size,
              color: titleFont.color,
            },
          },
          {
            text: subtitleText,
            showarrow: false,
            x: -0.05,
            y: subtitlePositionY,
            font: {
              family: subHeadingFont.family,
              size: subHeadingFont.size,
              color: subHeadingFont.color,
            },
          },
          {
            text: secondarySubtitleText,
            showarrow: false,
            x: 1.015,
            y: secondarySubtitlePositionY,
            font: {
              family: subHeadingFont.family,
              size: subHeadingFont.size,
              color: subHeadingFont.color,
            },
          },
        ],
      }}
    />
  );
};
