import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MaterialSelect,
  TextField,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import { DocumentType } from "Enums/DocumentType";
import { SubSystemType } from "Enums/SubSystemType";
import { SystemArea } from "Enums/SystemArea";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemDefaultLabelLogic } from "Logic/SubSystemOptions/SubSystemDefaultLabelLogic";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { FpsoAreaDocument } from "Models/FpsoAreaDocument";
import { OptionType } from "Models/OptionType";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { SaveIcon } from "../Icons/SaveIcon";

interface Props {
  readonly documentRow: FpsoAreaDocument;
  handleRowEdited(
    updatedDocumentRow: FpsoAreaDocument,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  handleDocumentDeleted(deletedComponentRow: FpsoAreaDocument): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      width: "100%",
      paddingRight: theme.spacing(6),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.SYSTEM_COMPONENT_EDIT_FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.SYSTEM_COMPONENT_EDIT_FORM_INPUT_WIDTH,
      width: "100%",
    },
    formRowSpacing: {
      margin: theme.spacing(1),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    bottomButtonRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    languageTextFields: {
      margin: theme.spacing(1),
    },
  }),
);

export const FpsoAreaDocumentDetailsEdit: FC<Props> = ({
  documentRow: originalDocumentRow,
  handleRowEdited,
  handleDocumentDeleted,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");

  const [unchangedDocumentRow] = useState<FpsoAreaDocument>({
    id: originalDocumentRow.id,
    type: originalDocumentRow.type,
    name: originalDocumentRow.name,
    description: originalDocumentRow.description,
    reference: originalDocumentRow.reference,
    publishDate: originalDocumentRow.publishDate,
    systemArea: originalDocumentRow.systemArea,
    comment: originalDocumentRow.comment,
    uploader: originalDocumentRow.uploader,
    documentNumber: originalDocumentRow.documentNumber,
    revision: originalDocumentRow.revision,
    itemsWithSubSystems: originalDocumentRow.itemsWithSubSystems,
  });

  const [updatedDocumentRow, setUpdatedDocumentRow] = useState(
    unchangedDocumentRow,
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();
  const [changeInSubSystems, setChangeInSubSystems] = useState(false);

  const defaultSelectedSubSystems: OptionType[] = [];
  unchangedDocumentRow.itemsWithSubSystems.forEach(subSystem => {
    const defaultLabelBlank = "";
    const defaultLabel = SubSystemDefaultLabelLogic(
      defaultLabelBlank,
      subSystem,
    );
    defaultSelectedSubSystems.push({
      value: subSystem.subSystemType,
      label: defaultLabel,
    });
  });

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    if (changeInSubSystems === true) {
      const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
        selectedSubSystems,
      );

      event.preventDefault();
      handleRowEdited(
        updatedDocumentRow,
        selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    } else {
      const unchangedSubSystemsSubSystemTypeArray: SubSystemType[] = [];

      event.preventDefault();
      handleRowEdited(
        updatedDocumentRow,
        unchangedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    }
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleDocumentDeleted(updatedDocumentRow);
  };

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
    setChangeInSubSystems(true);
  };

  const handleDocumentTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const documentRow = { ...updatedDocumentRow };
    documentRow.type = event.target.value as DocumentType;
    setUpdatedDocumentRow(documentRow);
  };

  const handleSystemAreaChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const documentRow = { ...updatedDocumentRow };
    documentRow.systemArea = event.target.value as SystemArea;
    setUpdatedDocumentRow(documentRow);
  };

  const handleDatePublishedChange = (selectedDate: MaterialUiPickersDate) => {
    const documentRow = { ...updatedDocumentRow };
    documentRow.publishDate = selectedDate ? selectedDate.toDate() : null;
    setUpdatedDocumentRow(documentRow);
  };

  const Option = CheckboxOption;

  return (
    <form onSubmit={handleSubmit} className={classes.formRoot}>
      <FormGroup>
        <FormGroup row className={classes.formRowSpacing}>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("doc_name")}
              defaultValue={unchangedDocumentRow.name}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedDocumentRow.name = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("documentNumber")}
              defaultValue={unchangedDocumentRow.documentNumber}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedDocumentRow.documentNumber = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("revision")}
              defaultValue={unchangedDocumentRow.revision}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedDocumentRow.revision = event.target.value;
              }}
            />
          </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formRowSpacing}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="class-select-label">
              {t("doc_type")}
            </InputLabel>
            <MaterialSelect
              value={updatedDocumentRow.type}
              onChange={handleDocumentTypeChange}
            >
              <MenuItem value={DocumentType.other}>
                {t("files:documentTypeOther")}
              </MenuItem>
              <MenuItem value={DocumentType.procedure}>
                {t("files:documentTypeProcedure")}
              </MenuItem>
              <MenuItem value={DocumentType.report}>
                {t("files:documentTypeReport")}
              </MenuItem>
              <MenuItem value={DocumentType.certificate}>
                {t("files:documentTypeCertificate")}
              </MenuItem>
              <MenuItem value={DocumentType.drawing}>
                {t("files:documentTypeDrawing")}
              </MenuItem>
              <MenuItem value={DocumentType.list}>
                {t("files:documentTypeList")}
              </MenuItem>
              <MenuItem value={DocumentType.specification}>
                {t("files:documentTypeSpecification")}
              </MenuItem>
              <MenuItem value={DocumentType.contractualDocument}>
                {t("files:documentTypeContractualDocument")}
              </MenuItem>
              <MenuItem value={DocumentType.documentDataSheet}>
                {t("files:documentTypeDocumentDataSheet")}
              </MenuItem>
              <MenuItem value={DocumentType.dataSheet}>
                {t("files:documentTypeDataSheet")}
              </MenuItem>
              <MenuItem value={DocumentType.functionalDesign}>
                {t("files:documentTypeFunctionalDesign")}
              </MenuItem>
              <MenuItem value={DocumentType.isometricDrawings}>
                {t("files:documentTypeIsometricDrawings")}
              </MenuItem>
              <MenuItem value={DocumentType.inspectionDataSheet}>
                {t("files:documentTypeInspectionDataSheet")}
              </MenuItem>
              <MenuItem value={DocumentType.minutesOfMeeting}>
                {t("files:documentTypeMinutesOfMeeting")}
              </MenuItem>
              <MenuItem value={DocumentType.planningAndScheduling}>
                {t("files:documentTypePlanningAndScheduling")}
              </MenuItem>
              <MenuItem value={DocumentType.processAndInstrumentDiagram}>
                {t("files:documentTypeProcessAndInstrumentDiagram")}
              </MenuItem>
              <MenuItem value={DocumentType.technicalDataSheet}>
                {t("files:documentTypeTechnicalDataSheet")}
              </MenuItem>
              <MenuItem value={DocumentType.technicalQuery}>
                {t("files:documentTypeTechnicalQuery")}
              </MenuItem>
            </MaterialSelect>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel shrink id="class-select-label">
              {t("system_area")}
            </InputLabel>
            <MaterialSelect
              value={updatedDocumentRow.systemArea}
              onChange={handleSystemAreaChange}
            >
              <MenuItem value={SystemArea.chemicalsAndFuel}>
                {t("chemicalsAndFuel")}
              </MenuItem>
              <MenuItem value={SystemArea.electrical}>
                {t("electrical")}
              </MenuItem>
              <MenuItem value={SystemArea.hardware}>{t("hardware")}</MenuItem>
              <MenuItem value={SystemArea.instrument}>
                {t("instrument")}
              </MenuItem>
              <MenuItem value={SystemArea.liftingEquipment}>
                {t("liftingEquipment")}
              </MenuItem>
              <MenuItem value={SystemArea.mechanical}>
                {t("mechanical")}
              </MenuItem>
              <MenuItem value={SystemArea.other}>{t("other")}</MenuItem>
              <MenuItem value={SystemArea.piping}>{t("piping")}</MenuItem>
              <MenuItem value={SystemArea.process}>{t("process")}</MenuItem>
              <MenuItem value={SystemArea.safety}>{t("safety")}</MenuItem>
              <MenuItem value={SystemArea.vessel}>{t("vessel")}</MenuItem>
              <MenuItem value={SystemArea.wasteManagement}>
                {t("wasteManagement")}
              </MenuItem>
              <MenuItem value={SystemArea.welfare}>{t("welfare")}</MenuItem>
            </MaterialSelect>
          </FormControl>
          <FormControl className={classes.formControl}>
            <DatePicker
              clearable={true}
              label={t("publish_date")}
              value={updatedDocumentRow.publishDate}
              placeholder={moment().format(DateConstants.DEFAULT_DATE_FORMAT)}
              onChange={selectedDate => {
                handleDatePublishedChange(selectedDate);
              }}
              format={DateConstants.DEFAULT_DATE_FORMAT}
            />
          </FormControl>
        </FormGroup>

        <FormGroup row className={classes.formRowSpacing}>
          <FormControl className={classes.textFormControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("doc_description")}
              defaultValue={unchangedDocumentRow.description}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedDocumentRow.description = event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Select
              onChange={option => handleChange(option)}
              options={subSystemOptions}
              isMulti
              autoFocus
              isSearchable
              isClearable
              styles={CustomStylesSubSystemOptions}
              placeholder={t("helperTextEditSubSystems")}
              defaultValue={defaultSelectedSubSystems}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
            />
          </FormControl>
        </FormGroup>

        <Grid item xs={12} className={classes.bottomButtonRow}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
          <Button
            onClick={() => handleDeleteDialogOpen()}
            startIcon={<DeleteIcon />}
          >
            {t("delete")}
          </Button>
        </Grid>
        <ConfirmDialog
          dialogOpen={deleteDialogOpen}
          title={t("deleteDocumentTitle")}
          description={t("deleteDocumentDescription")}
          handleDialogClose={handleDeleteDialogClose}
          handleDialogConfirmClicked={handleDeleteClicked}
        />
      </FormGroup>
    </form>
  );
};
