import React, { FC } from "react";

export const ArchivedAuditIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <path
        fill="#575756"
        d="M47.38,28.24V10.67c0-1.18-0.96-2.13-2.13-2.13h0h-6.03l0-0.6c0-0.27-0.11-0.52-0.29-0.71s-0.44-0.29-0.71-0.29
	h-4.06c-0.47-1.96-2.24-3.42-4.35-3.42c-2.11,0-3.89,1.46-4.35,3.42H21.4c-0.27,0-0.52,0.11-0.71,0.29
	C20.5,7.41,20.4,7.67,20.4,7.93c0,0,0,0.22,0,0.6h-6.03h0c-1.18,0-2.13,0.96-2.13,2.13v43.62c0,1.18,0.96,2.13,2.13,2.13h0h30.87h0
	c1.18,0,2.13-0.96,2.13-2.13v-5.98c0,0,0,0,0,0L47.38,28.24C47.38,28.24,47.38,28.24,47.38,28.24z M37.22,8.53l0,3.04
	c0,0.09-0.07,0.16-0.16,0.16h-14.5c-0.05,0-0.09-0.03-0.11-0.05s-0.05-0.06-0.05-0.11c0-0.37,0-0.71,0-3.04h4.71
	c0.13-0.17,0.22-0.37,0.22-0.6c0.01-1.31,1.14-2.42,2.47-2.42c1.33,0,2.47,1.11,2.47,2.42c0,0.23,0.09,0.43,0.22,0.6H37.22z
	 M14.37,54.42c-0.07,0-0.13-0.06-0.13-0.13V10.67c0-0.07,0.06-0.13,0.13-0.13h6.03c0,0.33,0,0.67,0,1.04c0,0.58,0.22,1.12,0.63,1.53
	c0.41,0.41,0.95,0.63,1.53,0.63h14.5c1.19,0,2.16-0.97,2.16-2.16l0-1.04h6.03c0.07,0,0.13,0.06,0.13,0.13v43.62
	c0,0.07-0.06,0.13-0.13,0.13H14.37z"
      />
      <circle fill="#39A935" cx="29.81" cy="29.05" r="10.06" />
      <g>
        <path
          fill="#575756"
          d="M40.87,29.05c0-6.1-4.96-11.06-11.06-11.06c-6.1,0-11.06,4.96-11.06,11.06c0,3.93,2.06,7.37,5.15,9.33v10.93
		c0,0.39,0.22,0.74,0.58,0.91c0.35,0.17,0.77,0.11,1.06-0.14l4.16-3.47l4.16,3.47c0.18,0.15,0.41,0.23,0.64,0.23
		c0.14,0,0.29-0.03,0.42-0.09c0.35-0.16,0.58-0.52,0.58-0.91v-10.8C38.71,36.57,40.87,33.06,40.87,29.05z M33.5,47.18l-3.16-2.63
		c-0.19-0.15-0.41-0.23-0.64-0.23s-0.46,0.08-0.64,0.23l-3.16,2.63v-7.79c1.22,0.46,2.53,0.72,3.9,0.72c1.3,0,2.54-0.24,3.7-0.65
		V47.18z M29.81,38.11c-4.99,0-9.06-4.06-9.06-9.06s4.06-9.06,9.06-9.06s9.06,4.06,9.06,9.06S34.8,38.11,29.81,38.11z"
        />
        <path
          fill="#FFFFFF"
          d="M28.36,34.1c-0.26,0-0.51-0.1-0.71-0.29l-4.01-4.01c-0.39-0.39-0.39-1.02,0-1.41s1.02-0.39,1.41,0l3.3,3.3
		l6.19-6.19c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41l-6.9,6.9C28.88,34,28.62,34.1,28.36,34.1z"
        />
      </g>
    </svg>
  );
};
