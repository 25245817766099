import React, { FC } from "react";

export const SaveIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g>
        <path
          fill="#575756"
          d="M36.32,12.78c-0.55,0-1,0.45-1,1v10.11c0,0.55,0.45,1,1,1s1-0.45,1-1V13.78
		C37.32,13.23,36.87,12.78,36.32,12.78z"
        />
        <path
          fill="#575756"
          d="M42.51,8.8H12.37c-1.18,0-2.13,0.96-2.13,2.13V49c0,1.18,0.96,2.13,2.13,2.13h34.79
		c1.18,0,2.13-0.96,2.13-2.13V15.92L42.51,8.8z M20.09,10.8h19.33v16.32H20.09V10.8z M47.28,49c0,0.07-0.06,0.13-0.13,0.13H12.37
		c-0.07,0-0.13-0.06-0.13-0.13V10.94c0-0.07,0.06-0.13,0.13-0.13h5.73v17.32c0,0.55,0.45,1,1,1h21.33c0.55,0,1-0.45,1-1V10.8h0.23
		l5.63,5.91V49z"
        />
      </g>
    </svg>
  );
};
