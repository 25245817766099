import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import archive_en from "Assets/translations/en/archive.json";
import audits_en from "Assets/translations/en/audit.json";
import blocks_en from "Assets/translations/en/blocks.json";
import cars_en from "Assets/translations/en/cars.json";
import errors_en from "Assets/translations/en/errors.json";
import feedback_en from "Assets/translations/en/feedback.json";
import files_en from "Assets/translations/en/files.json";
import fpso_en from "Assets/translations/en/fpso.json";
import home_en from "Assets/translations/en/home.json";
import kpis_en from "Assets/translations/en/kpis.json";
import maps_en from "Assets/translations/en/maps.json";
import menu_en from "Assets/translations/en/menu.json";

import archive_pt from "Assets/translations/pt/archive.json";
import audits_pt from "Assets/translations/pt/audit.json";
import blocks_pt from "Assets/translations/pt/blocks.json";
import cars_pt from "Assets/translations/pt/cars.json";
import errors_pt from "Assets/translations/pt/errors.json";
import feedback_pt from "Assets/translations/pt/feedback.json";
import files_pt from "Assets/translations/pt/files.json";
import fpso_pt from "Assets/translations/pt/fpso.json";
import home_pt from "Assets/translations/pt/home.json";
import kpis_pt from "Assets/translations/pt/kpis.json";
import maps_pt from "Assets/translations/pt/maps.json";
import menu_pt from "Assets/translations/pt/menu.json";

const resources = {
  en: {
    menu: menu_en,
    blocks: blocks_en,
    audits: audits_en,
    errors: errors_en,
    cars: cars_en,
    fpsos: fpso_en,
    archive: archive_en,
    home: home_en,
    files: files_en,
    kpis: kpis_en,
    maps: maps_en,
    feedback: feedback_en,
  },
  pt: {
    menu: menu_pt,
    blocks: blocks_pt,
    audits: audits_pt,
    errors: errors_pt,
    cars: cars_pt,
    fpsos: fpso_pt,
    archive: archive_pt,
    home: home_pt,
    files: files_pt,
    kpis: kpis_pt,
    maps: maps_pt,
    feedback: feedback_pt,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
