import { useQuery } from "@apollo/react-hooks";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import Colors from "Constants/Colors";
import { CarClass } from "Enums/CarClass";
import {
  GET_CAR_CLASS_RATIO_BY_FPSO_AREA,
  GetCarClassRatioByFpsoAreaResult,
  GetCarClassRatioByFpsoAreaVariables,
} from "Graphql/FpsoQueries";
import { FPSOArea } from "Models/FPSOArea";
import { FC, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BasePieChart } from "Views/Components/FpsoOverview/Graphs/BasePieChart";

interface Props {
  readonly fpsoArea: FPSOArea;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorContainer: {
      padding: theme.spacing(2),
    },
  }),
);

export const CarClassRatioChart: FC<Props> = ({ fpsoArea }) => {
  const { t } = useTranslation("cars");
  const classes = useStyles();

  const [chartData, setChartData] = useState<Plotly.Data[]>([]);

  const handleCarRatioDataReceived = () => {
    const keys: string[] = [];
    const values: number[] = [];
    const sectionColors: string[] = [];

    data?.carClassRatios.forEach(ratio => {
      keys.push(
        ratio.class === CarClass.critical
          ? t("classCritical")
          : ratio.class === CarClass.major
          ? t("classMajor")
          : ratio.class === CarClass.minor
          ? t("classMinor")
          : ratio.class === CarClass.comment
          ? t("classComment")
          : "",
      );
      values.push(ratio.amount);
      sectionColors.push(
        ratio.class === CarClass.critical
          ? Colors.CAR_CLASS_CRITICAL_PIE_CHART
          : ratio.class === CarClass.major
          ? Colors.CAR_CLASS_MAJOR_PIE_CHART
          : ratio.class === CarClass.minor
          ? Colors.CAR_CLASS_MINOR_PIE_CHART
          : ratio.class === CarClass.comment
          ? Colors.CAR_CLASS_COMMENT_PIE_CHART
          : "",
      );
    });

    const carClassTrace = {
      values,
      labels: keys,
      type: "pie",
      name: "Car class ratio",
      textinfo: "value",
      textfont: {
        family: '"Red Hat Display", sans-serif',
        size: 20,
        color: Colors.DARK_GREY,
      },
      showlegend: true,
      sort: false,
      marker: {
        colors: sectionColors,
        line: {
          color: Colors.WHITE,
          width: [4, 4, 4, 4],
        },
      },
    };

    const plotData: Plotly.Data[] = [];
    plotData.push(carClassTrace as Plotly.Data);
    setChartData(plotData);
  };

  const { data } = useQuery<
    GetCarClassRatioByFpsoAreaResult,
    GetCarClassRatioByFpsoAreaVariables
  >(GET_CAR_CLASS_RATIO_BY_FPSO_AREA, {
    variables: {
      fpsoAreaId: fpsoArea.id,
    },
    onCompleted: handleCarRatioDataReceived,
  });

  const titletext = t("cars:carClassRatio");

  if (data?.carClassRatios && data?.carClassRatios.length !== 0) {
    return <BasePieChart data={chartData} titleText={titletext} />;
  }

  if (data?.carClassRatios.length === 0) {
    return (
      <div className={classes.errorContainer}>
        <Typography>{t("errors:carRatioChartFetchNoData")}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.errorContainer}>
      <Typography>{t("errors:carRatioChartFetchError")}</Typography>
    </div>
  );
};
