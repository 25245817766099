export enum SystemArea {
  vessel = "Vessel",
  other = "Other",
  electrical = "Electrical",
  instrument = "Instrument",
  mechanical = "Mechanical",
  hardware = "Hardware",
  piping = "Piping",
  welfare = "Welfare",
  process = "Process",
  safety = "Safety",
  liftingEquipment = "Lifting_Equipment",
  wasteManagement = "Waste_Management",
  chemicalsAndFuel = "Chemicals_and_Fuel",
}
