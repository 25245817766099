import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import { Roles } from "Enums/Roles";
import {
  GET_CURRENT_AUDIT_BY_FPSO_AREA,
  GetCurrentAuditByFpsoAreaQueryResult,
  GetCurrentAuditByFpsoAreaQueryVariables,
  INSERT_VIDEO_SESSION_EVIDENCE,
} from "Graphql/AuditQueries";
import { keycloak } from "keycloak";
import MaterialTable from "material-table";
import { RouteParams } from "Models/RouteParams";
import { VideoSessionEvidence } from "Models/VideoSessionEvidence";
import { VideoSessionRow } from "Models/VideoSessionRow";
import moment from "moment";
import React, { useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TableIcons } from "../Icons/TableIcons";
import { AddVideoSessionEvidenceButton } from "./AddVideoSessionEvidenceButton";
import { AuditVideoSessionEvidenceAdd } from "./AuditVideoSessionEvidenceAdd";
import { AuditVideoSessionEvidenceList } from "./AuditVideoSessionEvidenceList";

export const AuditVideoSessionList: FC = () => {
  const { t } = useTranslation("audits");
  const { fpsoAreaId } = useParams<RouteParams>();
  const [tableData, setTableData] = useState<VideoSessionRow[]>([]);
  const [selectedVideoSessionRow, setSelectedVideoSessionRow] = useState<
    VideoSessionRow
  >();
  const [
    videoSessionEvidenceAddFormEnabled,
    setVideoSessionEvidenceAddFormEnabled,
  ] = useState(false);

  const [insertVideoSessionEvidence] = useMutation(
    INSERT_VIDEO_SESSION_EVIDENCE,
  );

  const handleVideoSessionsReceived = () => {
    const videoSessionsData: VideoSessionRow[] = [];
    auditData?.fpsoArea.currentAudit.videoSessions.forEach(item => {
      videoSessionsData.push({
        ...item,
        sessionCreatorName: item.session_creator.fullName,
      });
    });
    setTableData(videoSessionsData);
  };

  const { data: auditData, refetch } = useQuery<
    GetCurrentAuditByFpsoAreaQueryResult,
    GetCurrentAuditByFpsoAreaQueryVariables
  >(GET_CURRENT_AUDIT_BY_FPSO_AREA, {
    fetchPolicy: "cache-and-network",
    variables: {
      fpsoAreaId: fpsoAreaId || "undefined",
    },
    onCompleted: handleVideoSessionsReceived,
  });

  const handleVideoSessionEvidenceAdd = (
    newAuditVideoSessionEvidence: VideoSessionEvidence,
    file: File,
  ) => {
    const formData = new FormData();
    formData.set(
      "folderName",
      `${auditData?.fpsoArea.fpso.id}/${fpsoAreaId}/${auditData?.fpsoArea.currentAudit.id}/${newAuditVideoSessionEvidence.videoSessionId}/`,
    );
    formData.append("file", file);

    Axios.post(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `bearer ${keycloak?.token}`,
      },
    }).then(result => {
      if (keycloak?.tokenParsed?.sub) {
        insertVideoSessionEvidence({
          variables: {
            evidenceReference: result.data,
            evidenceDescription: newAuditVideoSessionEvidence.description,
            auditId: auditData?.fpsoArea.currentAudit.id,
            submitterId: keycloak.tokenParsed.sub,
            videoSessionId: newAuditVideoSessionEvidence.videoSessionId,
            createdAt: newAuditVideoSessionEvidence.createdAt,
          },
        }).then(() => {
          refetch({ fpsoAreaId: fpsoAreaId || "undefined" });
          setVideoSessionEvidenceAddFormEnabled(false);
        });
      }
    });
  };

  const handleSetVideoSessionEvidenceAddFormEnabled = () => {
    setVideoSessionEvidenceAddFormEnabled(!videoSessionEvidenceAddFormEnabled);
  };

  return (
    <Grid item xs={12}>
      {(keycloak?.hasRealmRole(Roles.auditor) ||
        keycloak?.hasRealmRole(Roles.administrator)) && (
        <>
          {!videoSessionEvidenceAddFormEnabled && (
            <AddVideoSessionEvidenceButton
              setVideoSessionEvidenceAddFormEnabled={
                handleSetVideoSessionEvidenceAddFormEnabled
              }
            />
          )}

          <Paper>
            {videoSessionEvidenceAddFormEnabled && tableData && (
              <>
                <Grid item xs={12}>
                  <Tooltip title={t<string>("cars:tooltipDiscard")}>
                    <IconButton
                      onClick={handleSetVideoSessionEvidenceAddFormEnabled}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <AuditVideoSessionEvidenceAdd
                  handleAddVideoSessionEvidence={handleVideoSessionEvidenceAdd}
                  tableData={tableData}
                />
              </>
            )}
            <MaterialTable
              options={{
                draggable: false,
                padding: "default",
                pageSizeOptions: [10, 20, 30],
                pageSize: 10,
                toolbar: true,
                emptyRowsWhenPaging: false,
                detailPanelColumnAlignment: "right",
                detailPanelType: "single",
                rowStyle: rowData => ({
                  backgroundColor:
                    selectedVideoSessionRow &&
                    selectedVideoSessionRow.id === rowData.id
                      ? Colors.ITEM_SELECTED
                      : "",
                }),
              }}
              columns={[
                {
                  title: t("DateAndTimeOfSession"),
                  field: "createdAt",
                  type: "date",
                  render: rowData => (
                    <>
                      {rowData.createdAt ? (
                        <>
                          {moment(rowData.createdAt).format(
                            DateConstants.DATE_TIME_FORMAT,
                          )}
                        </>
                      ) : (
                        <>{t("cars:notAvailable")}</>
                      )}
                    </>
                  ),
                  headerStyle: {
                    fontWeight: "bold",
                  },
                },
                {
                  title: t("sessionCreator"),
                  field: "sessionCreatorName",

                  headerStyle: {
                    fontWeight: "bold",
                  },
                },
              ]}
              onRowClick={(event, rowData, togglePanel) => {
                setSelectedVideoSessionRow(rowData);

                if (togglePanel) {
                  togglePanel();
                }
              }}
              data={tableData}
              icons={TableIcons}
              title={
                auditData?.fpsoArea.currentAudit.auditReference +
                t("videoSessions")
              }
              detailPanel={rowData => {
                return (
                  <AuditVideoSessionEvidenceList
                    videoSessionRow={rowData}
                    auditData={auditData?.fpsoArea.currentAudit}
                    setVideoSessionEvidenceAddFormDisabled={
                      handleSetVideoSessionEvidenceAddFormEnabled
                    }
                  />
                );
              }}
            />
          </Paper>
        </>
      )}
    </Grid>
  );
};
