import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import {
  DELETE_FPSO_AREA_DOCUMENT,
  DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID,
  GET_FPSO_AREA_BY_ID,
  GetFpsoAreaByIdQueryResult,
  GetFpsoAreaByIdQueryVariables,
  INSERT_FPSO_AREA_DOCUMENT,
  INSERT_SUB_SYSTEM_ITEM,
  UPDATE_FPSO_AREA_DOCUMENT,
} from "Graphql/FpsoQueries";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { FpsoAreaDocument } from "Models/FpsoAreaDocument";
import { OptionType } from "Models/OptionType";
import { RouteParams } from "Models/RouteParams";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { FpsoAreaDocumentList } from "Views/Components/FpsoAreaDocuments/FpsoAreaDocumentList";
import { UploadCloudWhiteIcon } from "../Icons/UploadCloudWhiteIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    dropdown: {
      display: "flex",
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
    buttonRow: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    buttonText: {
      textTransform: "capitalize",
      color: "white",
    },
  }),
);

export const FpsoAreaDocuments: FC = () => {
  const classes = useStyles();
  const { fpsoAreaId } = useParams<RouteParams>();
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [uploadFormEnabled, setUploadFormEnabled] = useState(false);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();
  const [refetchDocuments, setRefetchDocuments] = useState(false);

  const [deleteSubSystemItem] = useMutation(DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID);
  const [addSubSystemItem] = useMutation(INSERT_SUB_SYSTEM_ITEM);

  const handleAddClicked = () => {
    setUploadFormEnabled(!uploadFormEnabled);
    setSelectedSubSystems([]);
  };

  const handleRefetchDocuments = () => {
    setRefetchDocuments(!refetchDocuments);
  };

  const handleDocumentUpload = (document: FpsoAreaDocument, file: File) => {
    setUploadFormEnabled(false);
    uploadNewDocument(document, file);
  };

  const handleDeleteDocument = (document: FpsoAreaDocument) => {
    deleteDocument(document);
  };

  const handleRowEdit = (
    updatedDocumentRow: FpsoAreaDocument,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    updateFpsoAreaDocument({
      variables: {
        id: updatedDocumentRow.id,
        comment: updatedDocumentRow.comment,
        description: updatedDocumentRow.description,
        documentNumber: updatedDocumentRow.documentNumber,
        name: updatedDocumentRow.name,
        publishDate: updatedDocumentRow.publishDate,
        reference: updatedDocumentRow.reference,
        revision: updatedDocumentRow.revision,
        systemArea: updatedDocumentRow.systemArea,
        type: updatedDocumentRow.type,
      },
    })
      .then(() => {
        if (changeInSubSystems === true) {
          deleteSubSystemItem({
            variables: {
              itemId: updatedDocumentRow.id,
            },
          });
        } else {
          handleRefetchDocuments();
        }
      })
      .then(() => {
        if (changeInSubSystems === true) {
          updatedSubSystems.forEach(item => {
            addSubSystemItem({
              variables: {
                itemId: updatedDocumentRow.id,
                subSystemType: item,
              },
            }).then(() => {
              handleRefetchDocuments();
            });
          });
        }
      });
  };

  const { data: fpsoAreaInfo } = useQuery<
    GetFpsoAreaByIdQueryResult,
    GetFpsoAreaByIdQueryVariables
  >(GET_FPSO_AREA_BY_ID, { variables: { id: fpsoAreaId || "undefined" } });

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaInfo?.fpsoArea.areaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
  };

  const [insertFpsoAreaDocument] = useMutation(INSERT_FPSO_AREA_DOCUMENT);

  const [deleteFpsoAreaDocument] = useMutation(DELETE_FPSO_AREA_DOCUMENT);

  const [updateFpsoAreaDocument] = useMutation(UPDATE_FPSO_AREA_DOCUMENT);

  const uploadNewDocument = (document: FpsoAreaDocument, file: File) => {
    const formData = new FormData();
    formData.set("folderName", `${fpsoAreaId}/`);
    formData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${keycloak?.token}`,
        },
      })
      .then(result => {
        if (keycloak?.tokenParsed?.sub) {
          insertFpsoAreaDocument({
            variables: {
              type: document.type,
              documentNumber: document.documentNumber,
              name: document.name,
              revision: document.revision,
              description: document.description,
              reference: result.data,
              systemArea: document.systemArea,
              fpsoAreaId,
              uploaderId: keycloak.tokenParsed.sub,
              publishDate: document.publishDate,
            },
          }).then(() => {
            handleRefetchDocuments();
          });
        }
      });
  };

  const deleteDocument = (document: FpsoAreaDocument) => {
    if (document.reference) {
      axios
        .delete(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, {
          headers: { Authorization: `bearer ${keycloak?.token}` },
          params: { fileName: document.reference },
        })
        .then(result => {
          if (keycloak?.tokenParsed?.sub) {
            deleteFpsoAreaDocument({
              variables: {
                id: document.id,
              },
            }).then(() => {
              handleRefetchDocuments();
            });
          }
        });
    }
  };

  const Option = CheckboxOption;

  return (
    <>
      {fpsoAreaId ? (
        <>
          <Grid container>
            {!uploadFormEnabled && (
              <Grid container>
                {(keycloak?.hasRealmRole(Roles.auditor) ||
                  keycloak?.hasRealmRole(Roles.administrator)) && (
                  <Grid item xs={2} className={classes.buttonRow}>
                    <Tooltip title={t<string>("files:tooltipAdd")} arrow={true}>
                      <Button
                        onClick={handleAddClicked}
                        className={classes.buttonText}
                      >
                        <UploadCloudWhiteIcon />
                        <Typography variant="body1">
                          {t("addFpsoAreaDocument")}
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                )}

                <Grid item xs={10} className={classes.dropdown}>
                  <Select
                    onChange={option => handleChange(option)}
                    options={subSystemOptions}
                    isMulti
                    autoFocus
                    isSearchable
                    isClearable
                    styles={CustomStylesSubSystemOptions}
                    placeholder={t("helperTextAreaDocumentsFilterSubSystems")}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.root}>
              <FpsoAreaDocumentList
                handleDocumentUpload={handleDocumentUpload}
                handleDeleteDocument={handleDeleteDocument}
                handleRowEdited={handleRowEdit}
                selectedSubSystems={selectedSubSystems}
                handleAddClicked={handleAddClicked}
                uploadFormEnabled={uploadFormEnabled}
                fpsoAreaId={fpsoAreaId}
                refetchDocuments={refetchDocuments}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {t("errors:noDocumentsAvailable")}
        </Grid>
      )}
    </>
  );
};
