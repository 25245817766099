import React, { FC } from "react";

export const StartANewAuditWhiteIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g fill="#FFFFFF">
        <path
          d="M43.61,1.59c-5.14,0-9.38,3.88-9.97,8.86h-0.79l0-0.6c0-0.27-0.11-0.52-0.29-0.71s-0.44-0.29-0.71-0.29h-4.06
		c-0.47-1.96-2.24-3.42-4.35-3.42c-2.11,0-3.89,1.46-4.35,3.42h-4.06c-0.27,0-0.52,0.11-0.71,0.29s-0.29,0.44-0.29,0.71
		c0,0,0,0.22,0,0.6H8c-1.18,0-2.13,0.96-2.13,2.13v43.62c0,1.18,0.96,2.13,2.13,2.13h30.88c1.18,0,2.13-0.96,2.13-2.13V21.33
		c0.83,0.22,1.7,0.35,2.6,0.35c5.54,0,10.05-4.51,10.05-10.05S49.15,1.59,43.61,1.59z M16.03,10.85h3.94c0.55,0,1-0.44,1-0.99
		c0.01-1.31,1.14-2.42,2.47-2.42c1.33,0,2.47,1.11,2.47,2.42c0,0.55,0.45,1,1,1h3.94l0,2.65c0,0.09-0.07,0.16-0.16,0.16h-14.5
		c-0.06,0-0.09-0.03-0.11-0.05c-0.02-0.02-0.05-0.06-0.05-0.11C16.03,12.42,16.03,11.5,16.03,10.85z M39.01,56.21
		c0,0.07-0.06,0.13-0.13,0.13H8c-0.07,0-0.13-0.06-0.13-0.13V12.59c0-0.07,0.06-0.13,0.13-0.13h6.03c0,0.33,0,0.67,0,1.04
		c0,0.58,0.22,1.12,0.63,1.53c0.41,0.41,0.95,0.63,1.53,0.63h14.5c1.19,0,2.16-0.97,2.16-2.16l0-1.04h0.76
		c0.29,3.53,2.4,6.55,5.41,8.11V56.21z M43.61,19.68c-4.44,0-8.05-3.61-8.05-8.05s3.61-8.05,8.05-8.05s8.05,3.61,8.05,8.05
		S48.04,19.68,43.61,19.68z"
        />
        <path
          d="M49.02,10.64h-4.41V6.23c0-0.55-0.45-1-1-1s-1,0.45-1,1v4.41H38.2c-0.55,0-1,0.45-1,1s0.45,1,1,1h4.41v4.41
		c0,0.55,0.45,1,1,1s1-0.45,1-1v-4.41h4.41c0.55,0,1-0.45,1-1S49.57,10.64,49.02,10.64z"
        />
      </g>
    </svg>
  );
};
