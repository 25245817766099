import React, { FC } from "react";
import { PlotComponent } from "Views/Components/PlotComponent/PlotComponent";

interface Props {
  readonly data: Plotly.Data[];
  readonly titleText: string;
}

export const BasePieChart: FC<Props> = ({ data, titleText }) => {
  const titleFont = {
    family: '"Red Hat Display", sans-serif',
    size: 19,
    color: "black",
  };
  return (
    <PlotComponent
      useResizeHandler={true}
      data={data}
      config={{
        displayModeBar: false,
        scrollZoom: false,
      }}
      layout={{
        yaxis: {
          fixedrange: true,
          automargin: true,
          rangemode: "nonnegative",
        },
        xaxis: {
          fixedrange: true,
          automargin: true,
          type: "category",
          rangemode: "nonnegative",
        },
        showlegend: true,
        legend: {
          orientation: "v",
          x: -0.02,
          y: 0.5,
          borderwidth: 2,
          bordercolor: "black",
        },
        autosize: true,
        annotations: [
          {
            text: titleText,
            showarrow: false,
            x: 0.5,
            y: 1.12,
            font: {
              family: titleFont.family,
              size: titleFont.size,
              color: titleFont.color,
            },
          },
        ],
      }}
    />
  );
};
