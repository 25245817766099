import React, { FC } from "react";

interface Props {
  readonly fillColor: string;
  readonly strokeColor: string;
}

export const CircleIcon: FC<Props> = ({ fillColor, strokeColor }) => {
  return (
    <svg
      className="circle"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <circle
        cx="15"
        cy="15"
        r="12"
        stroke={strokeColor}
        strokeWidth="2"
        fill={fillColor}
      />
    </svg>
  );
};
