export default class ValidationConstants {
  public static readonly BYTES_TO_KILOBYTES_FACTOR = 0.0009765625;
  public static readonly BYTES_TO_MEGABYTES_FACTOR = 0.00000095367432;
  public static readonly MEGABYTE_IN_BYTES = 1000000;
  public static readonly MAX_FILE_SIZE = 150000000;
  public static readonly ACCEPTED_IMAGE_TYPES = [".jpg", ".jpeg", ".bmp"];
  public static readonly ACCEPTED_DOCUMENT_TYPES = [
    ".pdf",
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    ".txt",
    ".png",
    ".jpg",
  ];
}
