import { gql } from "apollo-boost";
import { ArchivedAudit } from "Models/ArchivedAudit";
import { FPSOArea } from "Models/FPSOArea";
import { VideoSessionEvidence } from "Models/VideoSessionEvidence";

export const GET_ARCHIVED_AUDIT_BY_ID = gql`
  query getArchivedAuditById($id: uuid!) {
    archivedAudit: audit_snapshots_by_pk(id: $id) {
      id
      createdAt
      audit {
        id
      }
      auditReference
      auditScore
      startDate
      endDate
      leadAuditor {
        id
        fullName
      }
      isLocked
      lockingUser {
        id
        fullName
      }
      fpsoAreaId
      fpsoArea: fpso_area {
        id
        areaName
        fpso {
          id
          name
        }
      }
      englishTranslation: audit_translations_snapshots(
        where: { languageCode: { _eq: en } }
      ) {
        id
        auditSummary
        mainFindings
        professionalOpinion
        actionsRecommendations
      }
      portugueseTranslation: audit_translations_snapshots(
        where: { languageCode: { _eq: pt } }
      ) {
        id
        auditSummary
        mainFindings
        professionalOpinion
        actionsRecommendations
      }
      itemsWithSubSystems: items_with_sub_systems {
        createdAt
        id
        itemId
        subSystemType
        updatedAt
      }
    }
  }
`;

export interface GetArchivedAuditByIdQueryResult {
  archivedAudit: ArchivedAudit;
}

export interface GetArchivedAuditByIdQueryVariables {
  id: string;
}

export const GET_CURRENT_AUDIT_BY_FPSO_AREA = gql`
  query getCurrentAuditByFpsoArea($fpsoAreaId: uuid!) {
    fpsoArea: fpso_areas_by_pk(id: $fpsoAreaId) {
      id
      areaName
      fpso {
        id
        name
      }
      currentAudit: current_audit {
        id
        auditReference
        auditScore
        startDate
        endDate
        isActive
        fpsoAreaId
        leadAuditor {
          id
          fullName
        }
        isLocked
        lockingUser: locking_user {
          id
          fullName
        }
        videoSessions: video_sessions(order_by: { createdAt: desc }) {
          id
          sessionOngoing
          createdAt
          updatedAt
          session_creator {
            id
            fullName
          }
        }
        activeVideoSessions: video_sessions(
          where: { sessionOngoing: { _eq: true } }
        ) {
          id
          sessionOngoing
          createdAt
          updatedAt
          session_creator {
            id
            fullName
          }
        }
        englishTranslation: audit_translations(
          where: { languageCode: { _eq: en } }
        ) {
          id
          auditSummary
          mainFindings
          professionalOpinion
          actionsRecommendations
        }
        portugueseTranslation: audit_translations(
          where: { languageCode: { _eq: pt } }
        ) {
          id
          auditSummary
          mainFindings
          professionalOpinion
          actionsRecommendations
        }
        itemsWithSubSystems: items_with_sub_systems {
          createdAt
          id
          itemId
          subSystemType
          updatedAt
        }
      }
    }
  }
`;

export interface GetCurrentAuditByFpsoAreaQueryResult {
  fpsoArea: FPSOArea;
}

export interface GetCurrentAuditByFpsoAreaQueryVariables {
  fpsoAreaId: string;
}

export const INSERT_AUDIT = gql`
  mutation insertAudit(
    $id: uuid!
    $auditReference: String
    $auditSummaryEnglish: String
    $auditSummaryPortuguese: String
    $mainFindingsEnglish: String
    $mainFindingsPortuguese: String
    $professionalOpinionEnglish: String
    $professionalOpinionPortuguese: String
    $actionsRecommendationsEnglish: String
    $actionsRecommendationsPortuguese: String
    $fpsoAreaId: uuid!
    $leadAuditorId: uuid!
    $createdAt: timestamptz
  ) {
    result: insert_audits(
      objects: {
        id: $id
        auditReference: $auditReference
        fpsoAreaId: $fpsoAreaId
        leadAuditorId: $leadAuditorId
        createdAt: $createdAt
        isActive: true
      }
    ) {
      newAudit: returning {
        id
        fpso_area {
          id
          fpso {
            id
          }
        }
      }
    }
    insertEnglishAuditTranslation: insert_audit_translations(
      objects: {
        languageCode: en
        auditSummary: $auditSummaryEnglish
        mainFindings: $mainFindingsEnglish
        professionalOpinion: $professionalOpinionEnglish
        actionsRecommendations: $actionsRecommendationsEnglish
        auditId: $id
      }
    ) {
      returning {
        id
      }
    }
    insertPortugueseAuditTranslation: insert_audit_translations(
      objects: {
        languageCode: pt
        auditSummary: $auditSummaryPortuguese
        mainFindings: $mainFindingsPortuguese
        professionalOpinion: $professionalOpinionPortuguese
        actionsRecommendations: $actionsRecommendationsPortuguese
        auditId: $id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export interface InsertAuditQueryResult {
  result: {
    newAudit: [
      {
        id: string;
        fpso_area: {
          id: string;
          currentAuditId: string;
        };
      },
    ];
  };
}

export const UPDATE_CURRENT_AUDIT_FOR_FPSO_AREA = gql`
  mutation updateCurrentAuditForFpsoArea(
    $fpsoAreaId: uuid!
    $newCurrentAuditId: uuid!
    $oldCurrentAuditId: uuid
  ) {
    updateFpso: update_fpso_areas(
      where: { id: { _eq: $fpsoAreaId } }
      _set: { currentAuditId: $newCurrentAuditId }
    ) {
      affected_rows
    }
    updateOldAudit: update_audits(
      where: {
        id: { _eq: $oldCurrentAuditId }
        fpsoAreaId: { _eq: $fpsoAreaId }
      }
      _set: { isActive: false }
    ) {
      affected_rows
    }
    updateNewAudit: update_audits(
      where: {
        id: { _eq: $newCurrentAuditId }
        fpsoAreaId: { _eq: $fpsoAreaId }
      }
      _set: { isActive: true }
    ) {
      affected_rows
    }
    moveCars: update_cars(
      where: {
        fpsoAreaId: { _eq: $fpsoAreaId }
        _and: { status: { _eq: OPEN }, isDeleted: { _eq: false } }
      }
      _set: { auditId: $newCurrentAuditId }
    ) {
      affected_rows
    }
  }
`;

export const GET_ARCHIVED_AUDITS_BY_FPSO_AREA = gql`
  query getArchivedAuditsByFpsoArea(
    $fpsoAreaId: uuid!
    $whereVariables: audit_snapshots_bool_exp
  ) {
    archivedAudits: audit_snapshots(
      where: $whereVariables
      order_by: { createdAt: desc }
    ) {
      id
      createdAt
      endDate
      startDate
      leadAuditor {
        id
        fullName
      }
      auditReference
      auditScore
      fpsoAreaId
      audit {
        id
      }
      englishAuditSnapshot: audit_translations_snapshots(
        where: { languageCode: { _eq: en } }
      ) {
        id
        auditSummary
      }
      portugueseAuditSnapshot: audit_translations_snapshots(
        where: { languageCode: { _eq: pt } }
      ) {
        id
        auditSummary
      }
      itemsWithSubSystems: items_with_sub_systems {
        createdAt
        id
        itemId
        subSystemType
        updatedAt
      }
    }
    fpsoArea: fpso_areas_by_pk(id: $fpsoAreaId) {
      id
      areaName
      fpso {
        id
        name
      }
    }
  }
`;

export interface GetArchivedAuditsByFpsoAreaQueryResult {
  archivedAudits: ArchivedAudit[];
  fpsoArea: FPSOArea;
}

export interface GetArchivedAuditsByFpsoAreaQueryVariables {
  fpsoAreaId: string;
  whereVariables: object;
}

export const UPDATE_AUDIT = gql`
  mutation updateAudit(
    $id: uuid!
    $startDate: date
    $endDate: date
    $englishTranslationId: uuid
    $auditSummaryEnglish: String
    $portugueseTranslationId: uuid
    $auditSummaryPortuguese: String
    $mainFindingsEnglish: String
    $mainFindingsPortuguese: String
    $professionalOpinionEnglish: String
    $professionalOpinionPortuguese: String
    $actionsRecommendationsEnglish: String
    $actionsRecommendationsPortuguese: String
  ) {
    update_audits(
      where: { id: { _eq: $id } }
      _set: { startDate: $startDate, endDate: $endDate }
    ) {
      returning {
        id
      }
    }
    upsertEnglishTranslation: insert_audit_translations(
      objects: {
        id: $englishTranslationId
        auditSummary: $auditSummaryEnglish
        mainFindings: $mainFindingsEnglish
        professionalOpinion: $professionalOpinionEnglish
        actionsRecommendations: $actionsRecommendationsEnglish
        languageCode: en
        auditId: $id
      }
      on_conflict: {
        constraint: audit_translations_pkey
        update_columns: [
          auditSummary
          mainFindings
          professionalOpinion
          actionsRecommendations
          auditId
        ]
      }
    ) {
      returning {
        id
      }
    }
    upsertPortugueseTranslation: insert_audit_translations(
      objects: {
        id: $portugueseTranslationId
        auditSummary: $auditSummaryPortuguese
        mainFindings: $mainFindingsPortuguese
        professionalOpinion: $professionalOpinionPortuguese
        actionsRecommendations: $actionsRecommendationsPortuguese
        languageCode: pt
        auditId: $id
      }
      on_conflict: {
        constraint: audit_translations_pkey
        update_columns: [
          auditSummary
          mainFindings
          professionalOpinion
          actionsRecommendations
          auditId
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_AUDIT_LOCK_STATUS = gql`
  mutation updateAuditLockStatus(
    $id: uuid!
    $isLocked: Boolean
    $lockingUserId: uuid!
  ) {
    update_audits(
      where: { id: { _eq: $id } }
      _set: { isLocked: $isLocked, lockingUserId: $lockingUserId }
    ) {
      returning {
        id
      }
    }
  }
`;

export const TAKE_AUDIT_SNAPSHOT = gql`
  mutation insertAuditSnapshot(
    $id: uuid!
    $auditId: uuid!
    $auditReference: String
    $auditScore: Int
    $startDate: date
    $endDate: date
    $fpsoAreaId: uuid!
    $leadAuditorId: uuid!
    $englishAuditTranslationSnapshotId: uuid!
    $portugueseAuditTranslationSnapshotId: uuid!
    $auditSummaryEnglish: String
    $auditSummaryPortuguese: String
    $mainFindingsEnglish: String
    $mainFindingsPortuguese: String
    $professionalOpinionEnglish: String
    $professionalOpinionPortuguese: String
    $actionsRecommendationsEnglish: String
    $actionsRecommendationsPortuguese: String
    $createdAt: timestamptz
  ) {
    result: insert_audit_snapshots(
      objects: {
        id: $id
        auditId: $auditId
        auditReference: $auditReference
        auditScore: $auditScore
        startDate: $startDate
        endDate: $endDate
        fpsoAreaId: $fpsoAreaId
        leadAuditorId: $leadAuditorId
        createdAt: $createdAt
      }
    ) {
      archivedAudit: returning {
        id
        fpso_area {
          id
          fpso {
            id
          }
        }
      }
    }
    insertEnglishAuditTranslationSnapshot: insert_audit_translations_snapshots(
      objects: {
        id: $englishAuditTranslationSnapshotId
        auditSnapshotId: $id
        languageCode: en
        auditSummary: $auditSummaryEnglish
        mainFindings: $mainFindingsEnglish
        professionalOpinion: $professionalOpinionEnglish
        actionsRecommendations: $actionsRecommendationsEnglish
      }
    ) {
      returning {
        id
      }
    }
    insertPortugueseAuditTranslationSnapshot: insert_audit_translations_snapshots(
      objects: {
        id: $portugueseAuditTranslationSnapshotId
        auditSnapshotId: $id
        languageCode: pt
        auditSummary: $auditSummaryPortuguese
        mainFindings: $mainFindingsPortuguese
        professionalOpinion: $professionalOpinionPortuguese
        actionsRecommendations: $actionsRecommendationsPortuguese
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_ALL_AUDIT_CURRENT_SCORES = gql`
  query getAllAuditCurrentScores {
    currentAuditScores: audits(where: { isActive: { _eq: true } }) {
      auditScore
      auditReference
      fpso_area {
        id
        areaName
        fpso {
          id
          name
          block {
            owner {
              companyName
            }
          }
        }
      }
    }
  }
`;

export interface GetAllAuditCurrentScoresResult {
  currentAuditScores: Array<{
    auditReference: string;
    auditScore: number;
    fpso_area: {
      id: string;
      areaName: string;
      fpso: {
        id: string;
        name: string;
        block: {
          owner: {
            companyName: string;
          };
        };
      };
    };
  }>;
}

export const INSERT_AUDIT_VIDEO_SESSION = gql`
  mutation insertVideoSession(
    $id: uuid!
    $sessionCreatorId: uuid!
    $fpsoAreaId: uuid!
    $auditId: uuid! #add the need for an audit ID for video sessions
  ) {
    insert_video_sessions(
      objects: {
        id: $id
        sessionCreatorId: $sessionCreatorId
        fpsoAreaId: $fpsoAreaId
        auditId: $auditId
        sessionOngoing: true
      }
    ) {
      affected_rows
      returning {
        id
        createdAt
        updatedAt
        session_creator {
          id
          fullName
        }
        fpso_area {
          id
          areaName
          fpso {
            id
            name
          }
        }
      }
    }
  }
`;

export interface InsertAuditVideoSessionMutationResult {
  result: {
    newVideoSession: [
      {
        id: string;
        auditId: string;
        session_creator: {
          id: string;
          fullName: string;
        };
        fpso_area: {
          id: string;
          areaName: string;
          fpso: {
            id: string;
            name: string;
          };
        };
        createdAt: Date;
        updatedAt: Date;
      },
    ];
  };
}

export const END_AUDIT_VIDEO_SESSION = gql`
  mutation endVideoSession($id: uuid!) {
    update_video_sessions(
      where: { id: { _eq: $id } }
      _set: { sessionOngoing: false }
    ) {
      affected_rows
      returning {
        fpso_area {
          id
          areaName
          fpso {
            id
            name
          }
        }
        id
        sessionOngoing
        session_creator {
          id
          fullName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export interface EndAuditVideoSessionMutationResult {
  result: {
    oldVideoSession: [
      {
        id: string;
        auditId: string;
        session_creator: {
          id: string;
          fullName: string;
        };
        fpso_area: {
          id: string;
          areaName: string;
          fpso: {
            id: string;
            name: string;
          };
        };
        createdAt: Date;
        updatedAt: Date;
      },
    ];
  };
}

export const GET_VIDEO_SESSION_EVIDENCE = gql`
  query getVideoSessionEvidenceBySessionId($videoSessionId: uuid!) {
    videoSessionEvidence: video_sessions_evidence(
      where: { videoSessionId: { _eq: $videoSessionId } }
      order_by: { createdAt: desc }
    ) {
      createdAt
      description
      evidenceReference
      id
      updatedAt
      submitter {
        fullName
        id
      }
      itemsWithSubSystems: items_with_sub_systems {
        createdAt
        id
        itemId
        subSystemType
        updatedAt
      }
    }
  }
`;

export interface GetVideoSessionEvidenceBySessionIdQueryResult {
  videoSessionEvidence: VideoSessionEvidence[];
}

export interface GetVideoSessionEvidenceBySessionIdQueryVariables {
  videoSessionId: string;
}

export const INSERT_VIDEO_SESSION_EVIDENCE = gql`
  mutation insertVideoSessionEvidence(
    $evidenceReference: String
    $evidenceDescription: String
    $auditId: uuid!
    $submitterId: uuid!
    $videoSessionId: uuid!
    $createdAt: timestamptz
  ) {
    result: insert_video_sessions_evidence(
      objects: {
        evidenceReference: $evidenceReference
        description: $evidenceDescription
        auditId: $auditId
        submitterId: $submitterId
        videoSessionId: $videoSessionId
        createdAt: $createdAt
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_VIDEO_SESSION_EVIDENCE = gql`
  mutation updateVideoSessionEvidence(
    $evidenceDescription: String
    $id: uuid!
  ) {
    update_video_sessions_evidence(
      where: { id: { _eq: $id } }
      _set: { description: $evidenceDescription }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_VIDEO_SESSION_EVIDENCE = gql`
  mutation deleteVideoSessionEvidence($id: uuid!) {
    result: delete_video_sessions_evidence(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
