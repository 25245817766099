export enum SubSystemType {
  powerDistributionSystem = "power_distribution_system",
  earthingSystem = "earthing_system",
  lightningSystem = "lightning_system",
  HVACSystem = "HVAC_system",
  processControlSystem = "process_control_system",
  powerGenerationSystem = "power_generation_system",
  safetySystem = "safety_system",
  FAndGDetectionSystem = "F_and_G_detection_system",
  oilExportSystem = "oil_export_system",
  gasExportSystem = "gas_export_system",
  seaWaterInjectionSystem = "sea_water_injection_system",
  processWaterInjectionSystem = "process_water_injection_system",
  gasInjectionSystem = "gas_injection_system",
  flareSystem = "flare_system",
  closedVentSystem = "closed_vent_system",
  openVentSystem = "open_vent_system",
  chemicalInjectionSystem = "chemical_injection_system",
  heatingSystem = "heating_system",
  coolingSystem = "cooling_system",
  productStorageSystem = "product_storage_system",
  productTransferSystem = "product_transfer_system",
  productSeparationSystem = "product_separation_system",
  gasDehydrationSystem = "gas_dehydration_system",
  sulfurRemovalSystem = "sulfur_removal_system",
  carbonDioxideRemovalSystem = "carbon_dioxide_removal_system",
  gasBTUReductionSystems = "gas_BTU_reduction_systems",
  H2SRemovalSystem = "H2S_removal_system",
  gasCompression = "gas_compression",
  producedWaterTreatment = "produced_water_treatment",
  producedWaterDischargeDisposal = "produced_water_discharge_disposal",
  freshWaterSystem = "fresh_water_system",
  washWaterSystem = "wash_water_system",
  nitrogenSystem = "nitrogen_system",
  instrumentAirSystem = "instrument_air_system",
  fuelGasSystem = "fuel_gas_system",
  dieselFuelSystem = "diesel_fuel_system",
  closedDrainSystem = "closed_drain_system",
  openDrainSystem = "open_drain_system",
  antiCorrosionSystem = "anti_corrosion_system",
  fireFightingSystem = "fire_fighting_system",
  radioAndCommunicationEquipment = "radio_and_communication_equipment",
  rescueEquipment = "rescue_equipment",
  mooringSystem = "mooring_system",
  shipHullSystem = "ship_hull_system",
  transferTankerMooringSystem = "transfer_tanker_mooring_system",
  loadingCraneSystem = "loading_crane_system",
  ballastSystem = "ballast_system",
  bilgePumpSystem = "bilge_pump_system",
  oilWell = "oil_well",
  gasWell = "gas_well",
  waterWell = "water_well",
  oilInjectionWell = "oil_injection_well",
  gasInjectionWell = "gas_injection_well",
  waterInjectionWell = "water_injection_well",
  waterInjection = "water_injection",
  productionWell = "production_well",
  liftGasSystem = "lift_gas_system",
  risers = "risers",
  equipmentA = "equipment_a",
  equipmentB = "equipment_b",
  equipmentC = "equipment_c",
  offshore = "offshore",
  onshore = "onshore",
}
