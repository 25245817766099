import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import LanguageCodes from "Constants/LanguageCodes";
import { Roles } from "Enums/Roles";
import i18next from "i18next";
import { FPSO } from "Models/FPSO";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FpsoBasicDetailsEdit } from "Views/Components/FpsoOverview/FpsoBasicDetailsEdit";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";

interface Props {
  readonly fpso: FPSO;
  handleFpsoEdited?(updatedFpso: FPSO): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    fpsoBasicDetails: {
      marginTop: theme.spacing(4),
    },
    image: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "90%",
      display: "block",
    },
    icon: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      width: 15,
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    readLess: {
      overflow: "hidden",
      height: 390,
      textOverflow: "ellipsis",
      width: "100%",
    },
    buttonText: {
      textTransform: "capitalize",
      fontStyle: "italic",
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
  }),
);

export const FpsoBasicDetails: FC<Props> = ({
  fpso,
  handleFpsoEdited: handleEditedFpsoReceived,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);
  const [siteDescriptionReadMore, setSiteDescriptionReadMore] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleFpsoBasicDetailsEdited = (updatedFpso: FPSO) => {
    setEditEnabled(false);
    if (handleEditedFpsoReceived) {
      handleEditedFpsoReceived(updatedFpso);
    }
  };

  const handleSiteDescriptionReadMore = () => {
    setSiteDescriptionReadMore(!siteDescriptionReadMore);
  };

  return (
    <Paper className={classes.root}>
      {editEnabled ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {t("editing") + " " + fpso.name + " " + t("title")}
          </Typography>
          <Tooltip title={t<string>("cars:tooltipDiscard")}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">
                {t("cars:discardChanges")}
              </Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : keycloak?.hasRealmRole(Roles.auditor) ||
        keycloak?.hasRealmRole(Roles.administrator) ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">{fpso.name + " " + t("title")}</Typography>
          <Tooltip
            title={t<string>("fpsos:tooltipEditBasicDetails")}
            arrow={true}
          >
            <Button onClick={handleEditClicked}>
              <EditAuditInfoIcon />
            </Button>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">{fpso.name + " " + t("title")}</Typography>
        </Grid>
      )}
      {editEnabled ? (
        <FpsoBasicDetailsEdit
          fpso={fpso}
          handleFpsoBasicDetailsEdited={handleFpsoBasicDetailsEdited}
        />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("block")}
              </Typography>
              <Typography variant="body2" align="center">
                {fpso.block.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("operator")}
              </Typography>
              <Typography variant="body2" align="center">
                {fpso.block.owner.companyName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("coordinates")}
              </Typography>
              <Typography variant="body2" align="center">
                {fpso.latitude + " " + fpso.longitude}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("start")}
              </Typography>
              {fpso.startOfOperations ? (
                <Typography variant="body2" align="center">
                  {moment(fpso.startOfOperations).format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {t("notAvailable")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("anpg_contact")}
              </Typography>

              {fpso.block.anpgMainContactName ? (
                <Typography variant="body2" align="center">
                  {fpso.block.anpgMainContactName}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {t("notAvailable")}
                </Typography>
              )}
              {fpso.block.anpgMainContactEmail == null ? (
                <Typography variant="body2" align="center">
                  {t("emailNotAvailable")}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {fpso.block.anpgMainContactEmail}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("fpso_contact")}
              </Typography>

              {fpso.fpsoMainContactName == null ? (
                "notAvailable"
              ) : (
                <Typography variant="body2" align="center">
                  {fpso.fpsoMainContactName}
                </Typography>
              )}
              {fpso.fpsoMainContactEmail == null ? (
                <Typography variant="body2" align="center">
                  {t("emailNotAvailable")}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {fpso.fpsoMainContactEmail}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("site_description")}
              </Typography>
              <Typography variant="body2" className={classes.multiLine}>
                {!siteDescriptionReadMore &&
                (fpso.englishTranslation[0]?.fpsoDescription ||
                  fpso.portugueseTranslation[0]?.fpsoDescription) &&
                (fpso.englishTranslation[0]?.fpsoDescription.length ||
                  fpso.portugueseTranslation[0]?.fpsoDescription.length) >
                  ElementSizes.FPSO_DESCRIPTION_READ_MORE_LENGTH ? (
                  <>
                    <Box className={classes.readLess}>
                      {i18next.language === LanguageCodes.ENGLISH
                        ? fpso.englishTranslation[0]?.fpsoDescription ||
                          t("notAvailable")
                        : fpso.portugueseTranslation[0]?.fpsoDescription ||
                          t("notAvailable")}
                    </Box>
                    <Button
                      onClick={handleSiteDescriptionReadMore}
                      className={classes.buttonText}
                    >
                      <Typography variant="body1">
                        {t("audits:readMore")}
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Box>
                      {i18next.language === LanguageCodes.ENGLISH
                        ? fpso.englishTranslation[0]?.fpsoDescription ||
                          t("notAvailable")
                        : fpso.portugueseTranslation[0]?.fpsoDescription ||
                          t("notAvailable")}
                    </Box>
                    {siteDescriptionReadMore && (
                      <Button
                        onClick={handleSiteDescriptionReadMore}
                        className={classes.buttonText}
                      >
                        <Typography variant="body1">
                          {t("audits:readLess")}
                        </Typography>
                      </Button>
                    )}
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};
