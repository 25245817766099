import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import ElementSizes from "Constants/ElementSizes";
import { FPSOArea } from "Models/FPSOArea";
import { FC, SyntheticEvent, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FpsoAreaPicturesUpload } from "Views/Components/FpsoOverview/FpsoArea/FpsoAreaPicturesUpload";

interface Props {
  readonly fpsoArea: FPSOArea;
  handleFpsoAreaBasicDetailsEdited(updatedFpsoArea: FPSOArea): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2),
    },
    formTopRow: {
      justifyContent: "space-evenly",
    },
    formBottomRow: {
      justifyContent: "space-evenly",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    coordinateTextFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.COORDINATE_TEXT_INPUT_WIDTH,
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
  }),
);

export const FpsoAreaBasicDetailsEdit: FC<Props> = ({
  fpsoArea: originalFpsoArea,
  handleFpsoAreaBasicDetailsEdited,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");

  const [unchangedFpsoArea] = useState<FPSOArea>({
    id: originalFpsoArea.id,
    areaName: originalFpsoArea.areaName,
    currentAuditId: originalFpsoArea.currentAuditId,
    englishTranslation: [
      {
        id: originalFpsoArea.englishTranslation[0].id,
        fpsoAreaDescription:
          originalFpsoArea.englishTranslation[0].fpsoAreaDescription,
      },
    ],
    portugueseTranslation: [
      {
        id: originalFpsoArea.portugueseTranslation[0].id,
        fpsoAreaDescription:
          originalFpsoArea.portugueseTranslation[0].fpsoAreaDescription,
      },
    ],

    fpso: {
      id: originalFpsoArea.fpso.id,
      name: originalFpsoArea.fpso.name,
      latitude: originalFpsoArea.fpso.latitude,
      longitude: originalFpsoArea.fpso.longitude,
      startOfOperations: originalFpsoArea.fpso.startOfOperations,
      fpsoMainContactName: originalFpsoArea.fpso.fpsoMainContactName,
      fpsoMainContactEmail: originalFpsoArea.fpso.fpsoMainContactEmail,
      fpsoAreas: originalFpsoArea.fpso.fpsoAreas,
      englishTranslation: [
        {
          id: originalFpsoArea.fpso.englishTranslation[0].id,
          fpsoDescription:
            originalFpsoArea.fpso.englishTranslation[0].fpsoDescription,
        },
      ],
      portugueseTranslation: [
        {
          id: originalFpsoArea.fpso.portugueseTranslation[0].id,
          fpsoDescription:
            originalFpsoArea.fpso.portugueseTranslation[0].fpsoDescription,
        },
      ],
      block: {
        id: originalFpsoArea.fpso.block.id,
        name: originalFpsoArea.fpso.block.name,
        fpsos: originalFpsoArea.fpso.block.fpsos,
        owner: originalFpsoArea.fpso.block.owner,
        anpgMainContactName: originalFpsoArea.fpso.block.anpgMainContactName,
        anpgMainContactEmail: originalFpsoArea.fpso.block.anpgMainContactEmail,
      },
    },

    mainContactName: originalFpsoArea.mainContactName,
    mainContactEmail: originalFpsoArea.mainContactEmail,
    fpsoAreaPictures: originalFpsoArea.fpsoAreaPictures,
    fpsoAreaSystemComponents: originalFpsoArea.fpsoAreaSystemComponents,
    fpsoAreaDocuments: originalFpsoArea.fpsoAreaDocuments,
    auditScoresOverTime: originalFpsoArea.auditScoresOverTime,
    currentAuditCarClassesCount: originalFpsoArea.currentAuditCarClassesCount,
    currentAudit: originalFpsoArea.currentAudit,
  });

  const [updatedFpsoArea] = useState<FPSOArea>(unchangedFpsoArea);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    handleFpsoAreaBasicDetailsEdited(updatedFpsoArea);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12} className={classes.root}>
        <FormGroup row className={classes.formBottomRow}>
          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("fpso_area_contact")}
              defaultValue={updatedFpsoArea.mainContactName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpsoArea.mainContactName = event.target.value;
              }}
            />
          </FormControl>

          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("fpso_area_contact_email")}
              defaultValue={updatedFpsoArea.mainContactEmail}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedFpsoArea.mainContactEmail = event.target.value;
              }}
            />
          </FormControl>
        </FormGroup>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            multiline
            fullWidth
            rows="4"
            rowsMax="8"
            variant="outlined"
            label={t("site_description") + t("cars:englishLanguageSuffix")}
            defaultValue={
              updatedFpsoArea.englishTranslation[0]?.fpsoAreaDescription
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updatedFpsoArea.englishTranslation[0].fpsoAreaDescription =
                event.target.value;
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            multiline
            fullWidth
            rows="4"
            rowsMax="8"
            variant="outlined"
            label={t("site_description") + t("cars:portugueseLanguageSuffix")}
            defaultValue={
              updatedFpsoArea.portugueseTranslation[0]?.fpsoAreaDescription
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updatedFpsoArea.portugueseTranslation[0].fpsoAreaDescription =
                event.target.value;
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.bottomButtonRow}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("cars:save")}
          </Button>
        </Grid>
        <FpsoAreaPicturesUpload fpsoArea={originalFpsoArea} />
      </Grid>
    </form>
  );
};
