import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useKeycloak } from "@react-keycloak/web";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import TimeConstants from "Constants/TimeConstants";
import { Roles } from "Enums/Roles";
import {
  DELETE_USER_FEEDBACK,
  DELETE_USER_FEEDBACK_REPLY,
  GET_FEEDBACK,
  GetFeedbackQueryResult,
  INSERT_USER_FEEDBACK,
  INSERT_USER_FEEDBACK_REPLY,
} from "Graphql/FeedbackQueries";
import { Feedback } from "Models/Feedback";
import { FeedbackReply } from "Models/FeedbackReply";
import { NewFeedback } from "Models/NewFeedback";
import { NewFeedbackReply } from "Models/NewFeedbackReply";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { UserFeedbackDelete } from "Views/Components/UserFeedback/UserFeedbackDelete";
import { UserFeedbackForm } from "Views/Components/UserFeedback/UserFeedbackForm";
import { UserFeedbackReplyForm } from "Views/Components/UserFeedback/UserFeedbackReplyForm";
import { UserFeedbackReplyDelete } from "./UserFeedbackReplyDelete";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    replies: {
      paddingLeft: theme.spacing(12),
    },
    listItemSection: {
      paddingTop: theme.spacing(1),
      "&:hover": {
        backgroundColor: Colors.ITEM_SELECTED,
      },
    },
    repliesSubheading: {
      paddingLeft: theme.spacing(8),
    },
  }),
);

export const UserFeedback: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("feedback");
  const { keycloak } = useKeycloak();
  const uuidv4 = require("uuid/v4");

  const [feedbackSuccessOpen, setFeedbackSuccessOpen] = useState(false);
  const [feedbackFailureOpen, setFeedbackFailureOpen] = useState(false);

  const handleFeedbackSuccessClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackSuccessOpen(false);
  };

  const handleFeedbackFailureClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackFailureOpen(false);
  };

  const {
    loading: feedbackLoading,
    error: feedbackError,
    data: feedbackData,
    refetch,
  } = useQuery<GetFeedbackQueryResult>(GET_FEEDBACK);

  const [insertUserFeedback] = useMutation(INSERT_USER_FEEDBACK);

  const [deleteUserFeedback] = useMutation(DELETE_USER_FEEDBACK);

  const [deleteUserFeedbackReply] = useMutation(DELETE_USER_FEEDBACK_REPLY);

  const [insertUserFeedbackReply] = useMutation(INSERT_USER_FEEDBACK_REPLY);

  const handleAddFeedbackReply = (newFeedbackReply: NewFeedbackReply) => {
    if (keycloak?.tokenParsed?.sub) {
      insertUserFeedbackReply({
        variables: {
          id: uuidv4(),
          reply: newFeedbackReply.reply,
          replierId: keycloak?.tokenParsed?.sub,
          userFeedbackId: newFeedbackReply.userFeedbackId,
        },
      }).then(result => {
        refetch();

        if (result.errors) {
          setFeedbackFailureOpen(true);
        } else {
          setFeedbackSuccessOpen(true);
        }
      });
    }
  };

  const handleAddFeedback = (newFeedback: NewFeedback) => {
    if (keycloak?.tokenParsed?.sub) {
      insertUserFeedback({
        variables: {
          id: uuidv4(),
          feedback: newFeedback.feedback,
          subject: newFeedback.subject,
          commenterId: keycloak?.tokenParsed?.sub,
        },
      }).then(result => {
        refetch();

        if (result.errors) {
          setFeedbackFailureOpen(true);
        } else {
          setFeedbackSuccessOpen(true);
        }
      });
    }
  };

  const handleDeleteFeedback = (deletedFeedback: Feedback) => {
    deleteUserFeedback({
      variables: {
        id: deletedFeedback.id,
      },
    }).then(() => {
      refetch();
    });
  };

  const handleDeleteFeedbackReply = (deletedFeedbackReply: FeedbackReply) => {
    deleteUserFeedbackReply({
      variables: {
        id: deletedFeedbackReply.id,
      },
    }).then(() => {
      refetch();
    });
  };

  const handleSubmitError = () => {
    setFeedbackFailureOpen(true);
  };

  if (feedbackError) {
    return <div>{feedbackError}</div>;
  }

  return (
    <>
      {(keycloak?.hasRealmRole(Roles.auditor) ||
        keycloak?.hasRealmRole(Roles.administrator) ||
        keycloak?.hasRealmRole(Roles.agency) ||
        keycloak?.hasRealmRole(Roles.agency_v2) ||
        keycloak?.hasRealmRole(Roles.operator)) && (
        <>
          {feedbackLoading ? (
            <CircularProgress />
          ) : (
            <>
              {feedbackData && feedbackData.userFeedback ? (
                <Paper className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{t("userFeedback")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" align="center">
                        {t("feedbackDescription")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <UserFeedbackForm
                        handleAddFeedback={handleAddFeedback}
                        handleSubmitError={handleSubmitError}
                      />
                    </Grid>
                  </Grid>
                  {feedbackData.userFeedback.map(feedback => (
                    <List className={classes.listItemSection} key={feedback.id}>
                      <ListItem>
                        <ListItemText
                          primary={feedback.subject}
                          secondary={
                            <Fragment>
                              <Typography variant="subtitle2">
                                {t("postedBy")}
                                {feedback.commenter.fullName}
                                {t("at")}
                                {feedback.createdAt
                                  ? moment(feedback.createdAt).format(
                                      DateConstants.TIME_DATE_FORMAT,
                                    )
                                  : t("notAvailable")}
                              </Typography>
                              {feedback.feedback}
                            </Fragment>
                          }
                        />
                        {(feedback.commenter.id ===
                          keycloak?.idTokenParsed?.sub ||
                          keycloak?.hasRealmRole(Roles.administrator)) &&
                          feedback.userFeedbackReplies.length === 0 && (
                            <UserFeedbackDelete
                              handleFeedbackDeleted={handleDeleteFeedback}
                              feedback={feedback}
                            />
                          )}
                      </ListItem>
                      {keycloak?.hasRealmRole(Roles.administrator) && (
                        <UserFeedbackReplyForm
                          handleAddFeedbackReply={handleAddFeedbackReply}
                          feedback={feedback}
                          handleSubmitError={handleSubmitError}
                        />
                      )}
                      {feedback.userFeedbackReplies.length > 0 && (
                        <>
                          <Typography
                            variant="subtitle2"
                            className={classes.repliesSubheading}
                          >
                            {t("replies")}
                          </Typography>
                          {feedback.userFeedbackReplies.map(replies => (
                            <ListItem
                              className={classes.replies}
                              key={replies.id}
                            >
                              <ListItemText
                                secondary={
                                  <Fragment>
                                    <Typography variant="subtitle2">
                                      {t("postedBy")}
                                      {replies.replier.fullName}
                                      {t("at")}
                                      {replies.createdAt
                                        ? moment(replies.createdAt).format(
                                            DateConstants.TIME_DATE_FORMAT,
                                          )
                                        : t("notAvailable")}
                                    </Typography>
                                    {replies.reply}
                                  </Fragment>
                                }
                              />
                              {(replies.replier.id ===
                                keycloak?.idTokenParsed?.sub ||
                                keycloak?.hasRealmRole(
                                  Roles.administrator,
                                )) && (
                                <UserFeedbackReplyDelete
                                  handleFeedbackReplyDeleted={
                                    handleDeleteFeedbackReply
                                  }
                                  feedbackReply={replies}
                                />
                              )}
                            </ListItem>
                          ))}
                        </>
                      )}
                    </List>
                  ))}

                  <Snackbar
                    open={feedbackSuccessOpen}
                    autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
                    onClose={handleFeedbackSuccessClose}
                  >
                    <Alert
                      onClose={handleFeedbackSuccessClose}
                      severity="success"
                    >
                      {t("feedbackSubmitted")}
                    </Alert>
                  </Snackbar>

                  <Snackbar
                    open={feedbackFailureOpen}
                    autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
                    onClose={handleFeedbackFailureClose}
                  >
                    <Alert
                      onClose={handleFeedbackFailureClose}
                      severity="error"
                    >
                      {t("feedbackSubmitError")}
                    </Alert>
                  </Snackbar>
                </Paper>
              ) : (
                <div>{t("noFeedbackFound")}</div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
