import { useMutation } from "@apollo/react-hooks";
import { Button, Grid, Snackbar, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { Alert } from "@material-ui/lab";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import DateConstants from "Constants/DateConstants";
import TimeConstants from "Constants/TimeConstants";
import ValidationConstants from "Constants/ValidationConstants";
import {
  DELETE_FPSO_AREA_PICTURE,
  INSERT_FPSO_AREA_PICTURE,
} from "Graphql/FpsoQueries";
import MaterialTable from "material-table";
import { FPSOArea } from "Models/FPSOArea";
import { FpsoAreaPicture } from "Models/FpsoAreaPicture";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../../Dialogs/ConfirmDialog";
import { FileDropZone } from "../../FileDropZone/FileDropZone";
import { TableIcons } from "../../Icons/TableIcons";

interface Props {
  readonly fpsoArea: FPSOArea;
}

export const FpsoAreaPicturesUpload: FC<Props> = ({ fpsoArea }) => {
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [fpsoAreaPictureFiles, setFpsoAreaPictureFiles] = useState<File[]>();
  const [fpsoAreaPictures, setFpsoAreaPictures] = useState<FpsoAreaPicture[]>(
    fpsoArea.fpsoAreaPictures,
  );
  const [pictureUploadSuccessOpen, setPictureUploadSuccessOpen] = useState(
    false,
  );
  const [pictureUploadFailureOpen, setPictureUploadFailureOpen] = useState(
    false,
  );
  const [pictureDeleteSuccessOpen, setPictureDeleteSuccessOpen] = useState(
    false,
  );
  const [pictureDeleteFailureOpen, setPictureDeleteFailureOpen] = useState(
    false,
  );

  const [pictureDeleteDialogOpen, setPictureDeleteDialogOpen] = useState(false);
  const [selectedPictureForDelete, setSelectedPictureForDelete] = useState<
    FpsoAreaPicture
  >();

  const [insertFpsoAreaPicture] = useMutation(INSERT_FPSO_AREA_PICTURE);
  const [deleteFpsoAreaPicture] = useMutation(DELETE_FPSO_AREA_PICTURE);

  const handleImageFilesSelected = (files: File[]) => {
    setFpsoAreaPictureFiles(files);
  };

  const uploadFpsoAreaPictures = () => {
    const files = fpsoAreaPictureFiles;
    files?.forEach(file => {
      const formData = new FormData();
      formData.set(
        "folderName",
        `fpso-media/${fpsoArea.fpso.id}/${fpsoArea.id}/`,
      );
      formData.append("file", file);

      axios
        .post(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `bearer ${keycloak?.token}`,
          },
        })
        .then(result => {
          if (keycloak?.tokenParsed?.sub) {
            insertFpsoAreaPicture({
              variables: {
                fpsoAreaId: fpsoArea.id,
                pictureUrl: result.data,
                thumbUrl: `${result.data}_thumb`,
                pictureDescription: file.name,
              },
            }).then(insertFpsoAreaPictureResult => {
              if (insertFpsoAreaPictureResult.errors) {
                setPictureUploadFailureOpen(true);
              } else {
                setPictureUploadSuccessOpen(true);
              }
            });
          }
        });
    });
  };

  const handlePictureUploadSuccessClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setPictureUploadSuccessOpen(false);
  };

  const handlePictureUploadFailureClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setPictureUploadFailureOpen(false);
  };

  const handlePictureDeleteSuccessClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setPictureDeleteSuccessOpen(false);
  };

  const handlePictureDeleteFailureClose = (
    event?: SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setPictureDeleteFailureOpen(false);
  };

  const handleDeleteDialogOpen = (picture: FpsoAreaPicture) => {
    setSelectedPictureForDelete(picture);
    setPictureDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setPictureDeleteDialogOpen(false);
  };

  const handlePictureDeleteClicked = () => {
    setPictureDeleteDialogOpen(false);

    if (selectedPictureForDelete) {
      deletePicture(selectedPictureForDelete);
    }
  };

  const deletePicture = (picture: FpsoAreaPicture) => {
    if (picture.pictureUrl) {
      axios
        .delete(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, {
          headers: { Authorization: `bearer ${keycloak?.token}` },
          params: { fileName: picture.pictureUrl },
        })
        .then(() => {
          if (keycloak?.tokenParsed?.sub) {
            deleteFpsoAreaPicture({
              variables: {
                id: picture.id,
              },
            }).then(deleteFpsoAreaPictureResult => {
              if (deleteFpsoAreaPictureResult.errors) {
                setPictureDeleteFailureOpen(true);
              } else {
                setFpsoAreaPictures(
                  fpsoAreaPictures.filter(
                    fpsoAreaPicture => fpsoAreaPicture.id !== picture.id,
                  ),
                );
                setPictureDeleteSuccessOpen(true);
              }
            });
          }
        });
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("fpsoArea_picture_upload_title")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FileDropZone
          handleFilesSelected={handleImageFilesSelected}
          allowMultipleFiles={true}
          acceptedFileTypes={ValidationConstants.ACCEPTED_IMAGE_TYPES}
        />
        <Button onClick={uploadFpsoAreaPictures} startIcon={<SaveIcon />}>
          {t("picture_upload")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <MaterialTable
          options={{
            pageSizeOptions: [5, 10, 20],
            search: false,
            emptyRowsWhenPaging: false,
            actionsColumnIndex: -1,
          }}
          title={t("picture_uploaded_title")}
          icons={TableIcons}
          data={fpsoAreaPictures}
          columns={[
            {
              title: t("picture_description"),
              field: "pictureDescription",
              headerStyle: {
                fontWeight: "bold",
              },
            },
            {
              title: t("picture_upload_date"),
              field: "createdAt",
              headerStyle: {
                fontWeight: "bold",
              },
              render: rowData => (
                <>
                  {moment(rowData.createdAt).format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                </>
              ),
            },
          ]}
          actions={[
            {
              icon: () => <DeleteIcon />,
              onClick: (event, rowData) => {
                if (Array.isArray(rowData)) {
                  handleDeleteDialogOpen(rowData[0]);
                } else {
                  handleDeleteDialogOpen(rowData);
                }
              },
            },
          ]}
          localization={{
            header: {
              actions: " ",
            },
          }}
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Snackbar
        open={pictureUploadSuccessOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handlePictureUploadSuccessClose}
      >
        <Alert onClose={handlePictureUploadSuccessClose} severity="success">
          {t("picture_upload_success")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={pictureUploadFailureOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handlePictureUploadFailureClose}
      >
        <Alert onClose={handlePictureUploadFailureClose} severity="error">
          {t("picture_upload_failure")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={pictureDeleteSuccessOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handlePictureDeleteSuccessClose}
      >
        <Alert onClose={handlePictureDeleteSuccessClose} severity="success">
          {t("picture_delete_success")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={pictureDeleteFailureOpen}
        autoHideDuration={TimeConstants.SNACKBAR_ALERT_DURATION}
        onClose={handlePictureDeleteFailureClose}
      >
        <Alert onClose={handlePictureDeleteFailureClose} severity="error">
          {t("picture_delete_failure")}
        </Alert>
      </Snackbar>
      <ConfirmDialog
        dialogOpen={pictureDeleteDialogOpen}
        title={t("picture_delete_title")}
        description={t("picture_delete_description")}
        handleDialogClose={handleDeleteDialogClose}
        handleDialogConfirmClicked={handlePictureDeleteClicked}
      />
    </>
  );
};
