import { SubSystemType } from "Enums/SubSystemType";
import { ItemSubSystem } from "Models/ItemSubSystem";
import { useTranslation } from "react-i18next";

export const SubSystemDefaultLabelLogic = (
  defaultLabelBlank: string,
  subSystem: ItemSubSystem,
) => {
  const { t } = useTranslation("fpsos");

  if (subSystem.subSystemType === SubSystemType.powerDistributionSystem) {
    defaultLabelBlank = t("power_distribution_system");
  } else if (subSystem.subSystemType === SubSystemType.earthingSystem) {
    defaultLabelBlank = t("earthing_system");
  } else if (subSystem.subSystemType === SubSystemType.lightningSystem) {
    defaultLabelBlank = t("lightning_system");
  } else if (subSystem.subSystemType === SubSystemType.HVACSystem) {
    defaultLabelBlank = t("HVAC_system");
  } else if (subSystem.subSystemType === SubSystemType.processControlSystem) {
    defaultLabelBlank = t("process_control_system");
  } else if (subSystem.subSystemType === SubSystemType.powerGenerationSystem) {
    defaultLabelBlank = t("power_generation_system");
  } else if (subSystem.subSystemType === SubSystemType.safetySystem) {
    defaultLabelBlank = t("safety_system");
  } else if (subSystem.subSystemType === SubSystemType.FAndGDetectionSystem) {
    defaultLabelBlank = t("F_and_G_detection_system");
  } else if (subSystem.subSystemType === SubSystemType.oilExportSystem) {
    defaultLabelBlank = t("oil_export_system");
  } else if (subSystem.subSystemType === SubSystemType.gasExportSystem) {
    defaultLabelBlank = t("gas_export_system");
  } else if (
    subSystem.subSystemType === SubSystemType.seaWaterInjectionSystem
  ) {
    defaultLabelBlank = t("sea_water_injection_system");
  } else if (
    subSystem.subSystemType === SubSystemType.processWaterInjectionSystem
  ) {
    defaultLabelBlank = t("process_water_injection_system");
  } else if (subSystem.subSystemType === SubSystemType.gasInjectionSystem) {
    defaultLabelBlank = t("gas_injection_system");
  } else if (subSystem.subSystemType === SubSystemType.flareSystem) {
    defaultLabelBlank = t("flare_system");
  } else if (subSystem.subSystemType === SubSystemType.closedVentSystem) {
    defaultLabelBlank = t("closed_vent_system");
  } else if (subSystem.subSystemType === SubSystemType.openVentSystem) {
    defaultLabelBlank = t("open_vent_system");
  } else if (
    subSystem.subSystemType === SubSystemType.chemicalInjectionSystem
  ) {
    defaultLabelBlank = t("chemical_injection_system");
  } else if (subSystem.subSystemType === SubSystemType.heatingSystem) {
    defaultLabelBlank = t("heating_system");
  } else if (subSystem.subSystemType === SubSystemType.coolingSystem) {
    defaultLabelBlank = t("cooling_system");
  } else if (subSystem.subSystemType === SubSystemType.productStorageSystem) {
    defaultLabelBlank = t("product_storage_system");
  } else if (subSystem.subSystemType === SubSystemType.productTransferSystem) {
    defaultLabelBlank = t("product_transfer_system");
  } else if (
    subSystem.subSystemType === SubSystemType.productSeparationSystem
  ) {
    defaultLabelBlank = t("product_separation_system");
  } else if (subSystem.subSystemType === SubSystemType.gasDehydrationSystem) {
    defaultLabelBlank = t("gas_dehydration_system");
  } else if (subSystem.subSystemType === SubSystemType.sulfurRemovalSystem) {
    defaultLabelBlank = t("sulfur_removal_system");
  } else if (
    subSystem.subSystemType === SubSystemType.carbonDioxideRemovalSystem
  ) {
    defaultLabelBlank = t("carbon_dioxide_removal_system");
  } else if (subSystem.subSystemType === SubSystemType.gasBTUReductionSystems) {
    defaultLabelBlank = t("gas_BTU_reduction_systems");
  } else if (subSystem.subSystemType === SubSystemType.H2SRemovalSystem) {
    defaultLabelBlank = t("H2S_removal_system");
  } else if (subSystem.subSystemType === SubSystemType.gasCompression) {
    defaultLabelBlank = t("gas_compression");
  } else if (subSystem.subSystemType === SubSystemType.producedWaterTreatment) {
    defaultLabelBlank = t("produced_water_treatment");
  } else if (
    subSystem.subSystemType === SubSystemType.producedWaterDischargeDisposal
  ) {
    defaultLabelBlank = t("produced_water_discharge_disposal");
  } else if (subSystem.subSystemType === SubSystemType.freshWaterSystem) {
    defaultLabelBlank = t("fresh_water_system");
  } else if (subSystem.subSystemType === SubSystemType.washWaterSystem) {
    defaultLabelBlank = t("wash_water_system");
  } else if (subSystem.subSystemType === SubSystemType.nitrogenSystem) {
    defaultLabelBlank = t("nitrogen_system");
  } else if (subSystem.subSystemType === SubSystemType.instrumentAirSystem) {
    defaultLabelBlank = t("instrument_air_system");
  } else if (subSystem.subSystemType === SubSystemType.fuelGasSystem) {
    defaultLabelBlank = t("fuel_gas_system");
  } else if (subSystem.subSystemType === SubSystemType.dieselFuelSystem) {
    defaultLabelBlank = t("diesel_fuel_system");
  } else if (subSystem.subSystemType === SubSystemType.closedDrainSystem) {
    defaultLabelBlank = t("closed_drain_system");
  } else if (subSystem.subSystemType === SubSystemType.openDrainSystem) {
    defaultLabelBlank = t("open_drain_system");
  } else if (subSystem.subSystemType === SubSystemType.antiCorrosionSystem) {
    defaultLabelBlank = t("anti_corrosion_system");
  } else if (subSystem.subSystemType === SubSystemType.fireFightingSystem) {
    defaultLabelBlank = t("fire_fighting_system");
  } else if (
    subSystem.subSystemType === SubSystemType.radioAndCommunicationEquipment
  ) {
    defaultLabelBlank = t("radio_and_communication_equipment");
  } else if (subSystem.subSystemType === SubSystemType.rescueEquipment) {
    defaultLabelBlank = t("rescue_equipment");
  } else if (subSystem.subSystemType === SubSystemType.mooringSystem) {
    defaultLabelBlank = t("mooring_system");
  } else if (subSystem.subSystemType === SubSystemType.shipHullSystem) {
    defaultLabelBlank = t("ship_hull_system");
  } else if (
    subSystem.subSystemType === SubSystemType.transferTankerMooringSystem
  ) {
    defaultLabelBlank = t("transfer_tanker_mooring_system");
  } else if (subSystem.subSystemType === SubSystemType.loadingCraneSystem) {
    defaultLabelBlank = t("loading_crane_system");
  } else if (subSystem.subSystemType === SubSystemType.ballastSystem) {
    defaultLabelBlank = t("ballast_system");
  } else if (subSystem.subSystemType === SubSystemType.bilgePumpSystem) {
    defaultLabelBlank = t("bilge_pump_system");
  } else if (subSystem.subSystemType === SubSystemType.oilWell) {
    defaultLabelBlank = t("oil_well");
  } else if (subSystem.subSystemType === SubSystemType.gasWell) {
    defaultLabelBlank = t("gas_well");
  } else if (subSystem.subSystemType === SubSystemType.waterWell) {
    defaultLabelBlank = t("water_well");
  } else if (subSystem.subSystemType === SubSystemType.oilInjectionWell) {
    defaultLabelBlank = t("oil_injection_well");
  } else if (subSystem.subSystemType === SubSystemType.gasInjectionWell) {
    defaultLabelBlank = t("gas_injection_well");
  } else if (subSystem.subSystemType === SubSystemType.waterInjectionWell) {
    defaultLabelBlank = t("water_injection_well");
  } else if (subSystem.subSystemType === SubSystemType.waterInjection) {
    defaultLabelBlank = t("water_injection");
  } else if (subSystem.subSystemType === SubSystemType.productionWell) {
    defaultLabelBlank = t("production_well");
  } else if (subSystem.subSystemType === SubSystemType.liftGasSystem) {
    defaultLabelBlank = t("lift_gas_system");
  } else if (subSystem.subSystemType === SubSystemType.risers) {
    defaultLabelBlank = t("risers");
  } else if (subSystem.subSystemType === SubSystemType.equipmentA) {
    defaultLabelBlank = t("equipment_a");
  } else if (subSystem.subSystemType === SubSystemType.equipmentB) {
    defaultLabelBlank = t("equipment_b");
  } else if (subSystem.subSystemType === SubSystemType.equipmentC) {
    defaultLabelBlank = t("equipment_c");
  } else if (subSystem.subSystemType === SubSystemType.offshore) {
    defaultLabelBlank = t("offshore");
  } else if (subSystem.subSystemType === SubSystemType.onshore) {
    defaultLabelBlank = t("onshore");
  }

  return defaultLabelBlank;
};
