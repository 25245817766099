import { Button, FormGroup, Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import FontConstants from "Constants/FontConstants";
import { Roles } from "Enums/Roles";
import { Comment } from "Models/Comment";
import { ConfirmDialog } from "Views/Components/Dialogs/ConfirmDialog";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

import { keycloak } from "keycloak";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  handleCommentEdited(updatedComment: Comment): void;
  handleCommentDeleted(deletedComment: Comment): void;
  readonly carEvidenceComment: Comment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      width: "95%",
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: FontConstants.SUBHEADING_WEIGHT,
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
    languageTextFields: {
      margin: theme.spacing(1),
    },
  }),
);

export const CarEvidenceCommentEdit: FC<Props> = ({
  handleCommentEdited,
  carEvidenceComment,
  handleCommentDeleted,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");

  const [unchangedComment] = useState<Comment>({
    id: carEvidenceComment.id,
    content: carEvidenceComment.content,
    createdAt: carEvidenceComment.createdAt,
    updatedAt: carEvidenceComment.updatedAt,
    commenter: carEvidenceComment.commenter,
  });

  const [updatedComment] = useState<Comment>(unchangedComment);
  const [deletedComment] = useState<Comment>(carEvidenceComment);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    handleCommentEdited(updatedComment);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleCommentDeleted(deletedComment);
  };

  return (
    <div className={classes.root}>
      {(carEvidenceComment.commenter.id === keycloak?.idTokenParsed?.sub ||
        keycloak?.hasRealmRole(Roles.administrator)) && (
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  className={classes.languageTextFields}
                  multiline
                  fullWidth
                  rows="4"
                  rowsMax="8"
                  variant="outlined"
                  label={
                    updatedComment.commenter.fullName + t("belongsComment")
                  }
                  defaultValue={updatedComment.content}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updatedComment.content = event.target.value;
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.bottomButtonRow}>
              <Button type="submit" startIcon={<SaveIcon />}>
                {t("save")}
              </Button>
              <Button
                onClick={() => handleDeleteDialogOpen()}
                startIcon={<DeleteIcon />}
              >
                {t("delete")}
              </Button>
            </Grid>
            <ConfirmDialog
              dialogOpen={deleteDialogOpen}
              title={t("deleteCommentTitle")}
              description={t("deleteCommentDescription")}
              handleDialogClose={handleDeleteDialogClose}
              handleDialogConfirmClicked={handleDeleteClicked}
            />
          </FormGroup>
        </form>
      )}
    </div>
  );
};
