import React, { FC } from "react";

export const ExcelDownloadIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <path
        fill="none;stroke:#575756;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10"
        d="M49.96,13.82"
      />
      <g>
        <path
          fill="#575756"
          d="M50.94,13.68c-0.01-0.08-0.02-0.16-0.05-0.23c-0.03-0.07-0.08-0.13-0.12-0.2c-0.03-0.04-0.04-0.09-0.08-0.12
		L41.15,3.1c0,0-0.01,0-0.01-0.01C41.08,3.03,41,2.98,40.92,2.94c-0.03-0.02-0.06-0.05-0.1-0.06c-0.04-0.02-0.09-0.02-0.13-0.03
		c-0.08-0.02-0.16-0.05-0.25-0.05c0,0-0.01,0-0.01,0H18.86c-1.68,0-3.04,1.36-3.04,3.04v41.81c0,1.68,1.36,3.04,3.04,3.04h7.88
		l-0.99-0.99c-0.3-0.3-0.52-0.64-0.66-1.01h-6.23c-0.57,0-1.04-0.47-1.04-1.04V5.83c0-0.57,0.47-1.04,1.04-1.04h20.57v9.02
		c0,0.55,0.45,1,1,1h8.53v32.83c0,0.57-0.47,1.04-1.04,1.04H41.7c-0.15,0.37-0.37,0.71-0.66,1.01l-0.99,0.99h7.88
		c1.68,0,3.04-1.36,3.04-3.04V13.82C50.96,13.77,50.94,13.72,50.94,13.68z M41.43,6.29l6.2,6.52h-6.2V6.29z"
        />
        <path
          fill="#575756"
          d="M38.2,46.87l-3.81,3.87v-9.11c0-0.55-0.45-1-1-1s-1,0.45-1,1v9.05l-3.81-3.81c-0.39-0.39-1.02-0.39-1.41,0
		s-0.39,1.02,0,1.41l4.85,4.85c0.38,0.38,0.88,0.57,1.38,0.57s1-0.19,1.38-0.57l4.85-4.85c0.39-0.39,0.39-1.02,0-1.41
		S38.59,46.48,38.2,46.87z"
        />
        <path
          fill="#575756"
          d="M38.91,55.15H27.88c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.03c0.55,0,1-0.45,1-1S39.46,55.15,38.91,55.15z"
        />
      </g>
      <g>
        <path
          fill="#008D36"
          d="M22.49,45.16H9.68c-0.62,0-1.13-0.51-1.13-1.13v-12.8c0-0.62,0.51-1.13,1.13-1.13h12.8
		c0.62,0,1.13,0.51,1.13,1.13v12.8C23.62,44.65,23.11,45.16,22.49,45.16z"
        />
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M14.52,32.33l1.32,3.32l1.31-3.32h2.26l-2.39,5.08l2.56,5.19h-2.33l-1.48-3.48l-1.47,3.48h-2.27l2.56-5.19
				l-2.36-5.08H14.52z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
