import React, { FC } from "react";

export const EditAuditInfoIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g>
        <g>
          <g>
            <path
              fill="#575756"
              d="M41.11,19.94v35.04c0,0.07-0.06,0.13-0.13,0.13H10.1c-0.07,0-0.13-0.06-0.13-0.13V11.36
                   c0-0.07,0.06-0.13,0.13-0.13h6.77v1.77c0,1.18,0.96,2.13,2.13,2.13h13.06c1.18,0,2.13-0.96,2.13-2.13v-1.77h2.46l2-2h-4.46V7.46
                   c0-1.18-0.96-2.13-2.13-2.13H19.01c-1.18,0-2.13,0.96-2.13,2.13v1.77H10.1c-1.18,0-2.13,0.96-2.13,2.13v43.62
                   c0,1.18,0.96,2.13,2.13,2.13h30.88c1.18,0,2.13-0.96,2.13-2.13V17.94L41.11,19.94z M32.21,11.22v1.77c0,0.07-0.06,0.13-0.13,0.13
                   H19.01c-0.07,0-0.13-0.06-0.13-0.13v-1.77v-2V7.46c0-0.07,0.06-0.13,0.13-0.13h13.06c0.07,0,0.13,0.06,0.13,0.13v1.77V11.22z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#575756"
              d="M51.25,5.55l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-19.9,19.9c-0.11,0.11-0.2,0.26-0.25,0.41l-1.06,3.4
                   c-0.11,0.35-0.01,0.74,0.25,1c0.19,0.19,0.45,0.29,0.71,0.29c0.1,0,0.2-0.01,0.3-0.04l3.4-1.06c0.15-0.05,0.3-0.13,0.41-0.25
                   l19.9-19.9c0.19-0.19,0.29-0.44,0.29-0.71S51.44,5.74,51.25,5.55z M30.11,25.28l-1.34,0.42l0.42-1.34L48.2,5.33l0.93,0.93
                   L30.11,25.28z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
