import { SubSystemType } from "Enums/SubSystemType";
import { OptionType } from "Models/OptionType";
import { MultiValue } from "react-select";

export const convertSelectedSubSystemsToArray = (
  selectedSubSystems: MultiValue<OptionType> | undefined,
) => {
  // Convert selectedSubSystems from option type array to string object
  const selectedSubSystemsString = selectedSubSystems
    ?.map(({ value }) => `${value}`)
    .join(", ");

  // Declare empty string array to hold selectedSubSystems options
  let selectedSubSystemsStringArray: string[] | undefined = [];

  if (
    selectedSubSystemsString !== null ||
    selectedSubSystemsString !== undefined ||
    selectedSubSystemsString !== ""
  ) {
    // Convert selectedSubSystemsString string object to string array
    selectedSubSystemsStringArray = selectedSubSystemsString?.split(
      ", ",
      selectedSubSystemsString.length,
    );
  }

  // Convert selectedSubSystemsArray from string array to SubSystemType enum array,
  // which can now be passed as input variable for system details query
  const selectedSubSystemsSubSystemTypeArray = selectedSubSystemsStringArray as SubSystemType[];

  return { selectedSubSystemsSubSystemTypeArray, selectedSubSystemsString };
};
