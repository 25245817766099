import {
  Button,
  Grid,
  GridSize,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import FontConstants from "Constants/FontConstants";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import { FpsoAreaDocument } from "Models/FpsoAreaDocument";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditAuditInfoIcon } from "../Icons/EditAuditInfoIcon";
import { FpsoAreaDocumentDetailsEdit } from "./FpsoAreaDocumentDetailsEdit";

interface Props {
  readonly fpsoAreaDocumentRow: FpsoAreaDocument;
  handleRowEdited?(
    updatedFpsoAreaDocumentRow: FpsoAreaDocument,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  handleDocumentDeleted(document: FpsoAreaDocument): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: FontConstants.SUBHEADING_WEIGHT,
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
    },
    subSystemList: {
      display: "flex",
    },
    subSystemItem: {
      padding: theme.spacing(1),
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
  }),
);

export const FpsoAreaDocumentDetails: FC<Props> = ({
  fpsoAreaDocumentRow: originalFpsoAreaDocumentRow,
  handleDocumentDeleted,
  handleRowEdited: handleEditedRowReceived,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleRowEdited = (
    updatedFpsoAreaDocumentRow: FpsoAreaDocument,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    setEditEnabled(false);
    if (handleEditedRowReceived) {
      handleEditedRowReceived(
        updatedFpsoAreaDocumentRow,
        updatedSubSystems,
        changeInSubSystems,
      );
    }
  };

  let editButtonSize: GridSize = 12;
  if (originalFpsoAreaDocumentRow.itemsWithSubSystems.length !== 0) {
    editButtonSize = 1;
  }

  return (
    <Grid container className={classes.root}>
      {!editEnabled && (
        <>
          {originalFpsoAreaDocumentRow.itemsWithSubSystems.length !== 0 && (
            <Grid item xs={11} className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("subSystems")}</div>
              <div className={classes.subSystemList}>
                {originalFpsoAreaDocumentRow.itemsWithSubSystems.map(
                  subSystem => (
                    <Paper className={classes.subSystemItem} variant="outlined">
                      {t("" + subSystem.subSystemType)}
                    </Paper>
                  ),
                )}
              </div>
            </Grid>
          )}
          {(keycloak?.hasRealmRole(Roles.auditor) ||
            keycloak?.hasRealmRole(Roles.administrator)) && (
            <Grid item xs={editButtonSize} className={classes.detailsButtonRow}>
              <Tooltip
                title={t<string>("tooltipEditFpsoAreaDocument")}
                arrow={true}
              >
                <Button onClick={handleEditClicked}>
                  <EditAuditInfoIcon />
                </Button>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={2}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("documentNumber")}</div>
              <div>{originalFpsoAreaDocumentRow.documentNumber}</div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("revision")}</div>
              <div>{originalFpsoAreaDocumentRow.revision}</div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("doc_description")}</div>
              <div>{originalFpsoAreaDocumentRow.description}</div>
            </div>
          </Grid>
        </>
      )}

      {editEnabled && (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">{t("editFpsoAreaDocumentTitle")}</Typography>
          <Tooltip title={t<string>("tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">{t("discardChanges")}</Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      {editEnabled && handleDocumentDeleted && (
        <FpsoAreaDocumentDetailsEdit
          documentRow={originalFpsoAreaDocumentRow}
          handleDocumentDeleted={handleDocumentDeleted}
          handleRowEdited={handleRowEdited}
          fpsoAreaName={fpsoAreaName}
        />
      )}
    </Grid>
  );
};
