import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  handleLockAudit(): void;
  readonly auditLockStatus: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      textTransform: "capitalize",
      color: "white",
    },
  }),
);

export const LockAuditButton: FC<Props> = ({
  handleLockAudit,
  auditLockStatus,
}) => {
  const { t } = useTranslation("audits");
  const classes = useStyles();

  const handleLockAuditClick = () => {
    handleLockAudit();
  };
  if (auditLockStatus === false) {
    return (
      <>
        <Tooltip title={t<string>("audits:lockAuditTooltip")} arrow={true}>
          <Button
            onClick={() => handleLockAuditClick()}
            className={classes.buttonText}
          >
            <LockOpenIcon style={{ fill: "white", fontSize: 28 }} />
            <Typography variant="body1">{t("audits:lockAudit")}</Typography>
          </Button>
        </Tooltip>
      </>
    );
  } else if (auditLockStatus === true) {
    return (
      <>
        <Tooltip title={t<string>("audits:unlockAuditTooltip")} arrow={true}>
          <Button
            onClick={() => handleLockAuditClick()}
            className={classes.buttonText}
          >
            <LockIcon style={{ fill: "white", fontSize: 28 }} />
            <Typography variant="body1">{t("audits:unlockAudit")}</Typography>
          </Button>
        </Tooltip>
      </>
    );
  }
  return <></>;
};
