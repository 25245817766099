import {
  Button,
  Grid,
  GridSize,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import LanguageCodes from "Constants/LanguageCodes";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import { SystemArea } from "Enums/SystemArea";
import i18next from "i18next";
import { CarRow } from "Models/CarRow";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CarDetailsEdit } from "Views/Components/CarDetails/CarDetailsEdit";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";

interface Props {
  readonly carRow: CarRow;
  readonly isActive: boolean;
  readonly isLocked: boolean;
  handleRowEdited?(
    updatedCarRow: CarRow,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  handleRowDeleted?(deletedCarRow: CarRow): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: "bold",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
    subSystemList: {
      display: "flex",
    },
    subSystemItem: {
      padding: theme.spacing(1),
    },
  }),
);

export const CarDetails: FC<Props> = ({
  carRow: originalCarRow,
  isActive,
  isLocked,
  handleRowEdited: handleEditedRowReceived,
  handleRowDeleted,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };
  const handleRowEdited = (
    updatedCarRow: CarRow,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    setEditEnabled(false);
    if (handleEditedRowReceived) {
      handleEditedRowReceived(
        updatedCarRow,
        updatedSubSystems,
        changeInSubSystems,
      );
    }
  };

  let editButtonSize: GridSize = 12;
  if (originalCarRow.itemsWithSubSystems.length !== 0) {
    editButtonSize = 1;
  }
  let subSystemDetailsSize: GridSize = 11;
  if (originalCarRow.isDeleted) {
    subSystemDetailsSize = 12;
  }

  return (
    <Grid container className={classes.root}>
      {editEnabled ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {t("editTitle") + originalCarRow.carReference}
          </Typography>
          <Tooltip title={t<string>("tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">{t("discardChanges")}</Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        <>
          {originalCarRow.itemsWithSubSystems.length !== 0 && (
            <Grid
              item
              xs={subSystemDetailsSize}
              className={classes.detailContainer}
            >
              <div className={classes.detailHeader}>
                {t("fpsos:subSystems")}
              </div>
              <div className={classes.subSystemList}>
                {originalCarRow.itemsWithSubSystems.map(subSystem => (
                  <Paper className={classes.subSystemItem} variant="outlined">
                    {t("fpsos:" + subSystem.subSystemType)}
                  </Paper>
                ))}
              </div>
            </Grid>
          )}
          {!originalCarRow.isDeleted &&
            isActive &&
            ((originalCarRow.addedById === keycloak?.idTokenParsed?.sub &&
              keycloak?.hasRealmRole(Roles.auditor)) ||
              keycloak?.hasRealmRole(Roles.administrator)) &&
            isLocked === false && (
              <Grid
                item
                xs={editButtonSize}
                className={classes.detailsButtonRow}
              >
                <Tooltip title={t<string>("tooltipEditCar")} arrow={true}>
                  <Button onClick={handleEditClicked}>
                    <EditAuditInfoIcon />
                  </Button>
                </Tooltip>
              </Grid>
            )}
        </>
      )}
      {editEnabled && handleRowDeleted && isActive ? (
        <CarDetailsEdit
          carRow={originalCarRow}
          handleRowDeleted={handleRowDeleted}
          handleRowEdited={handleRowEdited}
          fpsoAreaName={fpsoAreaName}
        />
      ) : (
        <>
          <Grid item xs={2} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("carScore")}</div>
            <div>{originalCarRow.carScore}</div>
          </Grid>
          <Grid item xs={4} className={classes.detailContainer}>
            <div className={classes.detailHeader}>
              {t("componentsAffected")}
            </div>
            <div>{originalCarRow.componentsAffected}</div>
          </Grid>

          <Grid item xs={3} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("areasAffected")}</div>
            <div>
              {(() => {
                switch (originalCarRow.systemAreasAffected) {
                  case SystemArea.electrical:
                    return <>{t("fpsos:electrical")}</>;
                  case SystemArea.instrument:
                    return <>{t("fpsos:instrument")}</>;
                  case SystemArea.mechanical:
                    return <>{t("fpsos:mechanical")}</>;
                  case SystemArea.hardware:
                    return <>{t("fpsos:hardware")}</>;
                  case SystemArea.piping:
                    return <>{t("fpsos:piping")}</>;
                  case SystemArea.welfare:
                    return <>{t("fpsos:welfare")}</>;
                  case SystemArea.process:
                    return <>{t("fpsos:process")}</>;
                  case SystemArea.safety:
                    return <>{t("fpsos:safety")}</>;
                  case SystemArea.vessel:
                    return <>{t("fpsos:vessel")}</>;
                  case SystemArea.liftingEquipment:
                    return <>{t("fpsos:liftingEquipment")}</>;
                  case SystemArea.other:
                    return <>{t("fpsos:other")}</>;
                  case SystemArea.wasteManagement:
                    return <>{t("fpsos:wasteManagement")}</>;
                  case SystemArea.chemicalsAndFuel:
                    return <>{t("fpsos:chemicalsAndFuel")}</>;
                  default:
                    return null;
                }
              })()}
            </div>
          </Grid>

          <Grid item xs={3} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("affectedSince")}</div>
            {originalCarRow.affectedSince ? (
              <>
                {moment(originalCarRow.affectedSince).format(
                  DateConstants.DEFAULT_DATE_FORMAT,
                )}
              </>
            ) : (
              <>{t("cars:notAvailable")}</>
            )}
          </Grid>

          <Grid item xs={6} className={classes.detailContainer}>
            <div className={classes.detailHeader}>
              {t("initialAgreedVerificationDate")}
            </div>
            {originalCarRow.initialAgreedVerificationDate ? (
              <>
                {moment(originalCarRow.initialAgreedVerificationDate).format(
                  DateConstants.DEFAULT_DATE_FORMAT,
                )}
              </>
            ) : (
              <>{t("cars:notAvailable")}</>
            )}
          </Grid>
          <Grid item xs={6} className={classes.detailContainer}>
            <div className={classes.detailHeader}>{t("initialClass")}</div>
            <div>{originalCarRow.initialClass}</div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>
                {t("furtherDescription")}
              </div>
              <div className={classes.multiLine}>
                {i18next.language === LanguageCodes.ENGLISH
                  ? originalCarRow.englishTranslation.furtherDescription ||
                    t("notAvailable")
                  : originalCarRow.portugueseTranslation.furtherDescription ||
                    t("notAvailable")}
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("auditFinding")}</div>
              <div className={classes.multiLine}>
                {i18next.language === LanguageCodes.ENGLISH
                  ? originalCarRow.englishTranslation.auditFinding ||
                    t("notAvailable")
                  : originalCarRow.portugueseTranslation.auditFinding ||
                    t("notAvailable")}
              </div>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
