import { gql } from "apollo-boost";
import { Feedback } from "Models/Feedback";

export const GET_FEEDBACK = gql`
  query getFeedback {
    userFeedback: user_feedback(order_by: { createdAt: desc }) {
      id
      createdAt
      updatedAt
      subject
      feedback
      commenter {
        id
        fullName
      }
      userFeedbackReplies: user_feedback_replies(
        order_by: { createdAt: desc }
      ) {
        id
        createdAt
        updatedAt
        userFeedbackId
        reply
        replier {
          id
          fullName
        }
      }
    }
  }
`;

export interface GetFeedbackQueryResult {
  userFeedback: Feedback[];
}

export const INSERT_USER_FEEDBACK = gql`
  mutation insertUserFeedback(
    $id: uuid!
    $feedback: String
    $subject: String
    $commenterId: uuid!
  ) {
    result: insert_user_feedback(
      objects: {
        id: $id
        feedback: $feedback
        subject: $subject
        commenterId: $commenterId
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_USER_FEEDBACK = gql`
  mutation deleteUserFeedback($id: uuid!) {
    result: delete_user_feedback(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_USER_FEEDBACK_REPLY = gql`
  mutation insertUserFeedbackReply(
    $id: uuid!
    $reply: String
    $replierId: uuid!
    $userFeedbackId: uuid!
  ) {
    result: insert_user_feedback_replies(
      objects: {
        id: $id
        reply: $reply
        replierId: $replierId
        userFeedbackId: $userFeedbackId
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_USER_FEEDBACK_REPLY = gql`
  mutation deleteUserFeedbackReply($id: uuid!) {
    result: delete_user_feedback_replies(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
