import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import { CarEvidenceStatus } from "Enums/CarEvidenceStatus";
import { Roles } from "Enums/Roles";
import { CarEvidenceDocumentEditModel } from "Models/CarEvidenceDocumentEditModel";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "Views/Components/Dialogs/ConfirmDialog";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  readonly carEvidenceDocument: CarEvidenceDocumentEditModel;
  handleEvidenceRowEdited(
    updatedEvidenceRow: CarEvidenceDocumentEditModel,
  ): void;
  handleEvidenceRowDeleted(
    deletedEvidenceRow: CarEvidenceDocumentEditModel,
  ): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    spacedOut: {
      display: "flex",
      justifyContent: "space-between",
    },
    languageTextFields: {
      margin: theme.spacing(1),
      paddingRight: theme.spacing(4),
    },
  }),
);

export const CarEvidenceDocumentEdit: FC<Props> = ({
  carEvidenceDocument: originalCarEvidenceDocument,
  handleEvidenceRowEdited,
  handleEvidenceRowDeleted,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");
  const { keycloak } = useKeycloak();

  const [unchangedCarEvidenceDocument] = useState<CarEvidenceDocumentEditModel>(
    {
      id: originalCarEvidenceDocument.id,
      documentDescription: originalCarEvidenceDocument.documentDescription,
      evidenceDocumentName: originalCarEvidenceDocument.evidenceDocumentName,
      evidenceDocumentReference:
        originalCarEvidenceDocument.evidenceDocumentReference,
      evidenceStatus: originalCarEvidenceDocument.evidenceStatus,
      createdAt: originalCarEvidenceDocument.createdAt,
      updatedAt: originalCarEvidenceDocument.updatedAt,
      revision: originalCarEvidenceDocument.revision,
      documentNumber: originalCarEvidenceDocument.documentNumber,
      submitter: originalCarEvidenceDocument.submitter,
    },
  );
  const [updatedCarEvidenceDocument, setUpdatedCarEvidenceDocument] = useState(
    unchangedCarEvidenceDocument,
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    handleEvidenceRowEdited(updatedCarEvidenceDocument);
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleEvidenceRowDeleted(updatedCarEvidenceDocument);
  };

  const handleEvidenceStatusChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const carEvidenceDocument = { ...updatedCarEvidenceDocument };
    carEvidenceDocument.evidenceStatus = event.target
      .value as CarEvidenceStatus;
    setUpdatedCarEvidenceDocument(carEvidenceDocument);
  };

  const handleCreatedAtDateChange = (date: Date) => {
    const carEvidenceDocument = { ...updatedCarEvidenceDocument };
    carEvidenceDocument.createdAt = date;
    setUpdatedCarEvidenceDocument(carEvidenceDocument);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Grid item xs={12}>
          <FormGroup row>
            {(!keycloak?.hasRealmRole(Roles.operator) &&
              !keycloak?.hasRealmRole(Roles.agency) &&
              !keycloak?.hasRealmRole(Roles.agency_v2)) ||
            keycloak?.hasRealmRole(Roles.administrator) ||
            keycloak?.hasRealmRole(Roles.auditor) ? (
              <FormControl className={classes.formControl}>
                <InputLabel shrink id="evidence-status-select-label">
                  {t("evidenceStatus")}
                </InputLabel>
                <Select
                  value={updatedCarEvidenceDocument.evidenceStatus}
                  onChange={handleEvidenceStatusChange}
                >
                  <MenuItem value={CarEvidenceStatus.awaitingReview}>
                    {t("evidenceStatusAwaitingReview")}
                  </MenuItem>
                  <MenuItem value={CarEvidenceStatus.anpgAccepted}>
                    {t("evidenceStatusAnpgAccepted")}
                  </MenuItem>
                  <MenuItem value={CarEvidenceStatus.requiresOperatorReview}>
                    {t("evidenceStatusRequiresOperatorReview")}
                  </MenuItem>
                  <MenuItem value={CarEvidenceStatus.evidenceReviewed}>
                    {t("evidenceStatusEvidenceReviewed")}
                  </MenuItem>
                  <MenuItem value={CarEvidenceStatus.acceptedWithComment}>
                    {t("evidenceStatusAcceptedWithComment")}
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              (keycloak?.hasRealmRole(Roles.agency) ||
                keycloak?.hasRealmRole(Roles.agency_v2)) &&
              updatedCarEvidenceDocument.evidenceStatus !==
                CarEvidenceStatus.acceptedWithComment &&
              originalCarEvidenceDocument.evidenceStatus !==
                CarEvidenceStatus.evidenceReviewed && (
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="evidence-status-select-label">
                    {t("evidenceStatus")}
                  </InputLabel>
                  <Select
                    value={updatedCarEvidenceDocument.evidenceStatus}
                    onChange={handleEvidenceStatusChange}
                  >
                    <MenuItem value={CarEvidenceStatus.awaitingReview}>
                      {t("evidenceStatusAwaitingReview")}
                    </MenuItem>
                    <MenuItem value={CarEvidenceStatus.anpgAccepted}>
                      {t("evidenceStatusAnpgAccepted")}
                    </MenuItem>
                    <MenuItem value={CarEvidenceStatus.requiresOperatorReview}>
                      {t("evidenceStatusRequiresOperatorReview")}
                    </MenuItem>
                  </Select>
                </FormControl>
              )
            )}
          </FormGroup>
          <FormGroup row className={classes.spacedOut}>
            {((updatedCarEvidenceDocument.submitter.id ===
              keycloak?.idTokenParsed?.sub &&
              updatedCarEvidenceDocument.evidenceStatus ===
                CarEvidenceStatus.awaitingReview) ||
              keycloak?.hasRealmRole(Roles.administrator)) && (
              <>
                <FormControl className={classes.formControl}>
                  <TextField
                    label={t("doc_name")}
                    defaultValue={
                      unchangedCarEvidenceDocument.evidenceDocumentName
                    }
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                      updatedCarEvidenceDocument.evidenceDocumentName =
                        event.target.value;
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label={t("doc_number")}
                    defaultValue={unchangedCarEvidenceDocument.documentNumber}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                      updatedCarEvidenceDocument.documentNumber =
                        event.target.value;
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label={t("revision")}
                    defaultValue={unchangedCarEvidenceDocument.revision}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                      updatedCarEvidenceDocument.revision = event.target.value;
                    }}
                  />
                </FormControl>
              </>
            )}
          </FormGroup>

          <FormGroup row>
            {keycloak?.hasRealmRole(Roles.administrator) && (
              <FormControl className={classes.formControl}>
                <Typography variant={"body1"}>
                  {t("cars:adminDescriptionDateAdded")}
                </Typography>
                <DatePicker
                  clearable={true}
                  label={t("dateAdded")}
                  value={updatedCarEvidenceDocument.createdAt}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handleCreatedAtDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </FormControl>
            )}
          </FormGroup>
        </Grid>

        {((updatedCarEvidenceDocument.submitter.id ===
          keycloak?.idTokenParsed?.sub &&
          updatedCarEvidenceDocument.evidenceStatus ===
            CarEvidenceStatus.awaitingReview) ||
          keycloak?.hasRealmRole(Roles.administrator)) && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className={classes.languageTextFields}
                multiline
                fullWidth
                rows="4"
                rowsMax="8"
                variant="outlined"
                label={t("documentDescription")}
                defaultValue={unchangedCarEvidenceDocument.documentDescription}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updatedCarEvidenceDocument.documentDescription =
                    event.target.value;
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} className={classes.spacedOut}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
          {((updatedCarEvidenceDocument.submitter.id ===
            keycloak?.idTokenParsed?.sub &&
            updatedCarEvidenceDocument.evidenceStatus ===
              CarEvidenceStatus.awaitingReview) ||
            keycloak?.hasRealmRole(Roles.administrator)) && (
            <>
              <Button
                onClick={() => handleDeleteDialogOpen()}
                startIcon={<DeleteIcon />}
              >
                {t("delete")}
              </Button>
            </>
          )}
        </Grid>

        <ConfirmDialog
          dialogOpen={deleteDialogOpen}
          title={t("deleteDocumentTitle")}
          description={t("deleteDocumentDescription")}
          handleDialogClose={handleDeleteDialogClose}
          handleDialogConfirmClicked={handleDeleteClicked}
        />
      </FormGroup>
    </form>
  );
};
