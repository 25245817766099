import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import { CarClass } from "Enums/CarClass";
import { Roles } from "Enums/Roles";
import { InitializedCar } from "Models/InitialCar";
import moment from "moment";
import { FC, SyntheticEvent, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  handleAddCar(car: InitializedCar): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    saveButtonRow: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
);

export const CarDetailsAdd: FC<Props> = ({ handleAddCar }) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");
  const { keycloak } = useKeycloak();

  const [initializedCar, setInitializedCar] = useState<InitializedCar>(
    new InitializedCar(),
  );

  const handleSubmit = (event: SyntheticEvent) => {
    if (initializedCar.class === CarClass.comment) {
      initializedCar.score = 0;
    }
    if (initializedCar.class === CarClass.minor) {
      initializedCar.score = 1;
    }
    if (initializedCar.class === CarClass.major) {
      initializedCar.score = 5;
    }
    if (initializedCar.class === CarClass.critical) {
      initializedCar.score = 15;
    }
    if (initializedCar.createdAt === null) {
      initializedCar.createdAt = moment().toDate();
    }
    event.preventDefault();
    if (initializedCar) {
      handleAddCar(initializedCar);
    }
  };

  const handleClassChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const car = { ...initializedCar };
    car.class = event.target.value as CarClass;

    setInitializedCar(car);
  };

  const handleCreatedAtDateChange = (date: Date) => {
    const car = { ...initializedCar };
    car.createdAt = date;
    setInitializedCar(car);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("addCarTitle")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <FormGroup row>
            <FormControl className={classes.textFormControl}>
              <TextField
                label={t("description") + t("englishLanguageSuffix")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedCar.descriptionEnglish = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                label={t("description") + t("portugueseLanguageSuffix")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedCar.descriptionPortuguese = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="class-select-label">
                {t("class")}
              </InputLabel>
              <Select value={initializedCar.class} onChange={handleClassChange}>
                <MenuItem value={CarClass.comment}>
                  {t("classComment")}
                </MenuItem>
                <MenuItem value={CarClass.minor}>{t("classMinor")}</MenuItem>
                <MenuItem value={CarClass.major}>{t("classMajor")}</MenuItem>
                <MenuItem value={CarClass.critical}>
                  {t("classCritical")}
                </MenuItem>
              </Select>
            </FormControl>
          </FormGroup>

          <FormGroup row>
            {keycloak?.hasRealmRole(Roles.administrator) && (
              <FormControl className={classes.formControl}>
                <Typography variant={"body1"}>
                  {t("cars:adminDescriptionDateAdded")}
                </Typography>
                <DatePicker
                  clearable={true}
                  label={t("dateAdded")}
                  value={initializedCar.createdAt}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handleCreatedAtDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </FormControl>
            )}
          </FormGroup>

          <Grid item xs={12} className={classes.saveButtonRow}>
            <Button type="submit" startIcon={<SaveIcon />}>
              {t("save")}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
