import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import { CarClass } from "Enums/CarClass";
import { CarStatus } from "Enums/CarStatus";
import { Roles } from "Enums/Roles";
import { SubSystemType } from "Enums/SubSystemType";
import { SystemArea } from "Enums/SystemArea";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemDefaultLabelLogic } from "Logic/SubSystemOptions/SubSystemDefaultLabelLogic";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { CarRow } from "Models/CarRow";
import { OptionType } from "Models/OptionType";
import moment from "moment";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";
import { ConfirmDialog } from "Views/Components/Dialogs/ConfirmDialog";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  readonly carRow: CarRow;
  handleRowEdited(
    updatedCarRow: CarRow,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ): void;
  handleRowDeleted(deletedCarRow: CarRow): void;
  readonly fpsoAreaName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      width: "100%",
      paddingRight: theme.spacing(6),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_INPUT_WIDTH,
    },
    formDateControl: {
      margin: theme.spacing(1),
      minWidth: "100px",
    },
    textFormControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    formRowSpacing: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    bottomButtonRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    languageTextFields: {
      margin: theme.spacing(1),
    },
  }),
);

export const CarDetailsEdit: FC<Props> = ({
  carRow: originalCarRow,
  handleRowEdited,
  handleRowDeleted,
  fpsoAreaName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");
  const { keycloak } = useKeycloak();

  const [unchangedCarRow] = useState<CarRow>({
    id: originalCarRow.id,
    carReference: originalCarRow.carReference,
    class: originalCarRow.class,
    status: originalCarRow.status,
    performanceIndicator: originalCarRow.performanceIndicator,
    createdAt: originalCarRow.createdAt,
    addedBy: originalCarRow.addedBy,
    addedById: originalCarRow.addedById,
    agreedVerificationDate: originalCarRow.agreedVerificationDate,
    actualVerificationDate: originalCarRow.actualVerificationDate,
    carScore: originalCarRow.carScore,
    componentsAffected: originalCarRow.componentsAffected,
    systemAreasAffected: originalCarRow.systemAreasAffected,
    affectedSince: originalCarRow.affectedSince,
    initialAgreedVerificationDate: originalCarRow.initialAgreedVerificationDate,
    initialClass: originalCarRow.initialClass,
    isDeleted: originalCarRow.isDeleted,
    operatorActionRequired: originalCarRow.operatorActionRequired,
    anpgActionRequired: originalCarRow.anpgActionRequired,
    auditorActionRequired: originalCarRow.auditorActionRequired,
    operatorName: originalCarRow.operatorName,
    englishTranslation: {
      id: originalCarRow.englishTranslation.id,
      description: originalCarRow.englishTranslation.description,
      furtherDescription: originalCarRow.englishTranslation.furtherDescription,
      auditFinding: originalCarRow.englishTranslation.auditFinding,
    },
    portugueseTranslation: {
      id: originalCarRow.portugueseTranslation.id,
      description: originalCarRow.portugueseTranslation.description,
      furtherDescription:
        originalCarRow.portugueseTranslation.furtherDescription,
      auditFinding: originalCarRow.portugueseTranslation.auditFinding,
    },
    originalCarId: originalCarRow.originalCarId,
    itemsWithSubSystems: originalCarRow.itemsWithSubSystems,
  });

  const [updatedCarRow, setUpdatedCarRow] = useState(unchangedCarRow);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();
  const [changeInSubSystems, setChangeInSubSystems] = useState(false);

  const defaultSelectedSubSystems: OptionType[] = [];
  unchangedCarRow.itemsWithSubSystems.forEach(subSystem => {
    const defaultLabelBlank = "";
    const defaultLabel = SubSystemDefaultLabelLogic(
      defaultLabelBlank,
      subSystem,
    );
    defaultSelectedSubSystems.push({
      value: subSystem.subSystemType,
      label: defaultLabel,
    });
  });

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    if (
      !originalCarRow.initialAgreedVerificationDate &&
      updatedCarRow.agreedVerificationDate
    ) {
      updatedCarRow.initialAgreedVerificationDate =
        updatedCarRow.agreedVerificationDate;
    }

    if (updatedCarRow.class === CarClass.comment) {
      updatedCarRow.carScore = 0;
    }
    if (updatedCarRow.class === CarClass.minor) {
      updatedCarRow.carScore = 1;
    }
    if (updatedCarRow.class === CarClass.major) {
      updatedCarRow.carScore = 5;
    }
    if (updatedCarRow.class === CarClass.critical) {
      updatedCarRow.carScore = 15;
    }

    if (changeInSubSystems === true) {
      const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
        selectedSubSystems,
      );

      event.preventDefault();
      handleRowEdited(
        updatedCarRow,
        selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    } else {
      const unchangedSubSystemsSubSystemTypeArray: SubSystemType[] = [];

      event.preventDefault();
      handleRowEdited(
        updatedCarRow,
        unchangedSubSystemsSubSystemTypeArray,
        changeInSubSystems,
      );
    }
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleRowDeleted(updatedCarRow);
  };

  const handleClassChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const carRow = { ...updatedCarRow };
    carRow.class = event.target.value as CarClass;
    setUpdatedCarRow(carRow);
  };

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const carRow = { ...updatedCarRow };
    carRow.status = event.target.value as CarStatus;
    setUpdatedCarRow(carRow);
  };

  const handleSystemAreasAffectedChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const carRow = { ...updatedCarRow };
    carRow.systemAreasAffected = event.target.value as SystemArea;
    setUpdatedCarRow(carRow);
  };

  const handleAgreedVerificationDateChange = (
    selectedDate: MaterialUiPickersDate,
  ) => {
    const carRow = { ...updatedCarRow };
    carRow.agreedVerificationDate = selectedDate ? selectedDate.toDate() : null;
    setUpdatedCarRow(carRow);
  };

  const handleActualVerificationDateChange = (
    selectedDate: MaterialUiPickersDate,
  ) => {
    const carRow = { ...updatedCarRow };
    carRow.actualVerificationDate = selectedDate ? selectedDate.toDate() : null;
    setUpdatedCarRow(carRow);
  };

  const handleAffectedSinceDateChange = (
    selectedDate: MaterialUiPickersDate,
  ) => {
    const carRow = { ...updatedCarRow };
    carRow.affectedSince = selectedDate ? selectedDate.toDate() : null;
    setUpdatedCarRow(carRow);
  };

  const handleCreatedAtDateChange = (selectedDate: Date) => {
    const carRow = { ...updatedCarRow };
    carRow.createdAt = selectedDate;
    setUpdatedCarRow(carRow);
  };

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
    setChangeInSubSystems(true);
  };

  const Option = CheckboxOption;

  return (
    <form onSubmit={handleSubmit} className={classes.formRoot}>
      <FormGroup>
        <Grid item xs={12}>
          <FormControl className={classes.textFormControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("description") + t("englishLanguageSuffix")}
              defaultValue={unchangedCarRow.englishTranslation.description}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedCarRow.englishTranslation.description =
                  event.target.value;
              }}
            />
          </FormControl>
          <FormControl className={classes.textFormControl}>
            <TextField
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("description") + t("portugueseLanguageSuffix")}
              defaultValue={unchangedCarRow.portugueseTranslation.description}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedCarRow.portugueseTranslation.description =
                  event.target.value;
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormGroup row className={classes.formRowSpacing}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="class-select-label">
                {t("class")}
              </InputLabel>
              <MaterialSelect
                value={updatedCarRow.class}
                onChange={handleClassChange}
              >
                <MenuItem value={CarClass.comment}>
                  {t("classComment")}
                </MenuItem>
                <MenuItem value={CarClass.minor}>{t("classMinor")}</MenuItem>
                <MenuItem value={CarClass.major}>{t("classMajor")}</MenuItem>
                <MenuItem value={CarClass.critical}>
                  {t("classCritical")}
                </MenuItem>
              </MaterialSelect>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="status-select-label">
                {t("status")}
              </InputLabel>
              <MaterialSelect
                value={updatedCarRow.status}
                onChange={handleStatusChange}
              >
                <MenuItem value={CarStatus.open}>{t("statusOpen")}</MenuItem>
                <MenuItem value={CarStatus.closed}>
                  {t("statusClosed")}
                </MenuItem>
              </MaterialSelect>
            </FormControl>
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <FormGroup row className={classes.formRowSpacing}>
            <FormControl className={classes.formControl}>
              <TextField
                label={t("componentsAffected")}
                defaultValue={unchangedCarRow.componentsAffected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updatedCarRow.componentsAffected = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id="areasAffected-select-label">
                {t("areasAffected")}
              </InputLabel>
              <MaterialSelect
                value={updatedCarRow.systemAreasAffected}
                onChange={handleSystemAreasAffectedChange}
              >
                <MenuItem value={SystemArea.chemicalsAndFuel}>
                  {t("fpsos:chemicalsAndFuel")}
                </MenuItem>
                <MenuItem value={SystemArea.electrical}>
                  {t("fpsos:electrical")}
                </MenuItem>
                <MenuItem value={SystemArea.hardware}>
                  {t("fpsos:hardware")}
                </MenuItem>
                <MenuItem value={SystemArea.instrument}>
                  {t("fpsos:instrument")}
                </MenuItem>
                <MenuItem value={SystemArea.liftingEquipment}>
                  {t("fpsos:liftingEquipment")}
                </MenuItem>
                <MenuItem value={SystemArea.mechanical}>
                  {t("fpsos:mechanical")}
                </MenuItem>
                <MenuItem value={SystemArea.other}>{t("fpsos:other")}</MenuItem>
                <MenuItem value={SystemArea.piping}>
                  {t("fpsos:piping")}
                </MenuItem>
                <MenuItem value={SystemArea.process}>
                  {t("fpsos:process")}
                </MenuItem>
                <MenuItem value={SystemArea.safety}>
                  {t("fpsos:safety")}
                </MenuItem>
                <MenuItem value={SystemArea.vessel}>
                  {t("fpsos:vessel")}
                </MenuItem>
                <MenuItem value={SystemArea.wasteManagement}>
                  {t("fpsos:wasteManagement")}
                </MenuItem>
                <MenuItem value={SystemArea.welfare}>
                  {t("fpsos:welfare")}
                </MenuItem>
              </MaterialSelect>
            </FormControl>
          </FormGroup>

          <FormGroup row className={classes.formRowSpacing}>
            <FormControl className={classes.formDateControl}>
              <DatePicker
                clearable={true}
                label={t("affectedSince")}
                value={updatedCarRow.affectedSince}
                placeholder={moment().format(DateConstants.DEFAULT_DATE_FORMAT)}
                onChange={selectedDate => {
                  handleAffectedSinceDateChange(selectedDate);
                }}
                format={DateConstants.DEFAULT_DATE_FORMAT}
              />
            </FormControl>
            <FormControl className={classes.formDateControl}>
              <DatePicker
                clearable={true}
                label={t("agreedVerificationDate")}
                value={updatedCarRow.agreedVerificationDate}
                placeholder={moment().format(DateConstants.DEFAULT_DATE_FORMAT)}
                onChange={selectedDate => {
                  handleAgreedVerificationDateChange(selectedDate);
                }}
                format={DateConstants.DEFAULT_DATE_FORMAT}
              />
            </FormControl>
            <FormControl className={classes.formDateControl}>
              <DatePicker
                clearable={true}
                label={t("actualVerificationDate")}
                value={updatedCarRow.actualVerificationDate}
                placeholder={moment().format(DateConstants.DEFAULT_DATE_FORMAT)}
                onChange={selectedDate => {
                  handleActualVerificationDateChange(selectedDate);
                }}
                format={DateConstants.DEFAULT_DATE_FORMAT}
              />
            </FormControl>
          </FormGroup>

          <FormGroup row className={classes.formRowSpacing}>
            <FormControl className={classes.formControl}>
              <Select
                onChange={option => handleChange(option)}
                options={subSystemOptions}
                isMulti
                autoFocus
                isSearchable
                isClearable
                styles={CustomStylesSubSystemOptions}
                placeholder={t("fpsos:helperTextEditSubSystems")}
                defaultValue={defaultSelectedSubSystems}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
              />
            </FormControl>
          </FormGroup>

          <FormGroup row className={classes.formControl}>
            {keycloak?.hasRealmRole(Roles.administrator) && (
              <FormControl className={classes.formControl}>
                <Typography variant={"body1"}>
                  {t("cars:adminDescriptionDateAdded")}
                </Typography>
                <DatePicker
                  clearable={false}
                  label={t("dateAdded")}
                  value={updatedCarRow.createdAt}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handleCreatedAtDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </FormControl>
            )}
          </FormGroup>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.languageTextFields}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("furtherDescription") + t("englishLanguageSuffix")}
              style={{ zIndex: 0 }}
              defaultValue={
                unchangedCarRow.englishTranslation.furtherDescription
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedCarRow.englishTranslation.furtherDescription =
                  event.target.value;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.languageTextFields}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("furtherDescription") + t("portugueseLanguageSuffix")}
              style={{ zIndex: 0 }}
              defaultValue={
                unchangedCarRow.portugueseTranslation.furtherDescription
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedCarRow.portugueseTranslation.furtherDescription =
                  event.target.value;
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.languageTextFields}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("auditFinding") + t("englishLanguageSuffix")}
              defaultValue={unchangedCarRow.englishTranslation.auditFinding}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedCarRow.englishTranslation.auditFinding =
                  event.target.value;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.languageTextFields}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              label={t("auditFinding") + t("portugueseLanguageSuffix")}
              defaultValue={unchangedCarRow.portugueseTranslation.auditFinding}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedCarRow.portugueseTranslation.auditFinding =
                  event.target.value;
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.bottomButtonRow}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
          <Button
            onClick={() => handleDeleteDialogOpen()}
            startIcon={<DeleteIcon />}
          >
            {t("delete")}
          </Button>
        </Grid>
        <ConfirmDialog
          dialogOpen={deleteDialogOpen}
          title={t("deleteCarTitle")}
          description={t("deleteCarDescription")}
          handleDialogClose={handleDeleteDialogClose}
          handleDialogConfirmClicked={handleDeleteClicked}
        />
      </FormGroup>
    </form>
  );
};
