import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import ValidationConstants from "Constants/ValidationConstants";
import { DocumentType } from "Enums/DocumentType";
import { Roles } from "Enums/Roles";
import { SystemArea } from "Enums/SystemArea";
import { FpsoAreaDocument } from "Models/FpsoAreaDocument";
import moment from "moment";
import React from "react";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "Views/Components/FileDropZone/FileDropZone";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  handleDocumentUpload(document: FpsoAreaDocument, file: File): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 124,
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
  }),
);

export const FpsoAreaDocumentUpload: FC<Props> = ({ handleDocumentUpload }) => {
  const classes = useStyles();
  const { t } = useTranslation("files");
  const { keycloak } = useKeycloak();

  const [newDocument, setNewDocument] = useState<FpsoAreaDocument>(
    {} as FpsoAreaDocument,
  );
  const [documentFile, setDocumentFile] = useState<File>();

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!newDocument.systemArea) {
      newDocument.systemArea = SystemArea.other;
    }
    if (!newDocument.type) {
      newDocument.type = DocumentType.certificate;
    }
    if (!newDocument.publishDate) {
      newDocument.publishDate = moment().toDate();
    }
    if (newDocument && documentFile) {
      handleDocumentUpload(newDocument, documentFile);
    }
  };

  const handleSystemAreaChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const document = { ...newDocument };
    document.systemArea = event.target.value as SystemArea;
    setNewDocument(document);
  };

  const handleDocumentTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const document = { ...newDocument };
    document.type = event.target.value as DocumentType;
    setNewDocument(document);
  };

  const handleFileSelected = (selectedFiles: File[]) => {
    setDocumentFile(selectedFiles[0]);
  };

  const handlePublishDateChange = (date: Date) => {
    const fpsoAreaDocument = { ...newDocument };
    fpsoAreaDocument.publishDate = date;
    setNewDocument(fpsoAreaDocument);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("title")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                label={t("documentNumber")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.documentNumber = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                label={t("name")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.name = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                label={t("revision")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.revision = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                multiline
                fullWidth
                rows="4"
                rowsMax="8"
                label={t("description")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.description = event.target.value;
                }}
              />
            </FormControl>
          </FormGroup>
          <FormGroup row>
            <FormControl required className={classes.formControl}>
              <InputLabel shrink id="documentType-select-label">
                {t("documentType")}
              </InputLabel>
              <Select
                value={newDocument.type || DocumentType.other}
                onChange={handleDocumentTypeChange}
              >
                <MenuItem value={DocumentType.other}>
                  {t("documentTypeOther")}
                </MenuItem>
                <MenuItem value={DocumentType.certificate}>
                  {t("documentTypeCertificate")}
                </MenuItem>
                <MenuItem value={DocumentType.procedure}>
                  {t("documentTypeProcedure")}
                </MenuItem>
                <MenuItem value={DocumentType.drawing}>
                  {t("documentTypeDrawing")}
                </MenuItem>
                <MenuItem value={DocumentType.report}>
                  {t("documentTypeReport")}
                </MenuItem>
                <MenuItem value={DocumentType.list}>
                  {t("documentTypeList")}
                </MenuItem>
                <MenuItem value={DocumentType.specification}>
                  {t("documentTypeSpecification")}
                </MenuItem>
                <MenuItem value={DocumentType.contractualDocument}>
                  {t("documentTypeContractualDocument")}
                </MenuItem>
                <MenuItem value={DocumentType.documentDataSheet}>
                  {t("documentTypeDocumentDataSheet")}
                </MenuItem>
                <MenuItem value={DocumentType.dataSheet}>
                  {t("documentTypeDataSheet")}
                </MenuItem>
                <MenuItem value={DocumentType.functionalDesign}>
                  {t("documentTypeFunctionalDesign")}
                </MenuItem>
                <MenuItem value={DocumentType.isometricDrawings}>
                  {t("documentTypeIsometricDrawings")}
                </MenuItem>
                <MenuItem value={DocumentType.inspectionDataSheet}>
                  {t("documentTypeInspectionDataSheet")}
                </MenuItem>
                <MenuItem value={DocumentType.minutesOfMeeting}>
                  {t("documentTypeMinutesOfMeeting")}
                </MenuItem>
                <MenuItem value={DocumentType.planningAndScheduling}>
                  {t("documentTypePlanningAndScheduling")}
                </MenuItem>
                <MenuItem value={DocumentType.processAndInstrumentDiagram}>
                  {t("documentTypeProcessAndInstrumentDiagram")}
                </MenuItem>
                <MenuItem value={DocumentType.technicalDataSheet}>
                  {t("documentTypeTechnicalDataSheet")}
                </MenuItem>
                <MenuItem value={DocumentType.technicalQuery}>
                  {t("documentTypeTechnicalQuery")}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl required className={classes.formControl}>
              <InputLabel shrink id="systemArea-select-label">
                {t("fpsos:systemArea")}
              </InputLabel>
              <Select
                value={newDocument.systemArea || SystemArea.other}
                onChange={handleSystemAreaChange}
              >
                <MenuItem value={SystemArea.other}>{t("fpsos:other")}</MenuItem>
                <MenuItem value={SystemArea.electrical}>
                  {t("fpsos:electrical")}
                </MenuItem>
                <MenuItem value={SystemArea.instrument}>
                  {t("fpsos:instrument")}
                </MenuItem>
                <MenuItem value={SystemArea.mechanical}>
                  {t("fpsos:mechanical")}
                </MenuItem>
                <MenuItem value={SystemArea.hardware}>
                  {t("fpsos:hardware")}
                </MenuItem>
                <MenuItem value={SystemArea.piping}>
                  {t("fpsos:piping")}
                </MenuItem>
                <MenuItem value={SystemArea.welfare}>
                  {t("fpsos:welfare")}
                </MenuItem>
                <MenuItem value={SystemArea.process}>
                  {t("fpsos:process")}
                </MenuItem>
                <MenuItem value={SystemArea.vessel}>
                  {t("fpsos:vessel")}
                </MenuItem>
                <MenuItem value={SystemArea.safety}>
                  {t("fpsos:safety")}
                </MenuItem>
                <MenuItem value={SystemArea.liftingEquipment}>
                  {t("fpsos:liftingEquipment")}
                </MenuItem>
                <MenuItem value={SystemArea.wasteManagement}>
                  {t("fpsos:wasteManagement")}
                </MenuItem>
                <MenuItem value={SystemArea.chemicalsAndFuel}>
                  {t("fpsos:chemicalsAndFuel")}
                </MenuItem>
              </Select>
            </FormControl>
            {keycloak?.hasRealmRole(Roles.administrator) && (
              <FormControl className={classes.formControl}>
                <Typography variant={"body1"}>
                  {t("files:adminDescriptionPublishDate")}
                </Typography>
                <DatePicker
                  clearable={false}
                  label={t("files:publishDate")}
                  value={newDocument.publishDate}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handlePublishDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </FormControl>
            )}
          </FormGroup>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
        </form>
      </Grid>
      <Grid item xs={4}>
        <FileDropZone
          handleFilesSelected={handleFileSelected}
          allowMultipleFiles={false}
          acceptedFileTypes={ValidationConstants.ACCEPTED_DOCUMENT_TYPES}
        />
      </Grid>
    </Grid>
  );
};
