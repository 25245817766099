export default class ElementSizes {
  public static readonly DRAWER_WIDTH = 256;
  public static readonly HOME_HEIGHT = 922;
  public static readonly LOGO_HEIGHT = 64;
  public static readonly FORM_TEXT_INPUT_WIDTH = 300;
  public static readonly COORDINATE_TEXT_INPUT_WIDTH = 100;
  public static readonly FORM_INPUT_WIDTH = 200;
  public static readonly SYSTEM_COMPONENT_EDIT_FORM_INPUT_WIDTH = 400;
  public static readonly FILE_UPLOAD_BORDER_WIDTH = 2;
  public static readonly FILE_UPLOAD_BORDER_RADIUS = 2;
  public static readonly OGC_LOGO_HEIGHT = 60;
  public static readonly FPSO_DESCRIPTION_READ_MORE_LENGTH = 2100;
}
