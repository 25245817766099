export default class DateConstants {
  public static readonly DEFAULT_DATE_FORMAT = "DD-MM-YYYY";
  public static readonly US_DATE_FORMAT = "MM-DD-YYYY";
  public static readonly TIME_DATE_FORMAT = "HH:mm, DD-MM-YYYY";
  public static readonly DATE_TIME_FORMAT = "DD-MM-YYYY, HH:mm";
  public static readonly AUDIT_TITLE_DATE_FORMAT = "MMM-YY";
  public static readonly REVERSE_DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
  public static readonly MINIMUM_DATE = new Date(1700, 1, 1);
  public static readonly MINIMUM_DATE_STRING = "1700-02-01";
}
