import {
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import { Roles } from "Enums/Roles";
import { VideoSessionEvidenceRow } from "Models/VideoSessionEvidenceRow";
import React from "react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditAuditInfoIcon } from "../Icons/EditAuditInfoIcon";
import { AuditVideoSessionEvidenceEdit } from "./AuditVideoSessionEvidenceEdit";

interface Props {
  readonly videoSessionEvidenceRow: VideoSessionEvidenceRow;
  handleRowEdited?(
    updatedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ): void;
  handleRowDeleted?(
    deletedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: "bold",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
  }),
);

export const AuditVideoSessionEvidenceDetails: FC<Props> = ({
  handleRowEdited: handleEditedRowReceived,
  handleRowDeleted,
  videoSessionEvidenceRow: originalVideoSessionEvidenceRow,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("audits");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleRowEdited = (
    updatedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ) => {
    setEditEnabled(false);
    if (handleEditedRowReceived) {
      handleEditedRowReceived(updatedVideoSessionEvidenceRow);
    }
  };

  return (
    <Grid container className={classes.root}>
      {editEnabled ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {t("editVideoSessionEvidenceTitle")}
          </Typography>
          <Tooltip title={t<string>("tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">{t("discardChanges")}</Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        (keycloak?.hasRealmRole(Roles.auditor) ||
          keycloak?.hasRealmRole(Roles.administrator)) && (
          <Grid item xs={12} className={classes.detailsButtonRow}>
            <Tooltip
              title={t<string>("tooltipEditVideoSessionEvidence")}
              arrow={true}
            >
              <Button onClick={handleEditClicked}>
                <EditAuditInfoIcon />
              </Button>
            </Tooltip>
          </Grid>
        )
      )}
      {editEnabled && handleRowDeleted && (
        <AuditVideoSessionEvidenceEdit
          videoSessionEvidenceRow={originalVideoSessionEvidenceRow}
          handleRowEdited={handleRowEdited}
          handleRowDeleted={handleRowDeleted}
        />
      )}
    </Grid>
  );
};
