import Colors from "Constants/Colors";

export const CustomStylesSubSystemOptions = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 10,
    backgroundColor: Colors.WHITE,
  }),

  control: (_: any, { selectProps: { width } }: any) => ({
    backgroundColor: Colors.WHITE,
    minWidth: 265,
    borderColor: Colors.BLACK,
    minHeight: 40,
    borderRadius: 4,
  }),

  singleValue: (provided: any, state: any) => {
    const transition = "opacity 300ms";
    return { ...provided, transition };
  },
};
