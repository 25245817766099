import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import { Roles } from "Enums/Roles";
import moment from "moment";
import { FC, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { StartANewAuditWhiteIcon } from "Views/Components/Icons/StartANewAuditWhiteIcon";

interface Props {
  createNewAudit(beginNewAuditDate: Date): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPicker: {
      justifyContent: "space-around",
    },
    dialogButtons: {
      justifyContent: "space-between",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
);

export const CreateAuditButton: FC<Props> = ({ createNewAudit }) => {
  const { t } = useTranslation("audits");
  const { keycloak } = useKeycloak();
  const classes = useStyles();

  const [newAuditDate, setNewAuditDate] = useState<Date>(new Date());

  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleNewAuditClick = () => {
    setOpen(false);
    if (newAuditDate === undefined) {
      setNewAuditDate(moment().toDate());
    }
    if (newAuditDate) {
      createNewAudit(newAuditDate);
    }
  };

  const handleCreatedAtDateChange = (date: Date) => {
    let auditDate = { ...newAuditDate };
    auditDate = date;
    setNewAuditDate(auditDate);
  };

  return (
    <>
      <Tooltip title={t<string>("audits:startNewAuditTooltip")} arrow={true}>
        <Button onClick={() => handleDialogOpen()}>
          <StartANewAuditWhiteIcon />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{t("audits:newAuditTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {t("audits:newAuditDescription")}
          </DialogContentText>
        </DialogContent>

        {keycloak?.hasRealmRole(Roles.administrator) && (
          <>
            <DialogContent>
              <DialogContentText id="dialog-description">
                {t("audits:createAuditAdminDescription")}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogPicker}>
              <DatePicker
                clearable={true}
                label={t("audits:newAuditDate")}
                value={newAuditDate}
                placeholder={moment().format(DateConstants.DEFAULT_DATE_FORMAT)}
                onChange={selectedDate => {
                  if (selectedDate) {
                    handleCreatedAtDateChange(selectedDate.toDate());
                  }
                }}
                format={DateConstants.DEFAULT_DATE_FORMAT}
              />
            </DialogActions>
          </>
        )}

        <DialogActions className={classes.dialogButtons}>
          <Button onClick={handleDialogClose} color="primary">
            {t("audits:newAuditCancel")}
          </Button>
          <Button onClick={handleNewAuditClick} color="primary" autoFocus>
            {t("audits:newAuditAccept")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
