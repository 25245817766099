import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  setCarAddFormEnabled(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      textTransform: "capitalize",
      color: "white",
    },
  }),
);

export const AddSystemComponentButton: FC<Props> = ({
  setCarAddFormEnabled,
}) => {
  const { t } = useTranslation("fpsos");
  const classes = useStyles();

  return (
    <>
      <Tooltip title={t<string>("addSystemComponentTooltip")} arrow={true}>
        <Button
          onClick={() => setCarAddFormEnabled()}
          className={classes.buttonText}
        >
          <AddCircleOutlineIcon style={{ fill: "white", fontSize: 28 }} />
          <Typography variant="body1">{t("addSystemComponent")}</Typography>
        </Button>
      </Tooltip>
    </>
  );
};
