import { SubSystemType } from "Enums/SubSystemType";
import { OptionType } from "Models/OptionType";
import { useTranslation } from "react-i18next";

export const SubSystemOptionsLogic = (
  fpsoAreaName: string | undefined,
  subSystemOptions: OptionType[],
) => {
  const { t } = useTranslation("fpsos");

  if (fpsoAreaName === "Process") {
    subSystemOptions = [
      {
        value: SubSystemType.gasDehydrationSystem,
        label: t("fpsos:gas_dehydration_system"),
      },
      {
        value: SubSystemType.sulfurRemovalSystem,
        label: t("fpsos:sulfur_removal_system"),
      },
      {
        value: SubSystemType.carbonDioxideRemovalSystem,
        label: t("fpsos:carbon_dioxide_removal_system"),
      },
      {
        value: SubSystemType.gasBTUReductionSystems,
        label: t("fpsos:gas_BTU_reduction_systems"),
      },
      {
        value: SubSystemType.gasCompression,
        label: t("fpsos:gas_compression"),
      },
      {
        value: SubSystemType.chemicalInjectionSystem,
        label: t("fpsos:chemical_injection_system"),
      },
      {
        value: SubSystemType.H2SRemovalSystem,
        label: t("fpsos:H2S_removal_system"),
      },
      { value: SubSystemType.flareSystem, label: t("fpsos:flare_system") },
      {
        value: SubSystemType.closedVentSystem,
        label: t("fpsos:closed_vent_system"),
      },
      {
        value: SubSystemType.openVentSystem,
        label: t("fpsos:open_vent_system"),
      },
      {
        value: SubSystemType.producedWaterTreatment,
        label: t("fpsos:produced_water_treatment"),
      },
      {
        value: SubSystemType.producedWaterDischargeDisposal,
        label: t("fpsos:produced_water_discharge_disposal"),
      },
      {
        value: SubSystemType.productTransferSystem,
        label: t("fpsos:product_transfer_system"),
      },
      {
        value: SubSystemType.productSeparationSystem,
        label: t("fpsos:product_separation_system"),
      },
    ];
  } else if (fpsoAreaName === "Power") {
    subSystemOptions = [
      {
        value: SubSystemType.powerGenerationSystem,
        label: t("fpsos:power_generation_system"),
      },
      {
        value: SubSystemType.powerDistributionSystem,
        label: t("fpsos:power_distribution_system"),
      },
      {
        value: SubSystemType.earthingSystem,
        label: t("fpsos:earthing_system"),
      },
      {
        value: SubSystemType.lightningSystem,
        label: t("fpsos:lightning_system"),
      },
    ];
  } else if (fpsoAreaName === "Utilities") {
    subSystemOptions = [
      {
        value: SubSystemType.freshWaterSystem,
        label: t("fpsos:fresh_water_system"),
      },
      {
        value: SubSystemType.washWaterSystem,
        label: t("fpsos:wash_water_system"),
      },
      {
        value: SubSystemType.nitrogenSystem,
        label: t("fpsos:nitrogen_system"),
      },
      {
        value: SubSystemType.instrumentAirSystem,
        label: t("fpsos:instrument_air_system"),
      },
      { value: SubSystemType.fuelGasSystem, label: t("fpsos:fuel_gas_system") },
      {
        value: SubSystemType.dieselFuelSystem,
        label: t("fpsos:diesel_fuel_system"),
      },
      {
        value: SubSystemType.closedDrainSystem,
        label: t("fpsos:closed_drain_system"),
      },
      {
        value: SubSystemType.openDrainSystem,
        label: t("fpsos:open_drain_system"),
      },
      { value: SubSystemType.HVACSystem, label: t("fpsos:HVAC_system") },
      { value: SubSystemType.heatingSystem, label: t("fpsos:heating_system") },
      { value: SubSystemType.coolingSystem, label: t("fpsos:cooling_system") },
      {
        value: SubSystemType.antiCorrosionSystem,
        label: t("fpsos:anti_corrosion_system"),
      },
    ];
  } else if (fpsoAreaName === "Control and Safety") {
    subSystemOptions = [
      { value: SubSystemType.safetySystem, label: t("fpsos:safety_system") },
      {
        value: SubSystemType.FAndGDetectionSystem,
        label: t("fpsos:F_and_G_detection_system"),
      },
      {
        value: SubSystemType.fireFightingSystem,
        label: t("fpsos:fire_fighting_system"),
      },
      {
        value: SubSystemType.processControlSystem,
        label: t("fpsos:process_control_system"),
      },
      {
        value: SubSystemType.radioAndCommunicationEquipment,
        label: t("fpsos:radio_and_communication_equipment"),
      },
    ];
  } else if (fpsoAreaName === "Marine") {
    subSystemOptions = [
      {
        value: SubSystemType.rescueEquipment,
        label: t("fpsos:rescue_equipment"),
      },
      { value: SubSystemType.mooringSystem, label: t("fpsos:mooring_system") },
      {
        value: SubSystemType.shipHullSystem,
        label: t("fpsos:ship_hull_system"),
      },
      {
        value: SubSystemType.transferTankerMooringSystem,
        label: t("fpsos:transfer_tanker_mooring_system"),
      },
      {
        value: SubSystemType.loadingCraneSystem,
        label: t("fpsos:loading_crane_system"),
      },
      { value: SubSystemType.ballastSystem, label: t("fpsos:ballast_system") },
      {
        value: SubSystemType.bilgePumpSystem,
        label: t("fpsos:bilge_pump_system"),
      },
    ];
  } else if (fpsoAreaName === "Hydrocarbon Export") {
    subSystemOptions = [
      {
        value: SubSystemType.oilExportSystem,
        label: t("fpsos:oil_export_system"),
      },
      {
        value: SubSystemType.gasExportSystem,
        label: t("fpsos:gas_export_system"),
      },
      {
        value: SubSystemType.productStorageSystem,
        label: t("fpsos:product_storage_system"),
      },
    ];
  } else if (fpsoAreaName === "Sub-Sea") {
    subSystemOptions = [
      { value: SubSystemType.oilWell, label: t("fpsos:oil_well") },
      { value: SubSystemType.gasWell, label: t("fpsos:gas_well") },
      { value: SubSystemType.waterWell, label: t("fpsos:water_well") },
      {
        value: SubSystemType.oilInjectionWell,
        label: t("fpsos:oil_injection_well"),
      },
      {
        value: SubSystemType.gasInjectionWell,
        label: t("fpsos:gas_injection_well"),
      },
      {
        value: SubSystemType.waterInjectionWell,
        label: t("fpsos:water_injection_well"),
      },
      {
        value: SubSystemType.waterInjection,
        label: t("fpsos:water_injection"),
      },
      {
        value: SubSystemType.productionWell,
        label: t("fpsos:production_well"),
      },
      { value: SubSystemType.liftGasSystem, label: t("fpsos:lift_gas_system") },
      {
        value: SubSystemType.seaWaterInjectionSystem,
        label: t("fpsos:sea_water_injection_system"),
      },
      {
        value: SubSystemType.processWaterInjectionSystem,
        label: t("fpsos:process_water_injection_system"),
      },
      {
        value: SubSystemType.gasInjectionSystem,
        label: t("fpsos:gas_injection_system"),
      },
      { value: SubSystemType.risers, label: t("fpsos:risers") },
    ];
  } else if (fpsoAreaName === "Equipment") {
    subSystemOptions = [
      { value: SubSystemType.equipmentA, label: t("fpsos:equipment_a") },
      { value: SubSystemType.equipmentB, label: t("fpsos:equipment_b") },
      { value: SubSystemType.equipmentC, label: t("fpsos:equipment_c") },
    ];
  } else if (fpsoAreaName === "Management") {
    subSystemOptions = [
      { value: SubSystemType.offshore, label: t("fpsos:offshore") },
      { value: SubSystemType.onshore, label: t("fpsos:onshore") },
    ];
  }

  return subSystemOptions;
};
