import React, { FC } from "react";

export const WordMinutesDownloadWhiteIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g>
        <path
          fill="#FFFFFF"
          d="M51.39,13.84v-0.02c0-0.04-0.02-0.08-0.02-0.12c-0.01-0.08-0.02-0.17-0.05-0.25
		c-0.03-0.07-0.07-0.12-0.12-0.19c-0.03-0.04-0.05-0.09-0.08-0.13L41.58,3.1c0,0-0.01,0-0.01-0.01c-0.06-0.07-0.14-0.11-0.22-0.16
		c-0.03-0.02-0.06-0.05-0.1-0.07c-0.04-0.02-0.09-0.02-0.13-0.03c-0.08-0.02-0.16-0.05-0.25-0.05c0,0-0.01,0-0.01,0H19.29
		c-1.68,0-3.04,1.36-3.04,3.04v41.81c0,1.68,1.36,3.04,3.04,3.04h7.88l-0.99-0.99c-0.3-0.3-0.52-0.64-0.66-1.01h-6.23
		c-0.57,0-1.04-0.47-1.04-1.04V5.83c0-0.57,0.47-1.04,1.04-1.04h20.57v9.02c0,0.55,0.45,1,1,1h8.53v32.83
		c0,0.57-0.47,1.04-1.04,1.04h-6.23c-0.15,0.37-0.37,0.71-0.66,1.01l-0.99,0.99h7.88c1.68,0,3.04-1.36,3.04-3.04V13.84
		C51.39,13.84,51.39,13.84,51.39,13.84z M41.86,6.29l6.2,6.52h-6.2V6.29z"
        />
        <path
          fill="#FFFFFF"
          d="M38.63,46.87l-3.81,3.87v-9.11c0-0.55-0.45-1-1-1s-1,0.45-1,1v9.05l-3.81-3.81c-0.39-0.39-1.02-0.39-1.41,0
		s-0.39,1.02,0,1.41l4.85,4.85c0.38,0.38,0.88,0.57,1.38,0.57s1-0.19,1.38-0.57l4.85-4.85c0.39-0.39,0.39-1.02,0-1.41
		S39.02,46.48,38.63,46.87z"
        />
        <path
          fill="#FFFFFF"
          d="M39.34,55.15H28.3c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.03c0.55,0,1-0.45,1-1S39.89,55.15,39.34,55.15z"
        />
      </g>
      <path fill="#FFFFFF" d="M50.39,13.82" />
      <g>
        <path
          fill="#1D70B7"
          d="M22.06,45.16H9.26c-0.62,0-1.13-0.51-1.13-1.13v-12.8c0-0.62,0.51-1.13,1.13-1.13h12.8
		c0.62,0,1.13,0.51,1.13,1.13v12.8C23.19,44.65,22.68,45.16,22.06,45.16z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M12.47,32.43l1.14,7.6h0.03l1.21-7.6h1.99l1.21,7.6h0.03l1.14-7.6h1.99l-1.87,10.27h-2.32l-1.15-7.45h-0.03
			l-1.15,7.45h-2.32l-1.87-10.27H12.47z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M23.56,17.37l1.47,7.25h0.03l1.48-7.25h3.06v10.27h-1.9v-8.2h-0.03l-1.83,8.2h-1.61l-1.83-8.2h-0.03v8.2h-1.9
		V17.37H23.56z"
        />
        <path
          fill="#FFFFFF"
          d="M30.93,22.01c0.11-0.51,0.28-0.93,0.52-1.27c0.24-0.34,0.55-0.6,0.94-0.78c0.39-0.18,0.87-0.27,1.43-0.27
		c0.59-0.01,1.09,0.07,1.48,0.24c0.39,0.17,0.71,0.42,0.94,0.77c0.23,0.35,0.39,0.77,0.49,1.28c0.1,0.51,0.14,1.1,0.14,1.78
		c0,0.66-0.05,1.25-0.16,1.76c-0.11,0.51-0.28,0.93-0.52,1.27c-0.24,0.34-0.55,0.59-0.94,0.76s-0.87,0.24-1.43,0.24
		c-0.59,0.01-1.09-0.06-1.48-0.22c-0.39-0.16-0.71-0.41-0.94-0.75s-0.39-0.77-0.49-1.28s-0.14-1.11-0.14-1.78
		C30.77,23.11,30.82,22.52,30.93,22.01z M32.78,24.86c0.02,0.34,0.06,0.62,0.14,0.86c0.07,0.24,0.18,0.43,0.32,0.56
		c0.14,0.13,0.34,0.2,0.58,0.2c0.4,0,0.68-0.21,0.83-0.63c0.15-0.42,0.23-1.12,0.23-2.09c0-0.97-0.08-1.66-0.23-2.09
		c-0.15-0.42-0.43-0.63-0.83-0.63c-0.24,0-0.43,0.07-0.58,0.2c-0.14,0.13-0.25,0.32-0.32,0.56s-0.12,0.53-0.14,0.86
		c-0.02,0.34-0.03,0.7-0.03,1.09C32.76,24.16,32.76,24.53,32.78,24.86z"
        />
        <path
          fill="#FFFFFF"
          d="M41.12,17.37l1.47,7.25h0.03l1.48-7.25h3.06v10.27h-1.9v-8.2h-0.03l-1.83,8.2h-1.61l-1.83-8.2h-0.03v8.2h-1.9
		V17.37H41.12z"
        />
      </g>
    </svg>
  );
};
