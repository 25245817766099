import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import { Roles } from "Enums/Roles";
import { CarEvidenceDocument } from "Models/CarEvidenceDocument";
import { Comment } from "Models/Comment";
import { InitializedComment } from "Models/InitialComment";
import moment from "moment";
import React, { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { CarEvidenceCommentAdd } from "Views/Components/CarEvidenceDocuments/CarEvidenceCommentAdd";
import { CarEvidenceCommentEdit } from "Views/Components/CarEvidenceDocuments/CarEvidenceCommentEdit";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";

interface Props {
  readonly carEvidenceDocument: CarEvidenceDocument;
  handleCommentAdded(newComment: InitializedComment): void;
  handleCommentEdited(editedComment: Comment): void;
  handleCommentDeleted(deletedComment: Comment): void;
  readonly isActive: boolean;
  readonly isLocked: boolean;
  readonly isArchived: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      width: "100%",
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
    listSubHeaderRoot: {
      fontWeight: "bold",
      display: "flex",
      color: Colors.BLACK,
    },
    buttonText: {
      textTransform: "capitalize",
    },
    commentSubheading: {
      width: "100%",
    },
  }),
);

export const CarEvidenceComments: FC<Props> = ({
  carEvidenceDocument,
  handleCommentAdded,
  handleCommentEdited: handleEditedCommentReceived,
  handleCommentDeleted: handleDeletedCommentReceived,
  isActive,
  isLocked,
  isArchived,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("cars");
  const { keycloak } = useKeycloak();

  const [addFormEnabled, setAddFormEnabled] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [commentToBeEdited, setCommentToBeEdited] = useState<string>();

  const handleAddClicked = () => {
    setAddFormEnabled(!addFormEnabled);
  };

  const handleCommentAdd = (newComment: InitializedComment) => {
    setAddFormEnabled(false);
    handleCommentAdded(newComment);
  };

  const handleCommentEdited = (updatedComment: Comment) => {
    setEditEnabled(false);
    if (handleEditedCommentReceived) {
      handleEditedCommentReceived(updatedComment);
    }
  };

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleCommentDeleted = (deletedComment: Comment) => {
    setEditEnabled(false);
    if (handleDeletedCommentReceived) {
      handleDeletedCommentReceived(deletedComment);
    }
  };

  return (
    <>
      <div className={classes.root}>
        {addFormEnabled ? (
          <Grid item xs={12} className={classes.addButtonRow}>
            <Tooltip title={t<string>("cars:tooltipDiscard")} arrow={true}>
              <IconButton onClick={handleAddClicked}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : (
          !isArchived &&
          isActive &&
          isLocked === false && (
            <Grid item xs={12} className={classes.addButtonRow}>
              <Button onClick={handleAddClicked} className={classes.buttonText}>
                <AddCircleOutlineIcon style={{ fontSize: 28 }} />
                <Typography variant="body1">{t("addComment")}</Typography>
              </Button>
            </Grid>
          )
        )}
        <List
          className={classes.commentSubheading}
          subheader={
            <ListSubheader className={classes.listSubHeaderRoot}>
              {t("evidenceComments")}{" "}
            </ListSubheader>
          }
        >
          {addFormEnabled && !isArchived && isActive && (
            <CarEvidenceCommentAdd
              handleAddComment={handleCommentAdd}
              carEvidenceDocument={carEvidenceDocument}
            />
          )}
          {carEvidenceDocument.comments.map(comment =>
            (comment.commenter.id === keycloak?.idTokenParsed?.sub ||
              keycloak?.hasRealmRole(Roles.administrator)) &&
            editEnabled &&
            commentToBeEdited === comment.id &&
            !isArchived &&
            isActive ? (
              <Grid container>
                <Grid item xs={12} className={classes.editButtonRow}>
                  <Tooltip
                    title={t<string>("cars:tooltipDiscard")}
                    arrow={true}
                  >
                    <IconButton onClick={handleEditClicked}>
                      <Typography variant="body1">
                        {t("cars:discardChanges")}
                      </Typography>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <CarEvidenceCommentEdit
                    handleCommentEdited={handleCommentEdited}
                    handleCommentDeleted={handleCommentDeleted}
                    carEvidenceComment={comment}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <ListItem>
                  <ListItemText
                    primary={comment.commenter.fullName}
                    secondary={
                      <Fragment>
                        <Typography
                          variant="subtitle2"
                          className={classes.commentSubheading}
                        >
                          {t("postedOn")}{" "}
                          {comment.createdAt
                            ? moment(comment.createdAt).format(
                                DateConstants.DEFAULT_DATE_FORMAT,
                              )
                            : t("notAvailable")}
                          {", "}
                          {t("lastUpdated")}{" "}
                          {comment.updatedAt
                            ? moment(comment.updatedAt).format(
                                DateConstants.TIME_DATE_FORMAT,
                              )
                            : t("notAvailable")}
                        </Typography>
                        {comment.content}
                      </Fragment>
                    }
                  />
                  {(comment.commenter.id === keycloak?.idTokenParsed?.sub ||
                    keycloak?.hasRealmRole(Roles.administrator)) &&
                    !isArchived &&
                    isActive && (
                      <Grid item className={classes.detailsButtonRow}>
                        <Tooltip
                          title={t<string>(
                            "cars:editCarEvidenceCommentTooltip",
                          )}
                          arrow={true}
                        >
                          <Button
                            onClick={() => {
                              setCommentToBeEdited(comment.id);
                              handleEditClicked();
                            }}
                          >
                            <EditAuditInfoIcon />
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                </ListItem>
              </>
            ),
          )}
        </List>
      </div>
    </>
  );
};
