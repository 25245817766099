import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ElementSizes from "Constants/ElementSizes";
import { VideoSessionEvidenceRow } from "Models/VideoSessionEvidenceRow";
import React, { SyntheticEvent, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { SaveIcon } from "../Icons/SaveIcon";

interface Props {
  readonly videoSessionEvidenceRow: VideoSessionEvidenceRow;
  handleRowEdited(
    updatedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ): void;
  handleRowDeleted(
    deletedVideoSessionEvidenceRow: VideoSessionEvidenceRow,
  ): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      width: "100%",
      paddingRight: theme.spacing(6),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    formRowSpacing: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    bottomButtonRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    languageTextFields: {
      margin: theme.spacing(1),
    },
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: "bold",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
  }),
);

export const AuditVideoSessionEvidenceEdit: FC<Props> = ({
  videoSessionEvidenceRow: originalVideoSessionEvidenceRow,
  handleRowEdited,
  handleRowDeleted,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("audits");

  const [unchangedVideoSessionEvidenceRow] = useState<VideoSessionEvidenceRow>({
    id: originalVideoSessionEvidenceRow.id,
    createdAt: originalVideoSessionEvidenceRow.createdAt,
    description: originalVideoSessionEvidenceRow.description,
    evidenceReference: originalVideoSessionEvidenceRow.evidenceReference,
    updatedAt: originalVideoSessionEvidenceRow.updatedAt,
    submitterName: originalVideoSessionEvidenceRow.submitterName,
  });

  const [updatedVideoSessionEvidenceRow] = useState(
    unchangedVideoSessionEvidenceRow,
  );
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    handleRowEdited(updatedVideoSessionEvidenceRow);
  };

  const handleDeleteClicked = () => {
    setDeleteDialogOpen(false);
    handleRowDeleted(updatedVideoSessionEvidenceRow);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.formRoot}>
      <FormGroup>
        <Grid item xs={12}>
          <FormControl className={classes.textFormControl}>
            <TextField
              label={t("description")}
              multiline
              fullWidth
              rows="4"
              rowsMax="8"
              variant="outlined"
              defaultValue={unchangedVideoSessionEvidenceRow.description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updatedVideoSessionEvidenceRow.description = event.target.value;
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.bottomButtonRow}>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
          <Button
            onClick={() => handleDeleteDialogOpen()}
            startIcon={<DeleteIcon />}
          >
            {t("delete")}
          </Button>
        </Grid>
        <ConfirmDialog
          dialogOpen={deleteDialogOpen}
          title={t("deleteVideoSessionEvidence")}
          description={t("deleteVideoSessionEvidenceDescription")}
          handleDialogClose={handleDeleteDialogClose}
          handleDialogConfirmClicked={handleDeleteClicked}
        />
      </FormGroup>
    </form>
  );
};
