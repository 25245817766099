import React, { FC } from "react";

export const UploadCloudWhiteIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g>
        <path
          fill="#FFFFFF"
          d="M49.22,23.11c-1.57-3.72-5.22-6.18-9.3-6.18c-1.83,0-3.63,0.5-5.19,1.44c-2.06-5.11-7.09-8.57-12.67-8.57
		c-7.53,0-13.66,6.12-13.66,13.65c-4.29,1.22-7.33,5.17-7.33,9.71c0,5.57,4.53,10.1,10.1,10.1h11.48c0.41,0,0.77-0.25,0.92-0.61
		c-0.53-0.12-1.04-0.38-1.45-0.79c-0.18-0.18-0.33-0.39-0.45-0.6h-10.5c-4.46,0-8.1-3.63-8.1-8.1c0-3.87,2.76-7.21,6.56-7.94
		c0.49-0.1,0.84-0.54,0.81-1.04l-0.01-0.2c-0.01-0.17-0.02-0.34-0.02-0.51c0-6.43,5.23-11.66,11.66-11.66
		c5.16,0,9.76,3.46,11.19,8.42c0.09,0.32,0.34,0.58,0.66,0.68c0.32,0.1,0.67,0.04,0.93-0.18c1.45-1.17,3.21-1.79,5.07-1.79
		c3.45,0,6.53,2.2,7.65,5.47c0.14,0.4,0.51,0.67,0.93,0.67c4.38,0.08,7.95,3.71,7.95,8.09c0,4.46-3.63,8.1-8.1,8.1h-10.5
		c-0.13,0.21-0.27,0.41-0.45,0.6c-0.31,0.31-0.68,0.54-1.07,0.68c0.12,0.41,0.49,0.72,0.94,0.72h11.08c5.57,0,10.1-4.53,10.1-10.1
		C58.44,27.93,54.36,23.56,49.22,23.11z"
        />
        <path
          fill="#FFFFFF"
          d="M31.13,34.18c-0.76-0.76-1.99-0.76-2.75,0l-4.85,4.85c-0.39,0.39-0.39,1.02,0,1.41s1.02,0.39,1.41,0l3.81-3.87
		v9.11c0,0.55,0.45,1,1,1s1-0.45,1-1v-9.05l3.81,3.81c0.2,0.2,0.45,0.29,0.71,0.29s0.51-0.1,0.71-0.29c0.39-0.39,0.39-1.02,0-1.41
		L31.13,34.18z"
        />
        <path
          fill="#FFFFFF"
          d="M35.27,48.13H24.24c-0.55,0-1,0.45-1,1s0.45,1,1,1h11.03c0.55,0,1-0.45,1-1S35.83,48.13,35.27,48.13z"
        />
      </g>
    </svg>
  );
};
