import React, { FC } from "react";

export const AngolianFlagIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900 600"
      width="24"
      height="16"
    >
      <path d="M0 0h900v600H0z" />
      <path fill="#cc092f" d="M0 0h900v300H0z" />
      <path
        d="M390.858 205.797h36.644l11.112-34.537 11.41 34.537h36.64l-29.433 21.926 11.409 35.135-30.026-21.324-29.731 21.324 11.41-35.135z"
        fill="#ffcb00"
        fillRule="evenodd"
      />
      <path
        d="M524.801 394.706l-28.827-22.53c29.127-23.724 48.052-60.064 48.052-100.605 0-61.866-44.144-113.824-102.411-126.137l4.504-21.025c8.11 1.803 17.624 5.217 25.432 7.916l7.51-12.608c8.1 3.9 17.118 7.803 24.621 12.608l-5.703 13.518c7.51 4.808 16.575 12.251 21.616 18.112l11.277-8.809c6.008 6.604 13.218 13.507 18.323 20.721l-10.211 10.512c6.594 9.68 9.143 14.135 14.004 25.287l14.327-3.36c2.706 8.408 6.814 18.702 8.32 27.408l-13.566 5.332c1.62 6.766 3.718 18.584 3.583 28.525-.011.598-.056 1.276-.056 1.878l14.167 2.021c-.6 8.712-.897 18.625-2.704 27.333h-14.714c-2.1 9.005-4.81 20.02-8.11 28.425l12.308 7.213c-3.901 7.805-7.8 17.117-12.908 24.626l-13.514-5.703c-5.11 7.505-10.81 14.71-17.118 21.321l8.706 11.41c-2.398 2.1-4.502 4.205-6.908 6.61m-63.362-2.704l21.772 13.12c-2.03 1.857-8.112 5.006-9.724 5.644l3.56 13.666c-8.407 2.708-17.42 6.608-26.724 8.412l-5.405-13.513c-8.71 1.8-17.416 3-26.427 3l-1.807 14.114c-8.406-.598-18.617 0-27.928-2.102v-14.11c-8.411-1.506-16.519-3.606-24.324-6.312l-6.91 12.322c-7.207-3.01-16.816-6.615-24.628-11.416l5.108-13.514c-6.31-3.307-12.015-7.509-17.42-11.72l13.515-17.118c22.226 17.722 50.153 28.228 80.787 28.228 16.221 0 32.132-2.697 46.555-8.7"
        fill="#ffcb00"
        fillRule="evenodd"
      />
      <path
        d="M552.192 426.811L408.462 317.43l-1.31 1.866 143.36 109.573-8.509 10.647-139.753-89.64c-37.839-23.726-36.337-42.344-28.528-62.466l6.608-16.217c3.605 21.023 25.223 36.944 47.748 54.658l126.914 97.497zm36.084 32.6a2.23 2.23 0 0 1-2.23-2.238 2.23 2.23 0 0 1 2.23-2.232c1.237 0 2.24 1 2.24 2.232a2.238 2.238 0 0 1-2.24 2.239m-8.59-6.308a2.24 2.24 0 0 1-2.237-2.235 2.236 2.236 0 1 1 2.237 2.235m-8.79-7.006c-1.232 0-2.236-1-2.236-2.231a2.236 2.236 0 0 1 4.47 0c0 1.231-1 2.231-2.234 2.231m29.554 12.4l-43.065-33.365-12.514 16.127 37.41 26.235c3.005 2.1 3.3 11.718 14.407 13.222 4.513.596 7.508-2.71 7.508-2.71 4.84-5.892 3.521-14.003-3.746-19.51"
        fill="#ffcb00"
        fillRule="evenodd"
      />
    </svg>
  );
};
