import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import ElementSizes from "Constants/ElementSizes";
import { SystemArea } from "Enums/SystemArea";
import { InitializedFpsoAreaSystemComponent } from "Models/InitialFpsoAreaSystemComponent";
import React from "react";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SaveIcon } from "../../Icons/SaveIcon";

interface Props {
  handleAddFpsoAreaComponent(
    fpsoAreaComponent: InitializedFpsoAreaSystemComponent,
  ): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.SYSTEM_COMPONENT_EDIT_FORM_INPUT_WIDTH,
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    formRowSpacing: {
      margin: theme.spacing(1),
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    languageTextFields: {
      margin: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
    saveButtonRow: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
);

export const FpsoAreaSystemComponentAdd: FC<Props> = ({
  handleAddFpsoAreaComponent,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");

  const [
    initializedFpsoAreaSystemComponent,
    setInitializedFpsoAreaComponent,
  ] = useState<InitializedFpsoAreaSystemComponent>(
    new InitializedFpsoAreaSystemComponent(),
  );

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (initializedFpsoAreaSystemComponent) {
      handleAddFpsoAreaComponent(initializedFpsoAreaSystemComponent);
    }
  };

  const handleSystemAreaChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const fpsoAreaSystemComponent = { ...initializedFpsoAreaSystemComponent };
    fpsoAreaSystemComponent.systemArea = event.target.value as SystemArea;

    setInitializedFpsoAreaComponent(fpsoAreaSystemComponent);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("addFpsoAreaComponentTitle")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <FormGroup row className={classes.formRowSpacing}>
            <FormControl className={classes.formControl}>
              <TextField
                label={t("componentName")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedFpsoAreaSystemComponent.componentName =
                    event.target.value;
                }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel shrink id="class-select-label">
                {t("systemArea")}
              </InputLabel>
              <Select
                value={initializedFpsoAreaSystemComponent.systemArea}
                onChange={handleSystemAreaChange}
              >
                <MenuItem value={SystemArea.chemicalsAndFuel}>
                  {t("chemicalsAndFuel")}
                </MenuItem>
                <MenuItem value={SystemArea.electrical}>
                  {t("electrical")}
                </MenuItem>
                <MenuItem value={SystemArea.hardware}>{t("hardware")}</MenuItem>
                <MenuItem value={SystemArea.instrument}>
                  {t("instrument")}
                </MenuItem>
                <MenuItem value={SystemArea.liftingEquipment}>
                  {t("liftingEquipment")}
                </MenuItem>
                <MenuItem value={SystemArea.mechanical}>
                  {t("mechanical")}
                </MenuItem>
                <MenuItem value={SystemArea.other}>{t("other")}</MenuItem>
                <MenuItem value={SystemArea.piping}>{t("piping")}</MenuItem>
                <MenuItem value={SystemArea.process}>{t("process")}</MenuItem>
                <MenuItem value={SystemArea.safety}>{t("safety")}</MenuItem>
                <MenuItem value={SystemArea.vessel}>{t("vessel")}</MenuItem>
                <MenuItem value={SystemArea.wasteManagement}>
                  {t("wasteManagement")}
                </MenuItem>
                <MenuItem value={SystemArea.welfare}>{t("welfare")}</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label={t("quantity")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedFpsoAreaSystemComponent.quantity =
                    event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label={t("type")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedFpsoAreaSystemComponent.type = event.target.value;
                }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                label={t("manufacturer")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedFpsoAreaSystemComponent.manufacturer =
                    event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label={t("modelNo")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedFpsoAreaSystemComponent.modelNo =
                    event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label={t("serialNo")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  initializedFpsoAreaSystemComponent.serialNo =
                    event.target.value;
                }}
              />
            </FormControl>
          </FormGroup>

          <FormGroup row>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  className={classes.languageTextFields}
                  multiline
                  fullWidth
                  rows="4"
                  rowsMax="8"
                  variant="outlined"
                  label={t("description")}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    initializedFpsoAreaSystemComponent.description =
                      event.target.value;
                  }}
                />
              </Grid>
            </Grid>
          </FormGroup>

          <FormGroup row>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  className={classes.languageTextFields}
                  multiline
                  fullWidth
                  rows="4"
                  rowsMax="8"
                  variant="outlined"
                  label={t("comment")}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                    initializedFpsoAreaSystemComponent.comment =
                      event.target.value;
                  }}
                />
              </Grid>
            </Grid>
          </FormGroup>

          <Grid item xs={12} className={classes.saveButtonRow}>
            <Button type="submit" startIcon={<SaveIcon />}>
              {t("save")}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
