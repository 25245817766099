import {
  Button,
  createStyles,
  FormControl,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import ElementSizes from "Constants/ElementSizes";
import ValidationConstants from "Constants/ValidationConstants";
import { Roles } from "Enums/Roles";
import { CarEvidenceDocument } from "Models/CarEvidenceDocument";
import moment from "moment";
import React from "react";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileDropZone } from "Views/Components/FileDropZone/FileDropZone";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  handleDocumentUpload(document: CarEvidenceDocument, file: File): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    textFormControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_TEXT_INPUT_WIDTH,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: ElementSizes.FORM_INPUT_WIDTH,
    },
  }),
);

export const CarEvidenceDocumentUpload: FC<Props> = ({
  handleDocumentUpload,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("files");
  const { keycloak } = useKeycloak();

  const [newDocument, setNewDocument] = useState<CarEvidenceDocument>(
    {} as CarEvidenceDocument,
  );
  const [file, setFile] = useState<File>();

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    if (newDocument.createdAt === undefined) {
      newDocument.createdAt = moment().toDate();
    }
    if (newDocument && file) {
      handleDocumentUpload(newDocument, file);
    }
  };

  const handleFileSelected = (selectedFiles: File[]) => {
    setFile(selectedFiles[0]);
  };

  const handleCreatedAtDateChange = (date: Date) => {
    const carEvidenceDocument = { ...newDocument };
    carEvidenceDocument.createdAt = date;
    setNewDocument(carEvidenceDocument);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h6">{t("title")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                label={t("documentNumber")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.documentNumber = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                label={t("name")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.evidenceDocumentName = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                label={t("revision")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.revision = event.target.value;
                }}
              />
            </FormControl>
            <FormControl className={classes.textFormControl}>
              <TextField
                required
                multiline
                fullWidth
                rows="4"
                rowsMax="8"
                label={t("description")}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  newDocument.documentDescription = event.target.value;
                }}
              />
            </FormControl>
            {keycloak?.hasRealmRole(Roles.administrator) && (
              <FormControl className={classes.formControl}>
                <Typography variant={"body1"}>
                  {t("cars:adminDescriptionDateAdded")}
                </Typography>
                <DatePicker
                  clearable={false}
                  label={t("cars:dateAdded")}
                  value={newDocument.createdAt}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handleCreatedAtDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </FormControl>
            )}
          </FormGroup>
          <Button type="submit" startIcon={<SaveIcon />}>
            {t("save")}
          </Button>
        </form>
      </Grid>
      <Grid item xs={6}>
        {
          <FileDropZone
            handleFilesSelected={handleFileSelected}
            allowMultipleFiles={false}
            acceptedFileTypes={ValidationConstants.ACCEPTED_DOCUMENT_TYPES}
          />
        }
      </Grid>
    </Grid>
  );
};
