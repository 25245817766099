import { createStyles, makeStyles, Theme } from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import PublishIcon from "@material-ui/icons/Publish";
import Colors from "Constants/Colors";
import ElementSizes from "Constants/ElementSizes";
import ValidationConstants from "Constants/ValidationConstants";
import React, { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface Props {
  handleFilesSelected(file: File[]): void;
  allowMultipleFiles: boolean;
  acceptedFileTypes: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2),
      borderWidth: ElementSizes.FILE_UPLOAD_BORDER_WIDTH,
      borderRadius: ElementSizes.FILE_UPLOAD_BORDER_RADIUS,
      borderColor: Colors.FILE_UPLOAD_BORDER,
      borderStyle: "dashed",
      backgroundColor: Colors.FILE_UPLOAD_BACKGROUND,
      color: Colors.FILE_UPLOAD_TEXT,
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
  }),
);

export const FileDropZone: FC<Props> = ({
  handleFilesSelected: handleFileSelected,
  allowMultipleFiles,
  acceptedFileTypes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("files");

  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDropAccepted: files => {
      setFileSizeError(false);
      setFileTypeError(false);
      onFileDrop(files);
    },
    onDropRejected: files => {
      files.forEach(file => {
        if (file.size > ValidationConstants.MAX_FILE_SIZE) {
          setFileSizeError(true);
          setFileTypeError(false);
        } else {
          setFileSizeError(false);
          setFileTypeError(true);
        }
      });
    },
    multiple: allowMultipleFiles,
    accept: acceptedFileTypes,
    maxSize: ValidationConstants.MAX_FILE_SIZE,
  });
  const { ref, ...rootProps } = getRootProps();

  const onFileDrop = (files: File[]) => {
    handleFileSelected(files);
  };

  return (
    <div>
      <RootRef rootRef={ref}>
        <div {...rootProps} className={classes.dropzone}>
          <input {...getInputProps()} />
          <div>{t("hint")}</div>
          <PublishIcon />
        </div>
      </RootRef>
      {fileSizeError && (
        <div className={classes.errorMessage}>{t("errorFileSize")}</div>
      )}
      {fileTypeError && (
        <div className={classes.errorMessage}>{t("errorFileType")}</div>
      )}
      {acceptedFiles.map(file => (
        <div key={file.name}>
          {file.name} -{" "}
          {file.size > ValidationConstants.MEGABYTE_IN_BYTES
            ? `${(
                file.size * ValidationConstants.BYTES_TO_MEGABYTES_FACTOR
              ).toFixed(2)} MB`
            : `${(
                file.size * ValidationConstants.BYTES_TO_KILOBYTES_FACTOR
              ).toFixed(2)} KB`}
        </div>
      ))}
    </div>
  );
};
