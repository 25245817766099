import React, { FC } from "react";

export const CarDetailsIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <path
        fill="#575756"
        d="M47.3,16.92c-0.01-0.08-0.02-0.16-0.05-0.24c-0.03-0.08-0.08-0.14-0.13-0.2c-0.03-0.04-0.04-0.08-0.07-0.11
   L37.52,6.33c0,0-0.01,0-0.01-0.01c-0.06-0.06-0.14-0.11-0.22-0.15c-0.03-0.02-0.06-0.05-0.1-0.07c-0.03-0.01-0.07-0.01-0.1-0.02
   C37,6.05,36.9,6.03,36.81,6.02c0,0-0.01,0-0.01,0H15.23c-1.68,0-3.04,1.36-3.04,3.04v41.81c0,1.68,1.36,3.04,3.04,3.04h29.07
   c1.68,0,3.04-1.36,3.04-3.04V17.05C47.33,17,47.31,16.96,47.3,16.92z M37.8,9.52l6.2,6.52h-6.2V9.52z M45.33,50.87
   c0,0.57-0.47,1.04-1.04,1.04H15.23c-0.57,0-1.04-0.47-1.04-1.04V9.06c0-0.57,0.47-1.04,1.04-1.04H35.8v9.02c0,0.55,0.45,1,1,1h8.53
   V50.87z"
      />
      <path
        fill="#575756"
        d="M42.77,29.25h-2.01c-0.48-5.3-4.71-9.53-10.01-10.01v-2.01c0-0.55-0.45-1-1-1s-1,0.45-1,1v2.01
   c-5.3,0.48-9.53,4.71-10.01,10.01h-2.01c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.01c0.48,5.3,4.71,9.53,10.01,10.01v2.01c0,0.55,0.45,1,1,1
   s1-0.45,1-1v-2.01c5.3-0.48,9.53-4.71,10.01-10.01h2.01c0.55,0,1-0.45,1-1S43.32,29.25,42.77,29.25z M38.76,29.25h-8v-8
   C34.95,21.72,38.29,25.06,38.76,29.25z M28.76,21.25v8h-8C21.22,25.06,24.56,21.72,28.76,21.25z M20.76,31.25h8v8
   C24.56,38.79,21.22,35.45,20.76,31.25z M30.76,39.25v-8h8C38.29,35.45,34.95,38.79,30.76,39.25z"
      />
    </svg>
  );
};
