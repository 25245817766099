import { useQuery } from "@apollo/react-hooks";
import { Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import mapAngolaDetailed from "Assets/Images/AngolaDetailedMap_KeyBlurred_WebSafe.jpg";
import blockMap from "Assets/Maps/overlayMap.json";
import ElementSizes from "Constants/ElementSizes";
import MapConstants from "Constants/MapConstants";
import {
  GET_BLOCKS_WITH_MARKERS,
  GetBlocksWithMarkersQueryResult,
} from "Graphql/BlockQueries";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      marginLeft: theme.spacing(4),
      minHeight: ElementSizes.HOME_HEIGHT,
    },
    map: {
      flexGrow: 1,
      backgroundImage: `url(${mapAngolaDetailed})`,
      backgroundSize: "149% 190%",
      backgroundPosition: "10% 50%",
      backgroundRepeat: "no-repeat",
    },
    body: {
      paddingTop: theme.spacing(4),
    },
    geography: {
      outline: "none",
    },
    legalDeclaration: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const BlocksMap: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("maps");
  const history = useHistory();

  const { data } = useQuery<GetBlocksWithMarkersQueryResult>(
    GET_BLOCKS_WITH_MARKERS,
  );

  const [tooltip, setTooltip] = useState("");

  const setTooltipContent = (tooltipContent: string) => {
    setTooltip(tooltipContent);
    ReactTooltip.rebuild();
  };

  const handleBlockClick = (selectedBlockName: string) => {
    const selectedBlock = data?.blocks.find(
      block => `Block ${block.name}` === selectedBlockName,
    );

    if (selectedBlock) {
      window.scrollTo(0, 0);
      history.push(`/block/${selectedBlock?.id}`);
    }
  };

  return (
    <>
      <Paper className={classes.map}>
        <ComposableMap
          height={MapConstants.MAP_HEIGHT}
          data-tip=""
          projectionConfig={{
            scale: MapConstants.MAP_SCALE,
            center: MapConstants.STARTING_COORDINATES,
          }}
        >
          <Geographies geography={blockMap}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  className={classes.geography}
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    const { NAME } = geo.properties;
                    setTooltipContent(`${NAME}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  onClick={() => {
                    handleBlockClick(geo.properties.NAME);
                    setTooltipContent("");
                  }}
                  style={{
                    hover: {
                      stroke: "#000000",
                      strokeWidth: 1,
                      fillOpacity: 0,
                    },
                    default: {
                      fillOpacity: 0.2,
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ComposableMap>
      </Paper>
      <Paper className={classes.legalDeclaration}>
        <Typography variant={"body1"}>{t("mapLegalDeclaration")}</Typography>
      </Paper>
      <ReactTooltip>{tooltip}</ReactTooltip>
    </>
  );
};
