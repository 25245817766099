import { useQuery } from "@apollo/react-hooks";
import {
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MapConstants from "Constants/MapConstants";
import Routes from "Constants/Routes";
import {
  GET_BLOCK_WITH_MARKERS_BY_ID,
  GetBlockWithMarkersQueryResult,
} from "Graphql/BlockQueries";
import { RouteParams } from "Models/RouteParams";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ComposableMap, Marker } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import { MapUtils } from "Utils/MapUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailedMap: {
      flexGrow: 1,
      backgroundColor: "rgba(0, 0, 0, 0)",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    legalDeclaration: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const DetailedBlockMap: FC = () => {
  const { blockId } = useParams<RouteParams>();

  const classes = useStyles();
  const { t } = useTranslation("maps");
  const history = useHistory();

  const { data } = useQuery<GetBlockWithMarkersQueryResult>(
    GET_BLOCK_WITH_MARKERS_BY_ID,
    { variables: { id: blockId } },
  );

  const [tooltip, setTooltip] = useState("");

  const setTooltipContent = (tooltipContent: string) => {
    setTooltip(tooltipContent);
    ReactTooltip.rebuild();
  };

  const markers = data?.block.mapMarkers;

  const handleFpsoClick = (fpsoId: string) => {
    window.scrollTo(0, 0);
    history.push(`/fpso/${fpsoId}`);
  };

  const handleDetailedMapClosed = () => {
    window.scrollTo(0, 0);
    history.push(Routes.HOME);
  };

  return (
    <>
      <IconButton onClick={handleDetailedMapClosed}>
        <ArrowBackIcon style={{ color: "white" }} />
      </IconButton>
      <Paper
        className={classes.detailedMap}
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL +
            data?.block.mapImageUrl}`,
        }}
      >
        <ComposableMap
          height={MapConstants.DETAILED_BLOCK_MAP_HEIGHT}
          projectionConfig={{ scale: MapConstants.DETAILED_BLOCK_MAP_SCALE }}
        >
          {markers?.map(({ name, coordinates, markerOffset, fpsoId }) => (
            <Marker
              key={name}
              coordinates={MapUtils.convertCoordinateStringToPoint(coordinates)}
              data-tip=""
              onMouseEnter={() => {
                setTooltipContent(name);
              }}
              onMouseLeave={() => {
                setTooltipContent("");
              }}
              onClick={() => {
                handleFpsoClick(fpsoId);
              }}
              style={{
                hover: {
                  stroke: "#000000",
                  strokeWidth: 1,
                  fillOpacity: 0,
                },
                default: {
                  fillOpacity: 0.3,
                },
              }}
            >
              <rect
                width={MapConstants.FPSO_MARKER_WIDTH}
                height={MapConstants.FPSO_MARKER_HEIGHT}
              />
            </Marker>
          ))}
        </ComposableMap>
      </Paper>
      <Paper className={classes.legalDeclaration}>
        <Typography variant={"body1"}>{t("mapLegalDeclaration")}</Typography>
      </Paper>
      <ReactTooltip>{tooltip}</ReactTooltip>
    </>
  );
};
