import { Button, Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { NewFeedback } from "Models/NewFeedback";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  handleAddFeedback(feedback: NewFeedback): void;
  handleSubmitError(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      display: "flex",
      flexWrap: "wrap",
    },
    feedbackTextField: {
      display: "flex",
    },
    subjectTextField: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
  }),
);

export const UserFeedbackForm: FC<Props> = ({
  handleAddFeedback,
  handleSubmitError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("feedback");

  const [newFeedback, setNewFeedback] = useState<NewFeedback>(
    new NewFeedback(),
  );

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (
      newFeedback &&
      newFeedback.subject !== "" &&
      newFeedback.feedback !== ""
    ) {
      handleAddFeedback(newFeedback);
      setNewFeedback(new NewFeedback());
    } else {
      handleSubmitError();
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root} id="feedback-form">
      <Grid item xs={6}>
        <TextField
          className={classes.subjectTextField}
          label={t("subject")}
          value={newFeedback.subject}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewFeedback({
              ...newFeedback,
              subject: event.target.value,
              feedback: newFeedback.feedback,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.feedbackTextField}
          multiline
          fullWidth
          rows="4"
          rowsMax="8"
          variant="outlined"
          label={t("feedbackComment")}
          value={newFeedback.feedback}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewFeedback({
              ...newFeedback,
              subject: newFeedback.subject,
              feedback: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" startIcon={<SaveIcon />}>
          {t("submit")}
        </Button>
      </Grid>
    </form>
  );
};
