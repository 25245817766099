import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import FontConstants from "Constants/FontConstants";
import { CarEvidenceStatus } from "Enums/CarEvidenceStatus";
import { Roles } from "Enums/Roles";
import { CarEvidenceDocument } from "Models/CarEvidenceDocument";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CarEvidenceDocumentEdit } from "Views/Components/CarEvidenceDocuments/CarEvidenceDocumentEdit";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";

interface Props {
  readonly carEvidenceDocument: CarEvidenceDocument;
  handleDocumentDeleted(document: CarEvidenceDocument): void;
  handleEvidenceRowEdited(updatedEvidenceRow: CarEvidenceDocument): void;
  readonly isActive: boolean;
  readonly isLocked: boolean;
  readonly isArchived: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    detailContainer: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    detailHeader: {
      fontWeight: FontConstants.SUBHEADING_WEIGHT,
    },
    bottomButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    detailsButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
  }),
);

export const CarEvidenceDocumentDetails: FC<Props> = ({
  carEvidenceDocument: originalCarEvidenceDocument,
  handleDocumentDeleted,
  handleEvidenceRowEdited: handleEditedEvidenceRowReceived,
  isActive,
  isLocked,
  isArchived,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleEvidenceRowEdited = (updatedEvidenceRow: CarEvidenceDocument) => {
    setEditEnabled(false);
    if (handleEditedEvidenceRowReceived) {
      handleEditedEvidenceRowReceived(updatedEvidenceRow);
    }
  };

  const handleEvidenceRowDeleted = () => {
    handleDocumentDeleted(originalCarEvidenceDocument);
  };

  return (
    <Grid container className={classes.root}>
      {editEnabled ? (
        <Grid item xs={12} className={classes.detailsButtonRow}>
          <Tooltip title={t<string>("cars:tooltipDiscard")} arrow={true}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">
                {t("cars:discardChanges")}
              </Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : (
        (keycloak?.hasRealmRole(Roles.auditor) ||
          keycloak?.hasRealmRole(Roles.administrator) ||
          (originalCarEvidenceDocument.submitter.id ===
            keycloak?.idTokenParsed?.sub &&
            originalCarEvidenceDocument.evidenceStatus ===
              CarEvidenceStatus.awaitingReview) ||
          ((keycloak?.hasRealmRole(Roles.agency) ||
            keycloak?.hasRealmRole(Roles.agency_v2)) &&
            originalCarEvidenceDocument.evidenceStatus !==
              CarEvidenceStatus.acceptedWithComment &&
            originalCarEvidenceDocument.evidenceStatus !==
              CarEvidenceStatus.evidenceReviewed)) &&
        !isArchived &&
        isActive &&
        isLocked === false && (
          <Grid item xs={12} className={classes.detailsButtonRow}>
            <Tooltip
              title={t<string>("cars:editCarEvidenceTooltip")}
              arrow={true}
            >
              <Button onClick={handleEditClicked}>
                <EditAuditInfoIcon />
              </Button>
            </Tooltip>
          </Grid>
        )
      )}
      {editEnabled && !isArchived && isActive ? (
        <CarEvidenceDocumentEdit
          carEvidenceDocument={originalCarEvidenceDocument}
          handleEvidenceRowDeleted={handleEvidenceRowDeleted}
          handleEvidenceRowEdited={handleEvidenceRowEdited}
        />
      ) : (
        <>
          <Grid item xs={2}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("documentNumber")}</div>
              <div>{originalCarEvidenceDocument.documentNumber}</div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("revision")}</div>
              <div>{originalCarEvidenceDocument.revision}</div>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.detailContainer}>
              <div className={classes.detailHeader}>{t("doc_description")}</div>
              <div>{originalCarEvidenceDocument.documentDescription}</div>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
