import React, { FC } from "react";

export const SnapshotWhiteIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g>
        <path
          fill="#FFFFFF"
          d="M25.52,23.7c-5.19,0-9.42,4.23-9.42,9.42c0,5.2,4.23,9.42,9.42,9.42s9.42-4.23,9.42-9.42
		C34.94,27.93,30.71,23.7,25.52,23.7z M25.52,40.55c-4.09,0-7.42-3.33-7.42-7.42c0-4.09,3.33-7.42,7.42-7.42s7.42,3.33,7.42,7.42
		C32.94,37.22,29.61,40.55,25.52,40.55z"
        />
        <path
          fill="#FFFFFF"
          d="M51.13,21.91h-4.41V17.5c0-0.55-0.45-1-1-1s-1,0.45-1,1v4.41h-4.41c-0.55,0-1,0.45-1,1s0.45,1,1,1h4.41v4.41
		c0,0.55,0.45,1,1,1s1-0.45,1-1v-4.41h4.41c0.55,0,1-0.45,1-1S51.69,21.91,51.13,21.91z"
        />
        <path
          fill="#FFFFFF"
          d="M45.72,12.87c-5.05,0-9.24,3.75-9.93,8.62l-0.73,0c-0.04,0-0.08-0.02-0.07,0l-3.54-5.18
		c-0.19-0.27-0.5-0.44-0.83-0.44H20.41c-0.33,0-0.64,0.16-0.83,0.44l-3.51,5.13c-0.02,0.03-0.06,0.05-0.1,0.05l-8.99-0.04
		c-1.79,0-3.25,1.44-3.25,3.21v19.16c0,1.79,1.46,3.25,3.25,3.25h37.05c1.79,0,3.25-1.46,3.25-3.25v-11
		c4.8-0.76,8.48-4.91,8.48-9.91C55.77,17.37,51.26,12.87,45.72,12.87z M45.29,43.82c0,0.69-0.56,1.25-1.25,1.25H6.99
		c-0.69,0-1.25-0.56-1.25-1.25V24.67c0-0.68,0.55-1.21,1.24-1.21l8.99,0.04c0.65,0,1.28-0.31,1.72-0.87l3.24-4.74h9.16l3.28,4.79
		c0.4,0.52,1.03,0.83,1.69,0.83l0.64,0c0.29,5.13,4.44,9.23,9.58,9.45V43.82z M45.72,30.96c-4.44,0-8.05-3.61-8.05-8.05
		c0-4.44,3.61-8.04,8.05-8.04c4.44,0,8.05,3.61,8.05,8.04C53.77,27.35,50.16,30.96,45.72,30.96z"
        />
      </g>
    </svg>
  );
};
