import React, { FC } from "react";

export const CurrentAuditIcon: FC = () => {
  return (
    <svg
      version="1.1"
      id="Laag_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width="40"
      height="50"
    >
      <g>
        <path
          fill="#575756"
          d="M7.64,24.34h25.84c0.55,0,1-0.45,1-1s-0.45-1-1-1H7.64c-0.55,0-1,0.45-1,1S7.09,24.34,7.64,24.34z"
        />
        <path
          fill="#575756"
          d="M55.81,54.77l-9.75-9.75c1.65-1.79,2.67-4.18,2.67-6.8c0-5.54-4.51-10.05-10.05-10.05
		c-0.09,0-0.18,0.01-0.27,0.01c0.01,0,0.02,0,0.04,0V10.62c0-1.18-0.96-2.13-2.13-2.13H30.3l0-0.6c0-0.27-0.11-0.52-0.29-0.71
		s-0.44-0.29-0.71-0.29h-4.06C24.77,4.92,23,3.46,20.89,3.46c-2.11,0-3.89,1.46-4.35,3.42h-4.06c-0.27,0-0.52,0.11-0.71,0.29
		c-0.19,0.19-0.29,0.44-0.29,0.71c0,0,0,0.22,0,0.6H5.45c-1.18,0-2.13,0.96-2.13,2.13v43.62c0,1.18,0.96,2.13,2.13,2.13h30.88
		c1.18,0,2.13-0.96,2.13-2.13v-5.98c-0.36-0.01-0.72-0.04-1.07-0.08c0.43,0.06,0.86,0.1,1.3,0.1c2.2,0,4.22-0.72,5.88-1.92
		l9.83,9.83c0.2,0.2,0.45,0.29,0.71,0.29s0.51-0.1,0.71-0.29C56.2,55.79,56.2,55.16,55.81,54.77z M13.48,8.88h3.94
		c0.55,0,1-0.44,1-0.99c0.01-1.31,1.14-2.42,2.47-2.42c1.33,0,2.47,1.11,2.47,2.42c0,0.55,0.45,1,1,1h3.94l0,2.65
		c0,0.09-0.07,0.16-0.16,0.16h-14.5c-0.05,0-0.09-0.03-0.11-0.05s-0.05-0.06-0.05-0.11C13.48,10.45,13.48,9.53,13.48,8.88z
		 M36.46,48.01v6.23c0,0.07-0.06,0.13-0.13,0.13H5.45c-0.07,0-0.13-0.06-0.13-0.13V10.62c0-0.07,0.06-0.13,0.13-0.13h6.03
		c0,0.33,0,0.67,0,1.04c0,0.58,0.22,1.12,0.63,1.53c0.41,0.41,0.95,0.63,1.53,0.63h14.5c1.19,0,2.16-0.97,2.16-2.16l0-1.04h6.03
		c0.07,0,0.13,0.06,0.13,0.13v17.82c-2.01,0.46-3.79,1.53-5.14,2.99H7.64c-0.55,0-1,0.45-1,1s0.45,1,1,1h22.22
		c-0.78,1.43-1.22,3.06-1.22,4.79c0,0.79,0.1,1.55,0.27,2.29H7.64c-0.55,0-1,0.45-1,1s0.45,1,1,1h21.84c0.05,0,0.08-0.02,0.13-0.03
		c1.36,2.87,4.03,4.99,7.24,5.59C36.72,48.05,36.59,48.04,36.46,48.01z M38.69,46.27c-4.44,0-8.05-3.61-8.05-8.05
		s3.61-8.05,8.05-8.05s8.05,3.61,8.05,8.05S43.13,46.27,38.69,46.27z"
        />
      </g>
    </svg>
  );
};
