import { useKeycloak } from "@react-keycloak/web";
import React, { FC, ReactNode } from "react";

interface Props {
  readonly children: ReactNode;
}

export const Authentication: FC<Props> = ({ children }) => {
  const { keycloak } = useKeycloak();

  return <>{keycloak?.authenticated && children}</>;
};
