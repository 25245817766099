import { useQuery } from "@apollo/react-hooks";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import { Roles } from "Enums/Roles";
import {
  GET_FPSO_AREA_BY_ID,
  GetFpsoAreaByIdQueryResult,
  GetFpsoAreaByIdQueryVariables,
} from "Graphql/FpsoQueries";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { OptionType } from "Models/OptionType";
import { RouteParams } from "Models/RouteParams";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { AddSystemComponentButton } from "./AddSystemComponentButton";
import { FpsoAreaSystemComponentList } from "./FpsoAreaSystemComponentList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    dropdown: {
      display: "flex",
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
  }),
);

export const FpsoAreaSystemDetailsOverview: FC = () => {
  const { fpsoAreaId } = useParams<RouteParams>();
  const { t } = useTranslation("fpsos");
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [
    fpsoAreaSystemComponentAddFormEnabled,
    setFpsoAreaSystemComponentAddFormEnabled,
  ] = useState(false);

  const handleSetFpsoAreaSystemComponentAddFormEnabled = () => {
    setFpsoAreaSystemComponentAddFormEnabled(
      !fpsoAreaSystemComponentAddFormEnabled,
    );
    setSelectedSubSystems([]);
  };

  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();

  const { data: fpsoAreaInfo } = useQuery<
    GetFpsoAreaByIdQueryResult,
    GetFpsoAreaByIdQueryVariables
  >(GET_FPSO_AREA_BY_ID, { variables: { id: fpsoAreaId || "undefined" } });

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaInfo?.fpsoArea.areaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
  };

  const Option = CheckboxOption;

  return (
    <>
      {fpsoAreaId ? (
        <Grid container>
          {(keycloak?.hasRealmRole(Roles.auditor) ||
            keycloak?.hasRealmRole(Roles.administrator)) &&
            !fpsoAreaSystemComponentAddFormEnabled && (
              <Grid item xs={2} className={classes.button}>
                <AddSystemComponentButton
                  setCarAddFormEnabled={
                    handleSetFpsoAreaSystemComponentAddFormEnabled
                  }
                />
              </Grid>
            )}

          <Grid item xs={10} className={classes.dropdown}>
            <Select
              onChange={option => handleChange(option)}
              options={subSystemOptions}
              isMulti
              autoFocus
              isSearchable
              isClearable
              styles={CustomStylesSubSystemOptions}
              placeholder={t("helperTextSystemDetailsFilterSubSystems")}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
            />
          </Grid>

          <FpsoAreaSystemComponentList
            fpsoAreaId={fpsoAreaId}
            fpsoName={fpsoAreaInfo?.fpsoArea.fpso.name}
            fpsoAreaName={fpsoAreaInfo?.fpsoArea.areaName}
            setFpsoAreaSystemComponentAddFormDisabled={
              handleSetFpsoAreaSystemComponentAddFormEnabled
            }
            fpsoAreaSystemComponentAddFormEnabled={
              fpsoAreaSystemComponentAddFormEnabled
            }
            selectedSubSystems={selectedSubSystems}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {t("errors:fpsoFetchError")}
        </Grid>
      )}
    </>
  );
};
