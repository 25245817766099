import { Button, Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Feedback } from "Models/Feedback";
import { NewFeedbackReply } from "Models/NewFeedbackReply";
import React, { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SaveIcon } from "Views/Components/Icons/SaveIcon";

interface Props {
  handleAddFeedbackReply(feedbackReply: NewFeedbackReply): void;
  readonly feedback: Feedback;
  handleSubmitError(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      display: "flex",
      flexWrap: "wrap",
    },
    feedbackTextField: {
      display: "flex",
    },
  }),
);

export const UserFeedbackReplyForm: FC<Props> = ({
  handleAddFeedbackReply,
  feedback: userFeedback,
  handleSubmitError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("feedback");

  const [newFeedbackReply, setFeedbackReply] = useState<NewFeedbackReply>(
    new NewFeedbackReply(),
  );

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    newFeedbackReply.userFeedbackId = userFeedback.id;

    if (
      newFeedbackReply &&
      newFeedbackReply.reply !== "" &&
      newFeedbackReply.userFeedbackId !== ""
    ) {
      handleAddFeedbackReply(newFeedbackReply);
      setFeedbackReply(new NewFeedbackReply());
    } else {
      handleSubmitError();
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.root} id="feedback-form">
      <Grid item xs={12}>
        <TextField
          className={classes.feedbackTextField}
          multiline
          fullWidth
          rows="2"
          rowsMax="8"
          variant="outlined"
          label={t("reply")}
          value={newFeedbackReply.reply}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFeedbackReply({
              ...newFeedbackReply,
              reply: event.target.value,
              userFeedbackId: newFeedbackReply.userFeedbackId,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button type="submit" startIcon={<SaveIcon />}>
          {t("submitReply")}
        </Button>
      </Grid>
    </form>
  );
};
