import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useKeycloak } from "@react-keycloak/web";
import DateConstants from "Constants/DateConstants";
import { Roles } from "Enums/Roles";
import moment from "moment";
import { FC, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { SnapshotWhiteIcon } from "Views/Components/Icons/SnapshotWhiteIcon";

interface Props {
  createSnapshot(snapshotAudit: Date): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonText: {
      textTransform: "capitalize",
      color: "white",
    },
    dialogPicker: {
      justifyContent: "space-around",
    },
    dialogButtons: {
      justifyContent: "space-between",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
);

export const CreateSnapshotButton: FC<Props> = ({ createSnapshot }) => {
  const { t } = useTranslation("audits");
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [snapshotAuditDate, setSnapshotAuditDate] = useState<Date>(new Date());

  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleNewSnapshotClick = () => {
    setOpen(false);
    if (snapshotAuditDate === undefined) {
      setSnapshotAuditDate(moment().toDate());
    }
    if (snapshotAuditDate) {
      createSnapshot(snapshotAuditDate);
    }
  };

  const handleCreatedAtDateChange = (date: Date) => {
    let auditCreatedAt = { ...snapshotAuditDate };
    auditCreatedAt = date;
    setSnapshotAuditDate(auditCreatedAt);
  };

  return (
    <>
      {keycloak?.hasRealmRole(Roles.auditor) ? (
        <Tooltip title={t<string>("audits:snapshotTooltip")} arrow={true}>
          <Button
            onClick={() => handleNewSnapshotClick()}
            className={classes.buttonText}
          >
            <SnapshotWhiteIcon />
            <Typography variant="body1">{t("audits:snapshot")}</Typography>
          </Button>
        </Tooltip>
      ) : (
        keycloak?.hasRealmRole(Roles.administrator) && (
          <>
            <Tooltip title={t<string>("audits:snapshotTooltip")} arrow={true}>
              <Button
                onClick={() => handleDialogOpen()}
                className={classes.buttonText}
              >
                <SnapshotWhiteIcon />
                <Typography variant="body1">{t("audits:snapshot")}</Typography>
              </Button>
            </Tooltip>
            <Dialog
              open={open}
              onClose={handleDialogClose}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
            >
              <DialogTitle id="dialog-title">
                {t("audits:newSnapshot")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="dialog-description">
                  {t("audits:newSnapshotAdminDescription")}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.dialogPicker}>
                <DatePicker
                  clearable={true}
                  label={t("audits:dateOfSnapshot")}
                  value={snapshotAuditDate}
                  placeholder={moment().format(
                    DateConstants.DEFAULT_DATE_FORMAT,
                  )}
                  onChange={selectedDate => {
                    if (selectedDate) {
                      handleCreatedAtDateChange(selectedDate.toDate());
                    }
                  }}
                  format={DateConstants.DEFAULT_DATE_FORMAT}
                />
              </DialogActions>

              <DialogActions className={classes.dialogButtons}>
                <Button onClick={handleDialogClose} color="primary">
                  {t("audits:newAuditCancel")}
                </Button>
                <Button
                  onClick={handleNewSnapshotClick}
                  color="primary"
                  autoFocus
                >
                  {t("audits:newSnapshot")}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      )}
    </>
  );
};
