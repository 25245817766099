import React from "react";
import { components } from "react-select";

export const CheckboxOption = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
