export default class Colors {
  public static readonly CAR_CLOSED = "#4caf50";

  public static readonly PERFORMANCE_INDICATOR_BLUE = "rgba(13, 71, 161, 0.7)";
  public static readonly PERFORMANCE_INDICATOR_YELLOW =
    "rgba(204, 204, 0, 0.6)";
  public static readonly PERFORMANCE_INDICATOR_RED = "rgba(255, 0, 0, 0.6)";
  public static readonly PERFORMANCE_INDICATOR_GREY =
    "rgba(158, 158, 158, 0.6)";

  public static readonly ITEM_SELECTED = "#d9d9d9";
  public static readonly EVIDENCE_ITEM_SELECTED = "#b3b3b3";

  public static readonly BLACK = "#000000";
  public static readonly WHITE = "#FFFFFF";
  public static readonly DARK_GREY = "#404040";

  public static readonly FILE_UPLOAD_TEXT = "#bdbdbd";
  public static readonly FILE_UPLOAD_BORDER = "#eeeeee";
  public static readonly FILE_UPLOAD_BACKGROUND = "#fafafa";

  public static readonly AUDIT_SCORE_UNACCEPTABLE = "rgba(200, 200, 200, 1)";
  public static readonly AUDIT_SCORE_UNSATISFACTORY = "rgba(255, 128, 0, 1)";
  public static readonly AUDIT_SCORE_FAIR = "rgba(255, 255, 0, 1)";
  public static readonly AUDIT_SCORE_GOOD = "rgba(0, 128, 0, 1)";

  public static readonly CAR_CLASS_CRITICAL = "rgba(255, 0, 0, 1)";
  public static readonly CAR_CLASS_MAJOR = "rgba(255, 128, 0, 1)";
  public static readonly CAR_CLASS_MINOR = "rgba(255, 255, 0, 1)";
  public static readonly CAR_CLASS_COMMENT = "rgba(0, 128, 0, 1)";

  public static readonly CAR_CLASS_CRITICAL_PIE_CHART = "rgba(255, 0, 0, 0.6)";
  public static readonly CAR_CLASS_MAJOR_PIE_CHART = "rgba(255, 128, 0, 0.6)";
  public static readonly CAR_CLASS_MINOR_PIE_CHART = "rgba(204, 204, 0, 0.6)";
  public static readonly CAR_CLASS_COMMENT_PIE_CHART = "rgba(0, 128, 0, 0.6)";

  public static readonly STATUS_OPEN = "rgba(13, 71, 161, 0.7)";
  public static readonly STATUS_CLOSED = "rgba(0, 128, 0, 0.6)";

  public static readonly ANPG_ACTION_REQUIRED = "rgba(0, 75, 189, 0.6)";
  public static readonly AUDITOR_ACTION_REQUIRED = "rgba(0, 100, 250, 0.6)";
  public static readonly OPERATOR_ACTION_REQUIRED = "rgba(61, 139, 255, 0.6)";

  public static readonly HEADER_DEV = "#a63b32";
  public static readonly HEADER_ACC = "#32a852";
}
