import { SystemArea } from "Enums/SystemArea";

export class InitializedFpsoAreaSystemComponent {
  public systemArea: SystemArea = SystemArea.other;
  public componentName: string | null = null;
  public quantity: string | null = null;
  public type: string | null = null;
  public manufacturer: string | null = null;
  public modelNo: string | null = null;
  public serialNo: string | null = null;
  public description: string | null = null;
  public comment: string | null = null;
}
