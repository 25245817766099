import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import { Roles } from "Enums/Roles";
import React, { FC } from "react";
import { CurrentScoreCompareChart } from "Views/Components/ManagementSummary/CurrentScoreCompareChart";
import { ScoreVsTimeChart } from "Views/Components/ManagementSummary/ScoreVsTimeChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "100%",
      paddingBottom: theme.spacing(1),
    },
  }),
);

export const GraphicalResults: FC = () => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  return (
    <>
      {(keycloak?.hasRealmRole(Roles.auditor) ||
        keycloak?.hasRealmRole(Roles.administrator) ||
        keycloak?.hasRealmRole(Roles.agency) ||
        keycloak?.hasRealmRole(Roles.agency_v2)) && (
        <>
          <div className={classes.root}>
            <CurrentScoreCompareChart />
          </div>
          <div className={classes.root}>
            <ScoreVsTimeChart />
          </div>
        </>
      )}
    </>
  );
};
