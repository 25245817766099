import { Button, Tooltip } from "@material-ui/core";
import { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { WordDocumentDownloadWhiteIcon } from "Views/Components/Icons/WordDocumentDownloadWhiteIcon";
import { WordMinutesDownloadWhiteIcon } from "Views/Components/Icons/WordMinutesDownloadWhiteIcon";

interface Props {
  downloadFile(): void;
  readonly fileType: string;
}

export const DownloadFileButton: FC<Props> = ({ downloadFile, fileType }) => {
  const { t } = useTranslation("audits");

  const handleDownloadFileClick = () => {
    downloadFile();
  };

  return (
    <>
      {fileType === "MeetingMinutes" ? (
        <>
          <Tooltip
            title={t<string>("audits:downloadMinutesOfMeetingTemplate")}
            arrow={true}
          >
            <Button onClick={() => handleDownloadFileClick()}>
              <WordMinutesDownloadWhiteIcon />
            </Button>
          </Tooltip>
        </>
      ) : fileType === "AuditReport" ? (
        <>
          <Tooltip
            title={t<string>("audits:downloadAuditReportTooltip")}
            arrow={true}
          >
            <Button onClick={() => handleDownloadFileClick()}>
              <WordDocumentDownloadWhiteIcon />
            </Button>
          </Tooltip>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
