import axios from "axios";
import { saveAs } from "file-saver";

export const FileUtils = {
  getExtensionByContentType: (contentType: string) => {
    if (contentType === "application/pdf") {
      return ".pdf";
    }
    if (contentType === "application/vnd.ms-excel") {
      return ".xls";
    }
    if (
      contentType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return ".xlsx";
    }
    if (contentType === "application/msword") {
      return ".doc";
    }
    if (
      contentType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return ".docx";
    }
    if (contentType === "text/plain") {
      return ".txt";
    }
    if (contentType === "image/jpeg") {
      return ".jpeg";
    }
    if (contentType === "image/jpg") {
      return ".jpg";
    }
    if (contentType === "image/png") {
      return ".png";
    }
    if (contentType === "image/bmp") {
      return ".bmp";
    }

    return "";
  },

  downloadFileByUrl: (url: string, fileName: string, token: string) => {
    axios
      .get(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, {
        headers: {
          Authorization: token,
        },
        params: { fileName: url },
        responseType: "blob",
      })
      .then(result => {
        const binaryData = [];
        binaryData.push(result.data);
        saveAs(
          new Blob(binaryData, { type: result.headers.contentType }),
          fileName +
            FileUtils.getExtensionByContentType(result.headers["content-type"]),
        );
      });
  },

  retrieveImageBlobByUrl: async (url: string, token: string) => {
    return await axios.get(`${process.env.REACT_APP_FILE_SERVER_URL}/file`, {
      headers: {
        Authorization: token,
      },
      params: { fileName: url },
      responseType: "blob",
    });
  },

  downloadCarListExportFile: async (auditId: string, token: string) => {
    return await axios
      .get(`${process.env.REACT_APP_FILE_SERVER_URL}/report/car-list`, {
        headers: {
          Authorization: token,
        },
        params: { auditId },
        responseType: "blob",
      })
      .then(result => {
        const binaryData = [];
        binaryData.push(result.data);
        saveAs(
          new Blob(binaryData, { type: result.headers.contentType }),
          result.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0],
        );
      })
      .catch(error => {
        return;
      });
  },
};
