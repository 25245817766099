export const NameToColor = {
  nameToHashCode: (operatorName: string): number => {
    let hash = 0;
    for (let i = 0; i < operatorName.length; i++) {
      /* tslint:disable:no-bitwise */
      hash = operatorName.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  },
  intToRGB: (operatorNameHash: number): string => {
    /* tslint:disable:no-bitwise */
    const operatorNameColor = (operatorNameHash & 0x00ffffff)
      .toString(16)
      .toUpperCase();
    return (
      "00000".substring(0, 6 - operatorNameColor.length) + operatorNameColor
    );
  },
};
